import React, { Component } from 'react'
import { bool, func, string } from 'prop-types';

import { connect } from 'react-redux';
import { deleteCovenantThunk, fetchCovenantsThunk } from '../../store/actions/covenantActions'

import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';

class DeleteCovenant extends Component {
  static propTypes = {
    modal: bool.isRequired,
    toggle: func.isRequired,
    deleteRef: string.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      name: props.deleteInfo.name,
      id: props.deleteInfo.id,
      modal: props.modal,
      covenantRemoved: false,
      error: false,
    }

    this.delete = this.delete.bind(this)
  }

  deleteResponse(){
    const { covenants } = this.props
    const { covenantRemoved, error } = this.state
    if(covenants.deletedCovenant && covenantRemoved) {
      alert(`${covenants.deletedCovenant} foi removido com sucesso!`)
      this.setState({ modal: false , covenantRemoved: false })
    } else if(covenants.error && error) {
      alert(covenants.error)
      this.setState({ error: false })
    }
  }

  delete(){
    const { id, name } = this.state
    const { deleteRef } = this.props

    this.props.fetchCovenants()
    this.props.deleteCovenant({ id, deleteRef, name }).then(function(){
      this.setState({
        covenantRemoved: true 
      }, () => this.deleteResponse())
    }.bind(this))
    .catch(function(){
      this.setState({
        error: true
      }, () => this.deleteResponse())
    }.bind(this))
  }

  render() {
    const { toggle } = this.props

    return(
      this.state.modal ?
      <MDBModal isOpen={this.state.modal} toggle={toggle}>
        <MDBModalHeader toggle={toggle}>Excluir convênio</MDBModalHeader>
        <MDBModalBody>
          <p>Tem certeza que deseja excluir <b>{`${this.state.name}`}</b>?</p>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={toggle}>Voltar</MDBBtn>
          <MDBBtn color="danger" onClick={this.delete}>Excluir</MDBBtn>
        </MDBModalFooter>
      </MDBModal> : null
    )
  }
}

const mapStateToProps = state => ({
  covenants: state.covenants
})

const mapDispatchToProps = dispatch => {
  return {
    deleteCovenant: (covenant) => dispatch(deleteCovenantThunk(covenant)),
    fetchCovenants: () => dispatch(fetchCovenantsThunk())
  }
 }

export default connect(mapStateToProps, mapDispatchToProps)(DeleteCovenant)