import React, { Component } from 'react'
import { connect } from 'react-redux';

import { MDBRow, MDBCol } from 'mdbreact'

import { Header } from '../Layout/Header'
import { CategoriesCard } from '../Layout/CategoriesCard';

class LeasesCategoriesAdmin extends Component {
  
  render(){
    const { leases } = this.props
    return(
      <div className="container">
        <Header text="Locação de espaços" routeBack="/admin" />
        <MDBRow>
          <MDBCol size="6">
            {
              leases === 'loading' ? (
                <div>Carregando...</div>
              ) : (
                leases && leases.map((lease, key) => {
                  return(
                    <CategoriesCard
                      title={lease.type}
                      type={`locacoes`}
                      route={lease.route}
                      button={`editar`}
                      key={key}
                    />
                  )
                })
              )
            }
            </MDBCol>
            <MDBCol size="4">

            </MDBCol>
        </MDBRow>
        
      </div>
    )
  }
}

const mapStateToProps = state => ({
  leases: state.leases
})

export default connect(mapStateToProps)(LeasesCategoriesAdmin)