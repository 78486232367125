import React, { Component } from "react";
import { MDBContainer, MDBCol, MDBBtn, MDBIcon, MDBInput } from 'mdbreact';
import Recaptcha from 'react-recaptcha';
import styled from 'styled-components'
import * as emailjs from 'emailjs-com'

const RequestMessage = styled.div`
  position: fixed;
  top: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 500px;
`

class Contact extends Component {
  static defaultState = {
    reply_to: '',
    from_name: '',
    phone_contact: '',
    message_html: '',
    hasMessageBeenSent: false,
    error: false
  }

  constructor(props) {
    super(props);

    this.state = {
      reply_to: '',
      from_name: '',
      phone_contact: '',
      message_html: '',
      sendingMessage: false,
      hasMessageBeenSent: false,
      isReCaptchaVerified: false
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.recaptchaLoaded = this.recaptchaLoaded.bind(this)
    this.sendEmail = this.sendEmail.bind(this)
    this.verifyCallback = this.verifyCallback.bind(this)
  }

  componentDidMount() {
    emailjs.init(`user_HbENs67seg474BvoXrpEj`)
  }

  handleInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({
      [name]: value
    })
  }

  recaptchaLoaded() {
    console.log('captcha successfully loaded');
  }

  sendEmail(){
    const { reply_to, from_name, phone_contact, message_html, isReCaptchaVerified } = this.state

    if(isReCaptchaVerified) {
      this.setState({
        sendingMessage: true
      })

      emailjs.send(
        `gmail`, 
        `template_Q5NTcBNW`, 
        {
          reply_to,
          from_name,
          phone_contact,
          message_html
        }
        ).then((response) => {
          this.setState({
            sendingMessage: false,
            hasMessageBeenSent: true,
            error: false
          })
        }, (err) => {
          this.setState({
            error: true
          })
        }
      ) 
    } else {
      alert('Verifique que você não é um robô.')
    } 
  }

  verifyCallback(response) {
    if (response) {
      this.setState({
        isReCaptchaVerified: true
      })
    }
  }

  render() {
    const { reply_to, from_name, phone_contact, message_html, hasMessageBeenSent, sendingMessage, error } = this.state
    return (
      <MDBContainer className="d-flex justify-content-center position-relative" style={ { paddingBottom: '500px' } }>
        {
          hasMessageBeenSent ? 
          (
            error ? 
            (
              <RequestMessage>
                <img src="https://img.icons8.com/color/100/000000/delete-message.png" />
                <p className="h5 text-center">{`${from_name}, ocorreu um erro, tente novamente mais tarde.`}</p>
                <p className="h3 text-center">（・∩・)</p>
              </RequestMessage>
            ) : (
              <RequestMessage>
                <img src="https://img.icons8.com/color/100/000000/paper-plane.png" />
                <p className="h5 text-center">{`${from_name}, sua mensagem foi enviada, lhe retornaremos o mais breve possível!`}</p>
                <p className="h3 text-center">(•‿•)</p>
              </RequestMessage>
            )
          ) : (
            sendingMessage ? 
            (
              <RequestMessage>
                <p className="h5 text-center">Enviando...</p>
                <p className="h4 mt-3 text-center">ε=┌(;･_･)┘</p>
              </RequestMessage>
            ) : (
              <MDBCol 
                size={window.matchMedia(`(max-width:520px)`).matches ? `12` : '6'}
              >
                <form>
                  <p className="h5 text-center mt-4">
                    Alguma dúvida, sugestão ou reclamação? Nós adoraríamos ouvir de você!
                  </p>
                  <p className="text-center">ヽ( ˘∪˘ )ゝ</p>
                  <div className="grey-text">
                    <MDBInput
                      onChange={ this.handleInputChange }
                      label="Seu nome"
                      icon="user"
                      name="from_name"
                      group
                      type="text"
                      validate
                      error="wrong"
                      success="right"
                      value={ from_name }
                    />
                    <MDBInput
                      onChange={ this.handleInputChange }
                      label="Seu email"
                      icon="envelope"
                      name="reply_to"
                      group
                      type="email"
                      validate
                      error="wrong"
                      success="right"
                      value={ reply_to }
                    />
                    <MDBInput
                      onChange={ this.handleInputChange }
                      label="Telefone"
                      icon="phone"
                      name="phone_contact"
                      group
                      type="text"
                      validate
                      error="wrong"
                      success="right"
                      value={ phone_contact }
                    />
                    <MDBInput
                      onChange={ this.handleInputChange }
                      type="textarea"
                      name="message_html"
                      rows="2"
                      label="Sua mensagem"
                      icon="pencil"
                      value={ message_html }
                    />
                  </div>
                  <div className="text-center">
                  <Recaptcha
                    sitekey={process.env.REACT_APP_SITEKEY}
                    render="explicit"
                    onloadCallback={this.recaptchaLoaded}
                    verifyCallback={this.verifyCallback}
                  />
                    <MDBBtn onClick={ this.sendEmail } className="mt-3" outline color="secondary">
                      Enviar 
                      <MDBIcon icon="paper-plane-o" className="ml-1" />
                    </MDBBtn>
                  </div>
                </form>
              </MDBCol>
            )
          )
        }
      </MDBContainer>
    );
  }
}

export default Contact;