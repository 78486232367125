import React, { Component } from 'react';
import { string, func, bool } from 'prop-types'

import { connect } from 'react-redux';
import { editLessonThunk, fetchLessonsThunk, editProfessorThunk } from '../../store/actions/lessonsActions'

import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { DayForm } from './DayForm';
import { PriceForm } from './PriceForm';
import { ProfessorForm } from './ProfessorForm';
import { CategoryForm } from './CategoryForm';

class CreateLesson extends Component {
  static propTypes = {
    modal: bool.isRequired,
    toggle: func.isRequired,
    editRef: string.isRequired
  }

  constructor(props) {
    super(props);
    debugger
    this.state = {
      id: props.id,
      name: '' || props.editLessonInfo.name || props.editCategoryInfo.type,
      lesson1: '' || props.editLessonInfo.lesson1,
      lesson2: '' || props.editLessonInfo.lesson2,
      lesson3: '' || props.editLessonInfo.lesson3,
      service: '' || props.editPriceInfo.service,
      associate: '',
      notAssociated: '',
      professorName: '' || props.editProfessorInfo.name,
      professorContact: '' || props.editProfessorInfo.contact,
      professorLesson: '' || props.editProfessorInfo.lesson,
      professors: '' || props.editCategoryInfo.professors,
      type: props.type,
      newLesson: false,
      error: false,
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.assignProfessor = this.assignProfessor.bind(this)
    this.edit = this.edit.bind(this)
  }

  componentDidMount() {
    if(this.state.type === 'prices') {
      this.setState({
        associate: this.props.editPriceInfo.associate.price,
        notAssociated: this.props.editPriceInfo.notAssociated.price
      })
    } else if (this.state.type === 'categories') {
      this.setState({
        associate: this.props.editCategoryInfo.prices.associate,
        notAssociated: this.props.editCategoryInfo.prices.notAssociated
      })
    }
  } 

  handleInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({
      [name]: value
    })
  }

  assignProfessor() {
    const { professorName, professorContact, professorLesson } = this.state
    const key = professorName.toLowerCase().replace(/[áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]/g, '')
    const newProfessor = {
      name: professorName,
      contact: professorContact,
      lesson: professorLesson,
      key: key,
      modifiedBy: JSON.parse(localStorage.getItem('auth')).user.displayName
    }
    if(professorName) {
      this.setState({
        professors: Object.assign( { [key]: newProfessor }, this.state.professors)
      })
    } else {
      alert('Ao menos o nome do professor deverá ser preenchido.')
    }
  }

  async edit() {
    const { name, professors,professorName, professorLesson, professorContact, lesson1, lesson2, lesson3, service, associate, notAssociated, type, id } = this.state
    const { editRef } = this.props

    const categoryPrices = {
      associate: associate,
      notAssociated: notAssociated
    }
    
    const newDay = {
      id,
      name, 
      lesson1, 
      lesson2, 
      lesson3,
      type,
      editRef
    }
    const newPrice = {
      id,
      service,
      associate,
      notAssociated,
      type,
      editRef
    }

    const newCategory = {
      id,
      name,
      professors,
      categoryPrices,
      type,
      editRef
    }

    const updateProfessor = {
      id,
      professorName,
      professorLesson,
      professorContact,
      type,
      editRef
    } 
    
    if( type === 'days' ) {
      if( name && lesson1 ) {
        this.props.fetchLessons()
        this.props.toggle()
        return this.props.editLesson(newDay)
        .then(function(){
          return alert('Item editado com sucesso!')
        })
        .catch(function(){
          return alert('Falha ao editar item. Tente novamente mais tarde.')
        })
      } else {
        alert("Por favor, preencha ao menos o dia e uma aula.")
      }
    } else if ( type === 'prices' ) {
      if( associate ) {
        this.props.fetchLessons()
        this.props.toggle()
        return this.props.editLesson(newPrice)
        .then(function(){
          return alert('Dia editado com sucesso!')
        })
        .catch(function(){
          return alert('Falha ao editar dia. Tente novamente mais tarde.')
        })
      } else {
        alert("Por favor, preencha pelo menos o valor de sócio")
      }
    } else if( type === 'categories' ) {
      if( name ) {
        this.props.fetchLessons()
        this.props.toggle()
        return this.props.editLesson(newCategory)
        .then(function(){
          return alert('Categoria editado com sucesso!')
        })
        .catch(function(){
          return alert('Falha ao editar categoria. Tente novamente mais tarde.')
        })
      } else {
        alert("Por favor, preencha ao menos o nome da categoria.")
      }
    } else {
      if( professorName ) {
        this.props.fetchLessons()
        this.props.toggle()
        return this.props.editProfessor(updateProfessor)
        .then(function(){
          return alert('Professor(a) editado com sucesso!')
        })
        .catch(function(){
          return alert('Falha ao editar professor(a). Tente novamente mais tarde.')
        })
      } else {
        alert("Por favor, preencha ao menos o nome.")
      }
    }
}

render() {
  const { toggle, modal, type } = this.props
  console.log(this.state)
  return (
      <MDBModal isOpen={modal} toggle={toggle}>
        <MDBModalHeader toggle={toggle}>Adicionar turno</MDBModalHeader>
        <MDBModalBody>
        {
            type === 'days' ?
            (
              <DayForm 
                handleInputChange={this.handleInputChange}
                name={this.state.name}
                lesson1={this.state.lesson1}
                lesson2={this.state.lesson2}
                lesson3={this.state.lesson3}
              />
            )
            :
            (
              type === 'prices' ?
              (
                <PriceForm 
                  handleInputChange={this.handleInputChange}
                  service={this.state.service}
                  associate={this.state.associate}
                  notAssociated={this.state.notAssociated}
                />
              ) : (
                type === 'categories' ?
                (
                  <CategoryForm
                    handleInputChange={this.handleInputChange}
                    assignProfessor={this.assignProfessor}
                    name={this.state.name}
                    professorName={this.state.professorName}
                    professorContact={this.state.professorContact}
                    professorLesson={this.state.professorLesson}
                    associate={this.state.associate}
                    notAssociated={this.state.notAssociated}
                    professors={this.state.professors}
                  />
                ) : (
                  <ProfessorForm 
                    handleInputChange={this.handleInputChange}
                    professorName={this.state.professorName}
                    professorLesson={this.state.professorLesson}
                    professorContact={this.state.professorContact}
                  />
                )
              )
            )
          }
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={toggle}>Voltar</MDBBtn>
          <MDBBtn color="info" onClick={this.edit}>Editar</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    );
  }
}

const mapStateToProps = state => ({
  lessons: state.lessons
})

const mapDispatchToProps = dispatch => {
  return {
    editLesson: (lesson) => dispatch(editLessonThunk(lesson)),
    fetchLessons: () => dispatch(fetchLessonsThunk()),
    editProfessor: (professor) => dispatch(editProfessorThunk(professor))
  }
 }

export default connect(mapStateToProps, mapDispatchToProps)(CreateLesson);