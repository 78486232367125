import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Header } from './Header'
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardHeader, MDBBtn } from "mdbreact";

const DashboardWrapper = styled.div`
  margin-top: 0;
`

const routes = [
  { route: '/admin/convenios', name: 'Convênios', icon: 'https://img.icons8.com/bubbles/100/000000/handshake.png', description: 'Dividido por categorias' },
  { route: '/admin/classificados', name: 'Classificados', icon: 'https://img.icons8.com/clouds/100/000000/promotion-budget.png', description: 'Gerenciamento de fotos para anúncio criado'  },
  { route: '/admin/galerias', name: 'Galerias', icon: 'https://img.icons8.com/clouds/100/000000/image.png', description: 'Galerias do Google Fotos' },
  { route: '/admin/locacoes', name: 'Locação de espaços', icon: 'https://img.icons8.com/bubbles/100/000000/news.png', description: 'Informações sobre espaços da ASHCLIN' },
  { route: '/admin/parquesocial', name: 'Parque Social', icon: 'https://img.icons8.com/bubbles/100/000000/test-passed.png', description: 'Atividades oferecidas por terceiros' },
  { route: '/admin/slideshow', name: 'Slideshow', icon: 'https://img.icons8.com/clouds/100/000000/send-images.png', description: 'Imagens que podem ser adicionadas na primeira página, é possível mudar a ordem em que elas aparecem' },
]

class Dashboard extends Component {
  render() {
    return (
      <DashboardWrapper>
        <div className="container">
          <Header text="Gerenciador de conteúdo" routeBack="/" />
          <div className="d-flex flex-wrap justify-content-start pb-5">
            {
              routes.map(route => {
                return(
                  <MDBCard style={{ width: "20rem", margin: "1rem" }}>
                    <MDBCardHeader color="deep-orange lighten-1">{route.name}</MDBCardHeader>
                    <MDBCardBody>
                      <MDBCardTitle>
                        <img
                          src={route.icon}
                        />
                      </MDBCardTitle>
                      <MDBCardText>
                        {route.description}
                      </MDBCardText>
                      <Link to={route.route}>  
                        <MDBBtn color="deep-orange">Gerenciar</MDBBtn>
                      </Link>
                    </MDBCardBody>
                  </MDBCard>
                )
              })
            }
          </div>
        </div>
      </DashboardWrapper>
    )
  }
}

export default Dashboard