import React, { Component } from 'react'
import { Route } from 'react-router'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Slideshow from '../Carousel'
import { fetchLeasesThunk } from '../../store/actions/leasesActions'
import { Card, CardBody, CardImage, CardTitle, CardText, Col, MDBBtn, MDBIcon } from 'mdbreact';

class LeasesCategories extends Component {
  componentDidMount () {
    //const script = document.createElement("script");

    //script.src = "https://cdn.jsdelivr.net/npm/publicalbum@latest/dist/pa-embed-player.min.js";
    //script.async = true;

    //document.body.appendChild(script);
    this.props.fetchLeasesThunk()
}

componentDidUpdate() {
  this.props.fetchLeasesThunk()
}

render() {
  const { leases } = this.props
  
  return (
    <div className="container">
    <Route exact path="/locacoes" render={() => (
      <div>
      <h1 className="h1-responsive font-bold mt-sm-5 wow fadeInLeft">Locação <small>de espaços</small></h1>
      <hr className=" rgba-cyan-strong wow fadeInLeft" data-wow-delay="0.3s" ></hr>
      <Col className="d-flex justify-content-start flex-wrap">
      {
        leases === 'loading' ? (
          <div>Carregando...</div>
        ) : (
          leases && leases.map((lease, index) => {
            return (
              <Card key={index} style={{ width: "20rem", margin: "20px" }}>
                <CardImage
                  className="img-fluid ml-4"
                  src={lease.icon}
                  waves
                />
                <CardBody>
                  <CardTitle>{lease.type}</CardTitle>
                  <CardText>
                    {lease.description}
                  </CardText>
                  <Link to={{pathname: `/locacoes/${lease.route}`}}>
                      <button type="button" className="btn light-blue">Ver espaço</button>
                  </Link>
                </CardBody>
              </Card>
            )
          })
        ) 
      }
      </Col>
      </div>
    )}
    /> 
    </div>
  )
}
}

const mapStateToProps = state => ({
  leases: state.leases
})

const mapDispatchToProps = dispatch => {
  dispatch(fetchLeasesThunk())
  return {
    fetchLeasesThunk: () => dispatch(fetchLeasesThunk())
  }
 }

export default connect(mapStateToProps, mapDispatchToProps)(LeasesCategories)