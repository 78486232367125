import React from 'react'
import { func, string } from 'prop-types';

import { MDBInput } from 'mdbreact'

export function HomesForm(props){
  return(
    <form>
      <MDBInput
        label="Titulo (Venda, Aluguel...)"
        name="title"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.title}
      />
      <MDBInput
        label="Preço"
        name="price"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.price}
      />
      <MDBInput
        label="Contato"
        name="contact"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.contact}
      />
      <MDBInput 
        type="text" 
        label="Taxas (Condomínio...)"
        name="taxes" 
        onChange={props.handleInputChange} 
        value={props.taxes}
      />
      <MDBInput
        label="Quartos"
        name="bedRooms"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.bedRooms}
      />
      <MDBInput
        label="Banheiros"
        name="bathRooms"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.bathRooms}
      />
      <MDBInput
        label="Portaria (opcional)"
        name="reception"
        onChange={props.handleInputChange}
        group
        rows="2"
        type="textarea"
        success="right"
        value={props.reception}
      />
      <MDBInput
        label="Saiba mais (link para site externo)"
        name="link"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.link}
      />
      <MDBInput
        label="Localização"
        name="location"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.location}
      />
      <MDBInput
        label="Reformas (opcional)"
        name="restoration"
        onChange={props.handleInputChange}
        group
        rows="3"
        type="textarea"
        success="right"
        value={props.restoration}
      />
      <MDBInput
        label="Informações adicionais"
        name="additionalInfo"
        onChange={props.handleInputChange}
        group
        rows="3"
        type="textarea"
        success="right"
        value={props.additionalInfo}
      />
    </form>
  )
}