import React from 'react'

import { MDBBtn, MDBCardText, MDBCard, MDBCardBody, MDBRow, MDBCardTitle } from 'mdbreact';

export function CategoryCard(props) {
  return(
    <MDBCard 
      id={props.id}
      style={{ width: `${props.width}`, position: 'relative', margin: '5px' }}>
      <MDBCardBody>
        <MDBRow className="d-flex flex-column justify-content-between align-items-start">
          <MDBCardTitle className="ml-3">
            {props.category.name}
          </MDBCardTitle>
          <MDBCardText className="pl-3 w-75" style={{ height: 'auto' }}>
            Sócio: {props.category.prices.associate}
          </MDBCardText>
          <MDBCardText className="pl-3 w-75" style={{ height: 'auto' }}>
            Não sócio: {props.category.prices.notAssociated}
           </MDBCardText>
           <MDBCardText className="pl-3 w-75" style={{ height: 'auto' }}>
            <i>Última modificação: {props.category.modifiedBy}</i>
           </MDBCardText>
          <div className={`d-flex flex-nowrap justify-content-between`}>
            <MDBBtn color="danger" onClick={props.onDelete} style={{ borderRadius: '50px' }}>
              Excluir categoria
            </MDBBtn>
            <MDBBtn color="info" onClick={props.onEdit} style={{ borderRadius: '50px' }}>
              EDITAR
            </MDBBtn>
          </div>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  )
}