import database from '../../firebase/index'
import { 
  FETCH_LESSONS,
  ADD_DAY,
  EDIT_DAY,
  DELETE_DAY,
  EDIT_PROFESSOR
} from './types'

export const fetchLessons = (lessons) => ({
  type: FETCH_LESSONS,
  lessons
})

export const addDay = (day, error) => ({
  type: ADD_DAY,
  day,
  error
})

export const editDay = (day, error) => ({
  type: EDIT_DAY,
  day,
  error
})

export const editProfessor = (name, contact, lesson) => ({
  type: EDIT_PROFESSOR,
  name,
  contact,
  lesson
})

export const deleteDay = (day, error) => ({
  type: DELETE_DAY,
  day,
  error
})

export function addLessonThunk(action) {
  return (dispatch) => {
    const day = {
      name: action.name || '',
      lesson1: action.lesson1 || '',
      lesson2: action.lesson2 || '',
      lesson3: action.lesson3 || '',
      modifiedBy: JSON.parse(localStorage.getItem('auth')).user.displayName
    }
    const price = {
      service: action.service || '',
      associate: {
        price: action.associate || ''
      },
      notAssociated: {
        price: action.notAssociated || ''
      },
      modifiedBy: JSON.parse(localStorage.getItem('auth')).user.displayName
    }

    const category = {
      type: action.name || '',
      route: action.name.toLowerCase().replace(/[áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]/g, '') || '',
      professors: action.professors || '',
      prices: action.categoryPrices || '',
      path: action.type || '',
      modifiedBy: JSON.parse(localStorage.getItem('auth')).user.displayName
    }

    const { type } = action
    const isCategory = type === 'categories' && category
    const isDays = type === 'days' && day
    const isPrices = type === 'prices' && price
    const payload = isCategory || isDays || isPrices
    debugger
    return database.ref(`${action.pushRef}/${action.type}`).push(payload).then((ref) => {
      dispatch(addDay({
        ...payload
      }));
    })
    .catch(function(err){
      dispatch(addDay({
        ...payload,
        error: err | `Erro ao adicionar ${action.name}. Tente novamente mais tarde.`
      }))
    })
  }
}

export function editProfessorThunk(action) {
  return (dispatch) => {
    const updatedProfessor = {
      name: action.professorName,
      lesson: action.professorLesson,
      contact: action.professorContact,
      modifiedBy: JSON.parse(localStorage.getItem('auth')).user.displayName,
      key: action.id
    }
    debugger
    return database.ref(`${action.editRef}/${action.type}/${action.id}`).update(updatedProfessor).then((ref) => {
      dispatch(editProfessor({
        ...updatedProfessor
      }));
    })
    .catch(function(err){
      dispatch(editProfessor({
        ...updatedProfessor,
        error: err | `Erro ao adicionar ${action.professorName}. Tente novamente mais tarde.`
      }))
    })
  }
}

export function editLessonThunk(action) {
  return (dispatch) => {
    const day = {
      name: action.name || '',
      lesson1: action.lesson1 || '',
      lesson2: action.lesson2 || '',
      lesson3: action.lesson3 || '',
      modifiedBy: JSON.parse(localStorage.getItem('auth')).user.displayName
    }
    const price = {
      service: action.service || '',
      associate: {
        price: action.associate || ''
      },
      notAssociated: {
        price: action.notAssociated || ''
      },
      modifiedBy: JSON.parse(localStorage.getItem('auth')).user.displayName
    }

    const category = {
      type: action.name || '',
      professors: action.professors || '',
      prices: action.categoryPrices || '',
      modifiedBy: JSON.parse(localStorage.getItem('auth')).user.displayName
    }

    const { type } = action
    const isCategory = type === 'categories' && category
    const isDays = type === 'days' && day
    const isPrices = type === 'prices' && price
    const payload = isCategory || isDays || isPrices
    debugger
    return database.ref(`${action.editRef}/${action.type}/${action.id}`).update(payload).then((ref) => {
      dispatch(editDay({
        ...payload
      }));
    })
    .catch(function(err){
      dispatch(editDay({
        ...payload,
        error: err | `Erro ao adicionar ${action.name}. Tente novamente mais tarde.`
      }))
    })
  }
}

export function deleteLessonThunk({ id, deleteRef, type } = {}) {
  return (dispatch) => {
    return database.ref(`${deleteRef}/${type}/${id}`).remove().then((res) => {
      dispatch(deleteDay({id}))
    })
    .catch((err) => {
      dispatch(deleteDay({
        id,
        error: err || `Erro ao deletar item. Tente novamente mais tarde.`
      }))
    })
  }
}

export function fetchLessonsThunk() {
  return dispatch => {
    const lessons = [];
    database.ref('lessons').once('value', snap => {
      snap.forEach(data => {
        let lesson = data.val();
        lesson = Object.assign({ key: data.key }, lesson)
        lesson.days && Object.keys(lesson.days).map(key => {
          lesson.days[key] = Object.assign({ key: key }, lesson.days[key])
        })
        lesson.prices && Object.keys(lesson.prices).map(key => {
          lesson.prices[key] = Object.assign({ key: key }, lesson.prices[key])
        })
        lessons.push(lesson)
        })
    })
    .then(() => dispatch(
      fetchLessons(lessons)
    ))
  }
}