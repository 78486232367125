import {
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  LOG_OUT,
  ALREADY_LOGGED_IN,
  NOT_LOGGED_IN
} from '../actions/types'

const initialState = {
  loggedIn: false,
  loggingOut: false
}

export function auth(state = initialState, action){
  switch(action.type) {
    case LOGIN_USER_SUCCESS:
      const { user } = action
      return { 
        ...state,
        loggedIn: true,
        user  
      }
    case LOGIN_USER_FAIL:
      return { 
        ...state,
        loggedIn: false }
    case LOG_OUT:
      return { 
        loggingOut: true,
        loggedIn: false,
        user: null
      }
    case ALREADY_LOGGED_IN:
      return {
        ...state,
        loggedIn: true,
        user: action.user
      }
    case NOT_LOGGED_IN:
      return {
        ...state,
        loggedIn: false
      }
    default:
      return { 
        ...state //CHANGE SHIT HERE TO loggedIn: true IF I WANT TO TEST SHIT FASTERRR
      }
  }
}