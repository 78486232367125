import React, { Component } from 'react'
import styled from 'styled-components'

import { MDBBtn, MDBIcon } from 'mdbreact'

import { handlePictureModal } from '../../store/actions/adsPicturesActions'
import { setEditId, setDeleteId } from '../../store/actions/classifiedAdsActions'
import { connect } from 'react-redux'

import { AdsCard } from './AdsCard'
import CreateAd from './CreateAd'
import DeleteAd from './DeleteAd'
import EditAd from './EditAd'

import UploadImage from '../Upload/UploadImage'

import { Header } from '../Layout/Header'
import SignIn from '../Authentication/SignIn';

class ClassifiedAdsAdmin extends Component {
  state = {
    pushModal: false,
    editModal: false,
    deleteModal: false,
    pictureModal: false,
    editId: ''
  }
  
  togglePushModal = () => {
    this.setState({
      pushModal: !this.state.pushModal
    });
  }

  toggleEditModal = () => {
    this.setState({
      editModal: !this.state.editModal
    })
  }

  toggleDeleteModal = () => {
    this.setState({
      deleteModal: !this.state.deleteModal
    })
  }

  togglePictureModal = () => {
    this.setState({
      pictureModal: !this.state.pictureModal
    })
  }

  render() {
    const { ad, pushRef, selectedAd, selectedType, selectedId, id, isAuthenticated } = this.props
    
    if(isAuthenticated.loggedIn) {
      return (
        <div>
            <Header text={`Classificados - ${ad.type} | `} routeBack='/admin/classificados' />
          <div className={`container d-flex justify-content-center flex-wrap`}>
             {
              this.state.pushModal &&
                <CreateAd
                  toggle={this.togglePushModal}
                  modal={this.state.pushModal}
                  pushRef={pushRef}
                />
            }
            {
              this.props.pictureModal &&
                <UploadImage
                  toggle={() => {this.props.dispatch(handlePictureModal('', '', false))}}
                  modal={this.props.pictureModal}
                  getImages
                  pushRef={`classifiedAds/${selectedType}/ads/${selectedId}/pictures`}
                  add={`fotos para anúncio de ${selectedType}`}
                />
            }
            {
              this.state.editModal && 
                <EditAd 
                  toggle={this.toggleEditModal}
                  modal={this.props.editModal}
                  editInfo={this.props.selectedAd}
                  id={id}
                  editRef={pushRef}
                />
            }
            {
              this.state.deleteModal &&
                <DeleteAd 
                  toggle={this.toggleDeleteModal}
                  modal={this.props.deleteModal}
                  deleteInfo={this.props.delete}
                  deleteId={this.props.deleteId}
                  deleteRef={pushRef}
                />
            }
            <div className="d-flex flex-wrap justify-content-center align-items-center">
              <MDBBtn outline color="success" onClick={this.togglePushModal}>
                <MDBIcon icon="plus" /> Adicionar anúncio
              </MDBBtn>
            </div>
          </div>
          <div className={`container pt-3 d-flex justify-content-start flex-wrap`}>
            {
              ad.ads ? 
                Object.keys(ad.ads).map((key) => {
                  return (
                    <AdsCard 
                      title={ad.ads[key].title}
                      name={ad.ads[key].name}
                      model={ad.ads[key].model}
                      year={ad.ads[key].year}
                      city={ad.ads[key].city}
                      price={ad.ads[key].price}
                      taxes={ad.ads[key].taxes}
                      location={ad.ads[key].location}
                      bathRooms={ad.ads[key].bathRooms}
                      bedRooms={ad.ads[key].bedRooms}
                      additionalInfo={ad.ads[key].additionalInfo}
                      restoration={ad.ads[key].restoration}
                      dealer={ad.ads[key].dealer}
                      contact={ad.ads[key].contact}
                      modifiedBy={ad.ads[key].modifiedBy}
                      width="20em"
                      id={key}
                      key={key}
                      onPicture={() => {this.props.dispatch(handlePictureModal(ad.ads[key].type, key, true))}}
                      onDelete={() => { this.props.dispatch(setDeleteId(ad.ads[key], key, true)); this.toggleDeleteModal(); }}
                      onEdit={() => { this.props.dispatch(setEditId(ad.ads[key], key, true)); this.toggleEditModal(); }}
                    />
                  )
                }) : (
                  <div>Nenhum anúncio foi encontrado nessa categoria</div>
                )
            }
          </div>
        </div>
      )
    } else {
      return <SignIn />
    }
  }
}

const mapStateToProps = state => ({
  id: state.addClassifiedAds.editId,
  delete: state.addClassifiedAds.delete,
  deleteId: state.addClassifiedAds.deleteId,
  deleteModal: state.addClassifiedAds.deleteModal,
  selectedAd: state.addClassifiedAds.edit,
  editModal: state.addClassifiedAds.editModal,
  pictureModal: state.adPictures.modal,
  selectedId: state.adPictures.selectedId,
  selectedType: state.adPictures.selectedType,
  isAuthenticated: state.auth
})

export default connect(mapStateToProps)(ClassifiedAdsAdmin)