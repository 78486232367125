import React from 'react'

export default function Shops() {
  return(
    <div class="container mt-5">
      <p className="mb-3">A ASHCLIN possui lojas de diversos seguimentos: roupas, artesanato, jóias e bijuterias, artigos para o lar, cosméticos, bazar. Aberta ao publico em geral. Horário de funcionamento: <b>7:00 às 17:00 de segunda a quinta e das 7:00 às 16:00 nas sextas</b>. Aceitamos cartões de crédito.</p>
      <img className="w-25 m-2" src={require("../../assets/shops/ANA RAMOS.jpg")} alt="Loja" />
      <img className="w-25 m-2" src={require("../../assets/shops/ATELIER D'MARCA.jpg")} alt="Loja" />
      <img className="w-25 m-2" src={require("../../assets/shops/LIANE PIJAMAS.jpg")} alt="Loja" />
      <img className="w-25 m-2" src={require("../../assets/shops/LULIGULI DOCES.jpg")} alt="Loja" />
      <img className="w-25 m-2" src={require("../../assets/shops/LULUGULI MODA INFANTIL.jpg")} alt="Loja" />
      <img className="w-25 m-2" src={require("../../assets/shops/OCEANIC.jpg")} alt="Loja" />
      <img className="w-25 m-2" src={require("../../assets/shops/BETH LIVRO.jpg")} alt="Loja" />
    </div>
  )
}