import React from 'react'
import { object, func, string } from 'prop-types';

import { MDBInput, MDBBtn, MDBListGroup, MDBListGroupItem } from 'mdbreact'

const propTypes = {
  handleInputChange: func.isRequired,
  name: string.isRequired,
  professorName: object.isRequired,
}

export function ProfessorForm(props){
  
  return(
    <form>
      <MDBInput
        label="Professor(a)"
        name="professorName"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.professorName}
      />
      <MDBInput
        label="Aulas"
        name="professorLesson"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.professorLesson}
      />
      <MDBInput
        label="Contato"
        name="professorContact"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.professorContact}
      />
    </form>
  )
}