import { 
  FETCH_LEASES,
  ADD_SHIFT,
  EDIT_SHIFT,
  DELETE_SHIFT
} from '../actions/types'

export function leases (state = 'loading', action) {
  switch(action.type) {
    case FETCH_LEASES:
      return action.leases;
    default:
      return state;
  }
}

export function leasesCrud (state= {}, action) {
  switch(action.type) {
    case ADD_SHIFT:
      return {
        ...state,
        shift: action.shift,
        error: action.error
      }
    case DELETE_SHIFT:
      return {
        ...state,
        shift: action.id,
        error: action.error
      }
    case EDIT_SHIFT:
      return {
        ...state,
        shift: action.id,
        error: action.error
      }
  }
}