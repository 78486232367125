import database from '../../firebase/index'
import { 
  FETCH_LEASES,
  ADD_SHIFT,
  EDIT_SHIFT,
  DELETE_SHIFT
} from './types'

export const fetchLeases = (leases) => ({
  type: FETCH_LEASES, leases
})

const addShift = (id, success, shift, error) => ({
  type: ADD_SHIFT,
  id,
  success,
  shift,
  error
})

const deleteShift = ({ id, error } = {}) => ({
  type: DELETE_SHIFT,
  id,
  error
})

const editShift = (id, updatedShift, error) => ({
  type: EDIT_SHIFT,
  id,
  updatedShift,
  error
})

export function addShiftThunk(action) {
  return (dispatch) => {
    const shift = {
      shift: action.shift || '',
      schedule: action.schedule || '',
      cash: action.cash || '',
      creditCard: action.creditCard || '',
      type: action.type || '',
      modifiedBy: JSON.parse(localStorage.getItem('auth')).user.displayName
    }
    debugger
    return database.ref(action.pushRef).push(shift).then((ref) => {
      dispatch(addShift({
        id: ref.key,
        success: true,
        ...shift
      }));
    })
    .catch(function(err){
      dispatch(addShift({
        id: '',
        success: false,
        ...shift,
        error: err | `Erro ao adicionar ${action.name}. Tente novamente mais tarde.`
      }))
    })
  }
}

export function deleteShiftThunk({ id, deleteRef } = {}) {
  debugger
  return (dispatch) => {
    return database.ref(`${deleteRef}/${id}`).remove().then((res) => {
      dispatch(deleteShift({id}))
    })
    .catch((err) => {
      dispatch(deleteShift({
        id: id,
        error: err || `Erro ao deletar turno. Tente novamente mais tarde.`
      }))
    })
  }
}

export function editShiftThunk(action) {
  return (dispatch) => {
    const updatedShift = {
      shift: action.shift || '',
      schedule: action.schedule || '',
      cash: action.cash || '',
      creditCard: action.creditCard || '',
      type: action.type || '',
      modifiedBy: JSON.parse(localStorage.getItem('auth')).user.displayName
    }
    return database.ref(`${action.editRef}/${action.id}`).update(updatedShift).then((res) => {
      dispatch(editShift(action.id, updatedShift))
    })
    .catch((err) => {
      dispatch(editShift({
        id: action.id,
        updatedShift,
        error: err || `Erro ao editar turno. Tente novamente mais tarde.`
      }))
    })
  }
}

export function fetchLeasesThunk() {
  return dispatch => {
    const leases = [];
    database.ref('leases').once('value', snap => {
      snap.forEach(data => {
        let lease = data.val();
        lease = Object.assign({ key: data.key }, lease)
        Object.keys(lease.shifts).map(key => {
          lease.shifts[key] = Object.assign({ key: key }, lease.shifts[key])
        })
        leases.push(lease)
        })
    })
    .then(() => dispatch(
      fetchLeases(leases)
    ))
  }
}