import React, { Component } from 'react';
import { string, func, bool } from 'prop-types'
import firebase from 'firebase'

import { connect } from 'react-redux';
import { addCovenantThunk, fetchCovenantsThunk } from '../../store/actions/covenantActions'

import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { CovenantForm } from './CovenantForm';

class CreateCovenant extends Component {
  static propTypes = {
    modal: bool.isRequired,
    toggle: func.isRequired,
    pushRef: string.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      contact: '',
      description: '',
      link: '',
      name: '',
      owner: '',
      newCovenant: false,
      error: false,
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.push = this.push.bind(this)
    this.userChange = this.userChange.bind(this)
  }

  handleInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({
      [name]: value
    })
  }

  pushResponse(){
    const { covenants } = this.props
    const { newCovenant, error } = this.state
    if(covenants.newCovenant && newCovenant) {
      alert(`${covenants.newCovenant.name} adicionado ao convênios!`)
      this.setState({ contact: '', description: '', link: '', name: '', owner: '' , newCovenant: false })
    } else if(covenants.error && error) {
      alert(covenants.error)
      this.setState({ error: false })
    }
  }

  userChange() {
    firebase.auth().createUserWithEmailAndPassword('gerencia.ashclin@gmail.com', 'v3r@@53cl1n')
    .then((userCredentials)=>{
        if(userCredentials.user){
          userCredentials.user.updateProfile({
            displayName: 'Vera'
          }).then((s)=> {
            console.log('done')
          })
        }
    })
    .catch(function(error) {
      alert(error.message);
    });
  }

  async push() {
    const { contact, description, link, name, owner } = this.state
    const { pushRef } = this.props
    if( name ) {
      const newCovenant = {
        contact,
        description,
        link, 
        name,
        owner, 
        pushRef
      }  
      this.props.fetchCovenants()
      return this.props.addCovenant(newCovenant)
      .then(function(){
        return this.setState({
          newCovenant: true
        }, () => this.pushResponse())
      }.bind(this))
      .catch(function(){
        return this.setState({
          error: true
        }, () => this.pushResponse())
      }.bind(this))
    } else {
      alert("Por favor, preencha ao menos o nome.")
    }
  }

render() {
  const { toggle, modal } = this.props

  return (
      <MDBModal isOpen={modal} toggle={toggle}>
        <MDBModalHeader toggle={toggle}>Adicionar convênio</MDBModalHeader>
        <MDBModalBody>
          <CovenantForm 
            handleInputChange={this.handleInputChange}
            contactValue={this.state.contact}
            descriptionValue={this.state.description}
            linkValue={this.state.link}
            nameValue={this.state.name}
            ownerValue={this.state.owner}
          />
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={toggle}>Voltar</MDBBtn>
          <MDBBtn color="success" onClick={this.push}>Adicionar</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    );
  }
}

const mapStateToProps = state => ({
  covenants: state.covenants
})

const mapDispatchToProps = dispatch => {
  return {
    addCovenant: (covenant) => dispatch(addCovenantThunk(covenant)),
    fetchCovenants: () => dispatch(fetchCovenantsThunk())
  }
 }

export default connect(mapStateToProps, mapDispatchToProps)(CreateCovenant);