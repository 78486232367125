import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import Home from './components/Layout/Home' 
import Covenant from './components/Convenant/Covenant'
import CovenantCategories from './components/Convenant/CovenantCategories'
import Address from './components/Layout/Address'
import LessonsList from './components/Lessons/LessonsList'
import GalleryCategories from './components/Gallery/GalleryCategories'
import Contact from './components/Layout/Contact'
import ClassifiedAds from './components/ClassifiedAds/ClassifiedAds'
import ClassifiedAdsCategories from './components/ClassifiedAds/ClassifiedAdsCategories'
import LeasesCategories from './components/Leases/LeasesCategories'
import Leases from './components/Leases/Leases'
import About from './components/Layout/About'
import Elections from "./components/Layout/Elections"
import Retired from "./components/Layout/Retired"
import Market from "./components/Layout/Market"
import Shops from "./components/Layout/Shops"
import SocialPark from "./components/Layout/SocialPark"

import SignIn from './content_management/Authentication/SignIn'

import RequireAuth from "./content_management/Authentication/RequireAuth"
import Dashboard from './content_management/Layout/Dashboard'
import Covenants from './content_management/Covenants/Covenants'
import CovenantAdmin from './content_management/Covenants/CovenantAdmin'
import SlideshowManager from './content_management/Slideshow/SlideshowManager'
import ClassifiedAdsList from './content_management/ClassifiedAds/ClassifiedAdsList'
import ClassifiedAdsAdmin from './content_management/ClassifiedAds/ClassifiedAdsAdmin'
import Galleries from './content_management/Galleries/Galleries'
import LeasesCategoriesAdmin from './content_management/Leases/LeasesCategoriesAdmin'
import LeasesAdmin from './content_management/Leases/LeasesAdmin'
import LessonsAdmin from "./content_management/Lessons/LessonsAdmin"
import LessonShifts from './content_management/Lessons/LessonShifts'

class Routes extends Component {
  
  render() {
    const { classifiedAds, covenants, leases, lessons } = this.props
    
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/convenios" component={CovenantCategories} />
        <Route exact path="/mapa" component={Address} />
        <Route exact path="/atividades" component={LessonsList} />
        <Route exact path="/contato" component={Contact} />
        <Route exact path="/galeria" component={GalleryCategories} />
        <Route exact path="/classificados" component={ClassifiedAdsCategories} />
        <Route exact path="/galerias" component={GalleryCategories} />
        <Route exact path="/locacoes" component={LeasesCategories} />
        <Route exact path="/sobre" component={About} />
        <Route exact path="/eleicoes" component={Elections} />
        <Route exact path="/aposentados" component={Retired} />
        <Route exact path="/lojas" component={Shops} />
        <Route exact path="/parquesocial" component={SocialPark} />
        <Route exact path="/login" component={RequireAuth(SignIn)} />
        <Route exact path="/admin" component={RequireAuth(Dashboard)} />
        <Route exact path="/admin/galerias" component={RequireAuth(Galleries)} />
        <Route exact path="/admin/convenios" component={RequireAuth(Covenants)} />
        <Route exact path="/admin/slideshow" component={RequireAuth(SlideshowManager)} />
        <Route exact path="/admin/classificados" component={RequireAuth(ClassifiedAdsList)} />
        <Route exact path="/admin/locacoes" component={RequireAuth(LeasesCategoriesAdmin)} />
        <Route exact path="/admin/parquesocial" component={RequireAuth(LessonsAdmin)} />
        {
          leases !== 'loading' && leases.map((lease, index) => {
              return (
                <Route 
                  exact path={`/locacoes/${lease.route}`} 
                  render={(props) => <Leases {...props} lease={lease} />} 
                  key={index} 
                />
              )
            })
        }
        {
          leases !== 'loading' && leases.map((lease, index) => {
              return (
                <Route 
                  exact path={`/admin/locacoes/${lease.route}`} 
                  render={(props) => <LeasesAdmin {...props} pushRef={`leases/${lease.route}/shifts`} lease={lease} />} 
                  key={index} 
                />
              )
            })
        }
        {
          lessons !== 'loading' && lessons.map((lesson, index) => {
              return (
                <Route 
                  exact path={`/admin/parquesocial/${lesson.route}`} 
                  render={(props) => <LessonShifts {...props} pushRef={`lessons/${lesson.key}`} lesson={lesson} />} 
                  key={index} 
                />
              )
            })
        }
        {
          covenants !== 'loading' && covenants.map((covenant, index) => {
            return (
              <Route 
                exact path={`/convenios/${covenant.route}`} 
                render={(props) => <Covenant {...props} covenant={covenant} />} 
                key={index} 
              />
            )
          })
        }
         {
          covenants !== 'loading' && covenants.map((covenant, index) => {
            return (
              <Route
                exact path={`/admin/convenios/${covenant.route}`}
                render={(props) => <CovenantAdmin {...props} pushRef={`covenant/${covenant.route}/covenants`} covenant={covenant} />}
                key={`adminconvenios-${index}`}
              />
            )
          })
        }
        {
          classifiedAds !== 'loading' && classifiedAds.map((ad, index) => {
            return (
              <Route
                exact path={`/admin/classificados/${ad.route}`}
                render={(props) => <ClassifiedAdsAdmin {...props} pushRef={`classifiedAds/${ad.route}/ads`} ad={ad} />}
                key={`adminads-${index}`}
              />
            )
          })
        }
        {
          classifiedAds !== 'loading' && classifiedAds.map((ad, index) => {
            return(
              <Route 
                exact path={`/classificados/${ad.route}`} 
                render={(props) => <ClassifiedAds {...props} ad={ad}/>} 
                key={index} 
              />
            )
          })
        }
      </Switch>
    )
  }
}

 export default Routes