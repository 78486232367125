import React, { Component } from 'react'
import { string, array } from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Button, Card, CardBody, CardTitle, CardText, MDBIcon, MDBRow } from 'mdbreact';

import CarouselClassifiedAds from '../Layout/CarouselClassifiedAds'

const Details = styled.span`
  display: flex
  align-items: ;
  margin-top: 5px;

  .fa {
    min-width: 20px;
    width: 20px;
  }

  .bedrooms {
    margin-left: 10px;
  }

  .bedrooms span {
    margin-left: 3px;
  }

  div:nth-child(2) {
    margin-left: 10px;
  }
`

class ClassifiedAds extends Component {
  static propTypes = {
    type: string,
    classifiedAds: array
  }

  static defaultProps = {
    type: null
  }

  render() {
    const { ad } = this.props

    ad.ads && Object.keys(ad.ads).forEach( key => { ad.ads[key] = Object.assign( { 'key': key }, ad.ads[key] ) } )
    const sortedArray = ad.ads ? Object.keys(ad.ads).map( key => ad.ads[key]) : []

    return(
      <div className="container">
      <h1 className="h1-responsive font-bold mt-sm-5 wow fadeInLeft">
        <Link to="/classificados">  
          <MDBIcon icon="arrow-left" size="lg" className="pr-3 cyan-text" />
        </Link>
         {ad.type}
      </h1>
        <hr className="mt-2 mb-4 rgba-cyan-strong wow fadeInLeft" data-wow-delay="0.3s" ></hr>
        <div className="d-flex flex-wrap">
        {
          ad.ads ?
          sortedArray.sort( (a,b) => new Date(b.date) - new Date(a.date) ).map((key) => {
            return(
              <Card key={key.key} style={{ width: "21rem", margin: "10px" }}>
              <CardBody>
                {
                  key.pictures ?
                (
                  <CarouselClassifiedAds 
                    category={`classifiedAds/${key.type}/ads/${key.key}/pictures`}
                    storageRef="ads"
                    ad
                    imageHeight="auto"
                  /> 
                ) : (
                  <img 
                    src="https://www.freeiconspng.com/uploads/no-image-icon-1.jpg"
                    height="320px" 
                    width="100%"
                  />
                  )
                }
                {
                key.title || key.model &&
                <CardTitle className="mt-2">
                  {key.title || key.model} {key.year}
                </CardTitle>
                }
                 <CardText>
                  {
                    key.price &&
                    <Details>
                      <MDBIcon icon="dollar" className="teal-text" /> 
                      <span>
                        {key.price}
                      </span>
                    </Details>
                  }
                  {
                    key.taxes &&
                    <Details>
                    <MDBIcon icon="money" className="teal-text" />
                    <span>
                      {key.taxes}
                    </span>
                    </Details>
                  }
                  {
                    key.location &&
                    <Details>
                      <MDBIcon icon="map-marker" className="teal-text" />
                      <span>{key.location}</span>
                    </Details>
                  }
                  <Details>
                  {
                    key.bathRooms &&
                    <span className="bathrooms">
                      <MDBIcon icon="bath" className="teal-text" />
                      <span>{key.bathRooms}</span>
                    </span>
                  }
                  {
                    key.bedRooms &&
                    <span className="bedrooms">
                      <MDBIcon icon="bed" className="teal-text" />
                      <span>{key.bedRooms}</span>
                    </span>
                  }
                  </Details>
                  {
                    key.additionalInfo &&
                    <Details>
                      <MDBIcon icon="info" size="lg" className="teal-text" />
                      <span>{key.additionalInfo}</span>
                    </Details>
                  }
                  {
                    key.restoration &&
                    <Details>
                      <MDBIcon icon="cogs" className="teal-text" />
                      <span>{key.restoration}</span>
                    </Details>
                  }
                  {
                    key.dealer &&
                    <Details>
                      <MDBIcon icon="street-view" className="teal-text" />
                      <span>{key.dealer}</span>
                    </Details>
                  }
                  {
                    key.contact &&
                    <Details>
                      <MDBIcon icon="mobile-phone" className="teal-text" />
                      <span>{key.contact}</span>
                    </Details>
                  }
                  {
                    key.link &&
                    <Button href={key.link} target="_blank">Saiba mais</Button>
                  }
                 </CardText>
              </CardBody>
            </Card>
            )
          })
          :
          (
            <div>Nenhum anúncio foi encontrado nessa categoria</div>
          )
        }
        </div>
      </div>
    )
  }
}

export default ClassifiedAds