import database from '../../firebase/index'
import {
  ADD_COVENANT,
  EDIT_COVENANT,
  FETCH_COVENANTS,
  DELETE_COVENANT,
  SET_EDIT_COVENANT_ID, 
  SET_DELETE_COVENANT_ID 
} from '../actions/types'

const fetchCovenants = (covenants) => ({
  type: FETCH_COVENANTS, 
  covenants
})

const addCovenant = (covenant, error) => ({
  type: ADD_COVENANT,
  covenant,
  error
});

const editCovenant = (id, covenant, error) => ({
  type: EDIT_COVENANT,
  id,
  covenant,
  error
})

export const deleteCovenant = ({ id, name } = {}) => ({
  type: DELETE_COVENANT,
  id, 
  name
})

export const setId = (selectedCovenant = {
  contact: '',
  description: '',
  link: '',
  name: '',
  owner: '',
  id: ''
}, key) => ({
  type: SET_EDIT_COVENANT_ID,
  selectedCovenant,
  key
})

export const setRemoveId = (name = {}, id = {}) => ({
  type: SET_DELETE_COVENANT_ID,
  name,
  id
})

export function addCovenantThunk(action) {
  const date = new Date;
  return (dispatch) => {
    const covenant = {
      contact: action.contact || '',
      description: action.description || '',
      id: `${action.name.replace(/[záàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ ]+/g, "").toLowerCase()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`,
      link: action.link || '',
      name: action.name || '',
      owner: action.owner || '',
      modifiedBy: JSON.parse(localStorage.getItem('auth')).user.displayName
    }
     
    return database.ref(action.pushRef).push(covenant).then((ref) => {
      dispatch(addCovenant({
        id: ref.key,
        success: true,
        ...covenant
      }));
    })
    .catch(function(err){
      dispatch(addCovenant({
        error: err | `Erro ao adicionar ${action.name}. Tente novamente mais tarde.`
      }))
    })
  }
}

export function editCovenantThunk(action) {
  return (dispatch) => {
    const updatedCovenant = {
      contact: action.contact || '',
      description: action.description || '',
      link: action.link || '',
      name: action.name || '',
      owner: action.owner || '',
      modifiedBy: JSON.parse(localStorage.getItem('auth')).user.displayName
    }
    return database.ref(`${action.editRef}/${action.id}`).update(updatedCovenant).then((res) => {
      dispatch(editCovenant(action.id, updatedCovenant))
    })
    .catch((err) => {
      dispatch(editCovenant({
        error: err || `Erro ao editar ${action.covenant.name}. Tente novamente mais tarde.`
      }))
    })
  }
}

export function deleteCovenantThunk({ id, deleteRef, name } = {}) {
  return (dispatch) => {
    return database.ref(`${deleteRef}/${id}`).remove().then((res) => {
      dispatch(deleteCovenant({id, name}))
    })
    .catch((err) => {
      dispatch(deleteCovenant({
        error: err || `Erro ao deletar ${name}. Tente novamente mais tarde.`
      }))
    })
  }
}

export function fetchCovenantsThunk() {
  return dispatch => {
    const covenants = [];
    database.ref('covenant').once('value', snap => {
      snap.forEach(data => {
        let covenant = data.val();
        covenants.push(covenant)
        })
    })
    .then(() => dispatch(
      fetchCovenants(covenants)
    ))
  }
}


export function fetchCovenantsRoutesThunk() {
  return dispatch => {
    const covenants = [];
    database.ref('covenant').once('value', snap => {
      snap.forEach(data => {
        let covenant = data.val();
        covenants.push(covenant.route)
        })
    })
    .then(() => dispatch(
      fetchCovenants(covenants)
    ))
  }
}