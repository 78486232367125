//import authReducer from './authReducer'
//import projectReducer from './projectReducer'
import { combineReducers } from 'redux'
import { covenants } from './covenantReducer'
import { lessons } from './lessonsReducer'
import { classifiedAds, addClassifiedAds } from './classifiedAdsReducer'
import { adPictures } from './adsPicturesReducer'
import { galleries, galleriesCrud } from './galleryReducer'
import { leases } from './leasesReducer'
import { auth } from './loginReducer'
import { slideshow } from './slideshowReducer'

const rootReducer = combineReducers({
  covenants,
  lessons,
  classifiedAds,
  adPictures,
  addClassifiedAds,
  galleries,
  galleriesCrud,
  leases,
  auth,
  slideshow
});

export default rootReducer