import React from 'react'

import { MDBBtn, MDBCardText, MDBCard, MDBCardBody, MDBIcon, MDBRow, MDBCardTitle } from 'mdbreact';

export function AdsCard(props) {
  return(
    <MDBCard 
      id={props.id}
      style={{ width: `${props.width}`, margin: '10px' }}>
      <MDBCardBody>
        <MDBRow className="d-flex flex-column justify-content-between align-items-start">
          <MDBCardTitle className="ml-3">
            {props.title || props.model || props.name}
          </MDBCardTitle>
          <MDBCardText className="pl-3 w-75" style={{ height: 'auto' }}>
            Preço: {props.price}
          </MDBCardText>
          {
            props.year && 
            <MDBCardText className="pl-3 w-75" style={{ height: 'auto' }}>
              Ano: {props.year}
            </MDBCardText>
          }
          {
            props.city && 
            <MDBCardText className="pl-3 w-75" style={{ height: 'auto' }}>
              Cidade: {props.city}
            </MDBCardText>
          }
          {
            props.taxes && 
            <MDBCardText className="pl-3 w-75" style={{ height: 'auto' }}>
              Taxas: {props.taxes}
            </MDBCardText>
          }
          {
            props.bathRooms && 
            <MDBCardText className="pl-3 w-75" style={{ height: 'auto' }}>
              Banheiros: {props.bathRooms}
            </MDBCardText>
          }
          {
            props.bedRooms && 
            <MDBCardText className="pl-3 w-75" style={{ height: 'auto' }}>
              Quartos: {props.bedRooms}
            </MDBCardText>
          }
          {
            props.additionalInfo && 
            <MDBCardText className="pl-3 w-75" style={{ height: 'auto' }}>
              Informações adicionais: {props.additionalInfo}
            </MDBCardText>
          }
          {
            props.restoration && 
            <MDBCardText className="pl-3 w-75" style={{ height: 'auto' }}>
              Reformas: {props.restoration}
            </MDBCardText>
          }
          {
            props.dealer && 
            <MDBCardText className="pl-3 w-75" style={{ height: 'auto' }}>
              Vendedor: {props.dealer}
            </MDBCardText>
          }
          {
            props.contact && 
            <MDBCardText className="pl-3 w-75" style={{ height: 'auto' }}>
              Contato: {props.contact}
            </MDBCardText>
          }
          {
            props.location && 
            <MDBCardText className="pl-3 w-75" style={{ height: 'auto' }}>
              Local: {props.location}
            </MDBCardText>
          }
          {
            props.modifiedBy && 
            <MDBCardText className="pl-3 w-75" style={{ height: 'auto' }}>
              <i>Última modificação: {props.modifiedBy}</i>
            </MDBCardText>
          }
          <div className={`d-flex flex-nowrap justify-content-between`}>
            <MDBBtn color="danger" onClick={props.onDelete} style={{ borderRadius: '50px' }}>
              X
            </MDBBtn>
            <MDBBtn color="info" onClick={props.onEdit} style={{ borderRadius: '50px' }}>
              EDITAR
            </MDBBtn>
            <MDBBtn color="success" onClick={props.onPicture} style={{ borderRadius: '50px' }}>
              <MDBIcon icon="camera" className="white-text" />
            </MDBBtn>
          </div>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  )
}