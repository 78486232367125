import React, { Component } from 'react'
import { bool, func, string } from 'prop-types';

import { connect } from 'react-redux';
import { editShiftThunk, fetchLeasesThunk } from '../../store/actions/leasesActions'

import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { ShiftForm } from './ShiftForm';

class DeleteShift extends Component {
  static propTypes = {
    modal: bool.isRequired,
    toggle: func.isRequired,
    editRef: string.isRequired
  }

  constructor(props) {
    super(props);
    
    this.state = {
      id: props.editInfo.key,
      shift: props.editInfo.shift,
      schedule: props.editInfo.schedule,
      cash: props.editInfo.cash,
      creditCard: props.editInfo.creditCard,
      type: props.editInfo.type,
      modal: props.modal,
    }
    
    this.edit = this.edit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleType = this.handleType.bind(this)
  }

  handleInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({
      [name]: value
    })
  }

  handleType(event) {
    const value = event.target.value;
    this.setState({
      type: value
    })
  }

  edit(){
    const { shift, schedule, cash, creditCard, type, id } = this.state
    const { editRef } = this.props

    const editedShift = {
      shift,
      schedule,
      cash,
      creditCard,
      type,
      id,
      editRef
    }

    this.props.fetchLeases()
    this.props.editShift(editedShift).then(function(){
      return alert('Turno editado com sucesso!')
    })
    .catch(function(){
      return alert('Falha ao editar turno. Tente novamente mais tarde.')
    })
    this.props.toggle()
  }

  render() {
    const { toggle } = this.props
    
    return(
      <MDBModal isOpen={this.state.modal} toggle={toggle}>
        <MDBModalHeader toggle={toggle}>Editar turno</MDBModalHeader>
        <MDBModalBody>
          <select onChange={this.handleType} className="browser-default custom-select">
            <option>Selecione o tipo de locação</option>
            <option value="salaogrande">Salão grande</option>
            <option value="salaopequeno1">Salões pequenos</option>
            <option value="quisques">Quiosques</option>
            <option value="quadras">Quadras</option>
          </select>
          <ShiftForm 
            handleInputChange={this.handleInputChange}
            shift={this.state.shift}
            schedule={this.state.schedule}
            cash={this.state.cash}
            creditCard={this.state.creditCard}
          />
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={toggle}>Voltar</MDBBtn>
          <MDBBtn color="info" onClick={this.edit}>Editar</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    )
  }
}

const mapStateToProps = state => ({
  leases: state.leases
})

const mapDispatchToProps = dispatch => {
  return {
    editShift: (shift) => dispatch(editShiftThunk(shift)),
    fetchLeases: () => dispatch(fetchLeasesThunk())
  }
 }

export default connect(mapStateToProps, mapDispatchToProps)(DeleteShift)