export const FETCH_COVENANTS = 'FETCH_COVENANTS'
export const ADD_COVENANT = 'ADD_COVENANT'
export const EDIT_COVENANT = 'EDIT_COVENANT'
export const DELETE_COVENANT = 'DELETE_COVENANT'
export const SET_EDIT_COVENANT_ID = 'SET_EDIT_COVENANT_ID'
export const SET_DELETE_COVENANT_ID = 'SET_DELETE_COVENANT_ID'

export const FETCH_LESSONS = 'FETCH_LESSONS'
export const ADD_DAY = 'ADD_DAY'
export const EDIT_DAY = 'EDIT_DAY'
export const DELETE_DAY = 'DELETE_DAY'
export const EDIT_PROFESSOR = 'EDIT_PROFESSOR'

export const FETCH_CLASSIFIED = 'FETCH_CLASSIFIED'
export const ADD_AD = 'ADD_AD'
export const SET_AD_EDIT_ID = 'SET_AD_EDIT_ID'
export const SET_AD_DELETE_ID = 'SET_AD_DELETE_ID'
export const EDITED_AD = 'EDITED_AD'
export const DELETE_SUCCESS = 'DELETE_SUCCESS'
export const DELETE_FAIL = 'DELETE_FAIL'
export const DELETE_PENDING = 'DELETE_PENDING'

export const FETCH_GALLERIES = 'FETCH_GALLERIES'
export const ADD_GALLERY = 'ADD_GALLERY'
export const SET_EDIT_GALLERY = 'SET_EDIT_GALLERY'
export const SET_REMOVE_GALLERY = 'SET_REMOVE_GALLERY'
export const DELETED_GALLERY = 'DELETED_GALLERY'
export const EDITED_GALLERY = 'EDITED_GALLERY'

export const FETCH_LEASES = 'FETCH_LEASES'
export const ADD_SHIFT = 'ADD_SHIFT'
export const EDIT_SHIFT = 'EDIT_SHIFT'
export const DELETE_SHIFT = 'DELETE_SHIFT'

export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_FAIL = 'LOGIN_USER_FAIL'
export const LOG_OUT = 'LOG_OUT'
export const ALREADY_LOGGED_IN = 'ALREADY_LOGGED_IN'
export const NOT_LOGGED_IN = 'NOT_LOGGED_IN'

export const FETCH_SLIDES = 'FETCH_SLIDES'
export const EDIT_SLIDE = 'EDIT_SLIDE'
export const EDITED_SLIDE = 'EDITED_SLIDE'
export const SLIDE_NOT_EDITED = 'SLIDE_NOT_EDITED'
export const DELETE_SLIDE = 'DELETE_SLIDE'
export const DELETE_SLIDE_THUNK = 'DELETE_SLIDE_THUNK'
export const HANDLE_DELETE_MODAL = 'HANDLE_DELETE_MODAL'
export const HANDLE_EDIT_MODAL = 'HANDLE_EDIT_MODAL'