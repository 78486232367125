import React, { Component } from 'react'
import { bool, func, string } from 'prop-types';

import { connect } from 'react-redux';
import { deleteLessonThunk, fetchLessonsThunk } from '../../store/actions/lessonsActions'

import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';

class DeleteLesson extends Component {
  static propTypes = {
    modal: bool.isRequired,
    toggle: func.isRequired,
    deleteRef: string.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      id: props.id,
      type: props.type,
      modal: props.modal,
    }

    this.delete = this.delete.bind(this)
  }

  delete(){
    const { id, type } = this.state
    const { deleteRef } = this.props

    this.props.fetchLessons()
    this.props.deleteLesson({ id, deleteRef, type }).then(function(){
      return alert('Item deletado com sucesso!')
    })
    .catch(function(){
      return alert('Falha ao deletar item. Tente novamente mais tarde.')
    })
    this.props.toggle()
  }

  render() {
    const { toggle } = this.props

    return(
      this.state.modal ? 
      <MDBModal isOpen={this.state.modal} toggle={toggle}>
      <MDBModalHeader toggle={toggle}>Excluir item</MDBModalHeader>
      <MDBModalBody>
        <p>Tem certeza que deseja excluir este item?</p>
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn color="secondary" onClick={toggle}>Voltar</MDBBtn>
        <MDBBtn color="danger" onClick={this.delete}>Excluir</MDBBtn>
      </MDBModalFooter>
    </MDBModal> : null
    )
  }
}

const mapStateToProps = state => ({
  lessons: state.lessons
})

const mapDispatchToProps = dispatch => {
  return {
    deleteLesson: (lesson) => dispatch(deleteLessonThunk(lesson)),
    fetchLessons: () => dispatch(fetchLessonsThunk())
  }
 }

export default connect(mapStateToProps, mapDispatchToProps)(DeleteLesson)