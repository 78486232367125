import React, { Component } from 'react'

import { MDBBtn, MDBIcon } from 'mdbreact'
import { connect } from 'react-redux'

import { Header } from '../Layout/Header'
import { LeasesCard } from './LeasesCard'
import CreateShift from './CreateShift'
import EditShift from './EditShift'
import DeleteShift from './DeleteShift'
import SignIn from '../Authentication/SignIn'

class LeasesAdmin extends Component {
  state = {
    pushModal: false,
    editModal: false,
    deleteModal: false,
    shiftToBeDeleted: {},
    editId: ''
  }
  
  togglePushModal = () => {
    this.setState({
      pushModal: !this.state.pushModal
    });
  }

  toggleEditModal = () => {
    this.setState({
      editModal: !this.state.editModal
    })
  }

  toggleDeleteModal = () => {
    this.setState({
      deleteModal: !this.state.deleteModal
    })
  }

  render() {
    const { lease, pushRef, isAuthenticated } = this.props
    
    if(isAuthenticated.loggedIn) {
      return (
        <div>
        <Header text={`Locações - ${lease.type} | `} routeBack="/admin/locacoes" />
        <div className={`container d-flex justify-content-center flex-wrap`}>
          {
            this.state.pushModal &&
              <CreateShift
                toggle={this.togglePushModal}
                modal={this.state.pushModal}
                pushRef={pushRef}
              />
          }
          {
            this.state.editModal && 
              <EditShift
                toggle={this.toggleEditModal}
                modal={this.state.editModal}
                editInfo={this.state.shiftToBeEdited}
                editRef={pushRef}
              />
          }
          {
            this.state.deleteModal &&
              <DeleteShift
                toggle={this.toggleDeleteModal}
                modal={this.state.deleteModal}
                deleteInfo={this.state.shiftToBeDeleted}
                deleteRef={pushRef}
              />
          }
          <div className="d-flex flex-wrap justify-content-center align-items-center">
            <MDBBtn outline color="success" onClick={this.togglePushModal}>
              <MDBIcon icon="plus" /> Adicionar turno
            </MDBBtn>
          </div>
        </div>
        <div className={`container pt-3 d-flex justify-content-start flex-wrap`}>
          {
            lease.shifts ? 
              Object.keys(lease.shifts).map((key) => {
                return (
                  <LeasesCard 
                    shift={lease.shifts[key].shift}
                    schedule={lease.shifts[key].schedule}
                    cash={lease.shifts[key].cash}
                    creditCard={lease.shifts[key].creditCard}
                    modifiedBy={lease.shifts[key].modifiedBy}
                    width="22em"
                    id={key}
                    key={key}
                    onDelete={() => { this.setState({ shiftToBeDeleted: lease.shifts[key] }); this.toggleDeleteModal(); }}
                    onEdit={() => { this.setState({ shiftToBeEdited: lease.shifts[key] }); this.toggleEditModal(); }}
                  />
                )
              }) : (
                <div>Nenhum convênio foi encontrado nessa categoria</div>
              )
          }
        </div>
      </div>
      )
    } else {
      return <SignIn />
    }
  }
}

const mapStateToProps = state => ({
  leases: state.leases,
  isAuthenticated: state.auth
})

export default connect(mapStateToProps)(LeasesAdmin)