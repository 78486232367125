import React from 'react'
import { func, string } from 'prop-types';

import { MDBInput } from 'mdbreact'

const propTypes = {
  handleInputChange: func.isRequired,
  nameValue: string.isRequired,
  linkValue: string.isRequired,
  contactValue: string.isRequired,
  descriptionValue: string.isRequired,
  ownerValue: string.isRequired
}

export function CovenantForm(props){
  return(
    <form>
      <MDBInput
        label="Nome da empresa"
        name="name"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.nameValue}
      />
      <MDBInput
        label="Link"
        name="link"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.linkValue}
      />
      <MDBInput
        label="Contato"
        name="contact"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.contactValue}
      />
      <MDBInput
        label="Responsável"
        name="owner"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.ownerValue}
      />
      <MDBInput 
        type="textarea" 
        label="Descrição"
        name="description" 
        onChange={props.handleInputChange}
        rows="5" 
        value={props.descriptionValue}
      />
    </form>
  )
}