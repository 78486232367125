import React, {Component} from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import Recaptcha from 'react-recaptcha';
import { loginUserThunk } from '../../store/actions/loginActions'

import { MDBContainer, MDBCol, MDBInput, MDBBtn } from 'mdbreact';


class SignIn extends Component {
  constructor(props){
    super(props)
    
    this.state = {
      user_name: '',
      user_password: '',
      isReCaptchaVerified: false
    }

    this.signIn = this.signIn.bind(this)
    this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
    this.handleUserInputChange = this.handleUserInputChange.bind(this)
    this.verifyCallback = this.verifyCallback.bind(this);
  }
  static contextTypes = {
    router: PropTypes.object
  };
  
  componentWillMount() {
    if (this.props.auth.loggedIn) {
      this.context.router.history.push("/admin");
    }
  }

  handleUserInputChange(event) {
    const value = event.target.value
    const name = event.target.name
    this.setState({
      [name]: value
    })
  }

  signIn(){
    const { user_name, user_password, isReCaptchaVerified } = this.state
    if( isReCaptchaVerified ) {
      this.props.loginUserThunk(user_name, user_password)
    } else { 
      alert('Valide que você não é um robô.')
    }
  }

  verifyCallback(response) {
    if (response) {
      this.setState({
        isReCaptchaVerified: true
      })
    }
  }

  recaptchaLoaded() {
    console.log('captcha successfully loaded');
  }

  render() {
    const { user_name, user_password } = this.state
    return (
      <MDBContainer className="d-flex justify-content-center pt-5 pb-5">
        <MDBCol 
          size={window.matchMedia(`(max-width:520px)`).matches ? `12` : '6'}
        >
          <form>
            <p className="h5 text-center pt-5 mb-4">Administrador</p>
            <div className="grey-text">
              <MDBInput
                onChange={this.handleUserInputChange}
                label="Informe o seu email"
                icon="envelope"
                group
                type="email"
                validate
                name="user_name"
                value={ user_name }
                error="wrong"
                success="right"
              />
              <MDBInput
                onChange={this.handleUserInputChange}
                label="Informe a sua senha"
                name="user_password"
                value={ user_password }
                icon="lock"
                group
                type="password"
                validate
              />
            </div>
            <Recaptcha
              sitekey={process.env.REACT_APP_SITEKEY}
              render="explicit"
              onloadCallback={this.recaptchaLoaded}
              verifyCallback={this.verifyCallback}
            />
            <div className="text-center">
              <MDBBtn
                onClick={this.signIn}
              >
                Login
              </MDBBtn>
            </div>
          </form>
        </MDBCol>
    </MDBContainer>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth
})

const mapDispatchToProps = dispatch => {
  return {
    loginUserThunk: (user, passw) => dispatch(loginUserThunk(user, passw))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)