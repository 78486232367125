import React from 'react'

import { MDBBtn, MDBIcon } from 'mdbreact'

export default function Elections(){
  return(
    <div className="container pt-3 pb-5">
      {/* <form className="d-flex justify-content-end w-100">
        <MDBBtn
          color="btn btn-cyan"
          href={require('../../assets/download/listaDeSocios2018.xlsx')}
          target="_blank"
        >
          Baixar Lista de sócios <MDBIcon icon="file-download" size="lg" />
        </MDBBtn>
      </form> */}
      <h3 className="mt-3">ELEIÇÕES PARA DIRETORIA DA ASCHLIN</h3>
        <hr className="wow fadeInLeft" data-wow-delay="0.3s" />
        <h6>Resultado das eleições ASHCLIN/2018</h6>
        <p><span className="badge badge-success"> CHAPA 11 </span><span className="green-text"> Vencedora</span></p>
        <p><b>Presidente: Lani Brito Fagundes</b></p>
        <p><b>Vice-Presidente: Indara Carmanim Saccilotto</b></p>
        <hr className="wow fadeInLeft mb-3 mt-3" data-wow-delay="0.3s" />
        <i> A eleição para a escolha da nova diretoria da ASCHLIN ocorrerá nos dias 19, 20 e 21 de junho. Os candidatos que estão concorrendo são:</i>
        <p> </p>
        <span className="badge badge-default"> CHAPA 11 </span>
        <p> <b>Presidente: Lani Brito Fagundes</b></p>
        <p> <b>Vice-Presidente: Indara Carmanim Saccilotto</b></p>
        <p></p>
        <p></p>
        <span className="badge badge-default"> CHAPA 12</span>
        <p></p>
        <p> <b>Presidente: Nilcea Ernandes Pereira</b></p>
        <p></p>
        <p> <b>Vice- Presidente: Claudia Valéria de Souza</b></p>
        <p></p>
        <p></p>
        <p> Será utilizado o Sistema de Votação Eletrônica, disponível em todos os computadores da rede interna do HCPA. A partir da zero hora do DIA 19/06, o sistema estará liberado para receber os acessos dos eleitores, dando inicio às eleições, encerrando-se às vinte horas do DIA 21/06. Na votação deverá ser observado o seguinte:</p>
        <p></p>
        <p>- o eleitor deverá acessar o sistema do HCPA, intranet.</p>
        <p></p>
        <p>- o eleitor deverá fazer o login, com usuário e senha;</p>
        <p></p>
        <p>- ato contínuo surgirá a tela com a cédula oficial constando os candidatos concorrentes a presidente;</p>
        <p></p>
        <p>- o eleitor poderá assinalar um candidato a presidente.;</p>
        <p></p>
        <p>- O voto é facultativo e a eleição será válida com qualquer número de votantes.</p>
        <p></p>
        <p>- Será considerado voto válido apenas aquele dado a um candidato para presidente.</p>
        <p></p>
        <p>- Votos em branco ou nulo não serão computados como válidos.</p>
        <p></p>
        <p> Estarão disponíveis os computadores da sala de inclusão digital, localizada no subsolo, nos dias das eleições no horário das 11 h às 14 h.</p>
        <p></p>
        <p> Os associados aposentados poderão votar. Será utilizado cédula de papel. O hora´rio de votação será apenas no horário das 11 h às 14 h na sala da inclusão digital no subsolo.</p>
        <p></p>
        <p></p>
        <p></p>
        <b>Adm. Ademir Rama</b>
        <p style={{ color: "grey" }}>​Comissão Eleitoral​</p>
        <hr className="wow fadeInLeft" data-wow-delay="0.3s" />
        <h4 className="mt-3">Diretrizes para o Processo Eleitoral 2018/2020</h4>
        <p><b>CAPÍTULO I</b></p>
        <p>Das Providências Preliminares</p>
        <p>Da Comissão Eleitoral</p>
        <p>Art. 1o - O processo eleitoral será coordenado pela Comissão Eleitoral segundo as normas constantes</p>
        <p>das Diretrizes determinadas por decisão da Diretoria da ASHCLlN em consonância com seu Estatuto.</p>
        <p>Art. 2o - A Comissão Eleitoral será composta de seis membros indicados pela ASHCLlN.</p>
        <p>Art. 3o - A Comissão Eleitoral entrará em funcionamento logo após a indicação de seus membros.</p>
        <p>Art. 4o - A ASHCLlN oferecerá à Comissão Eleitoral os recursos requeridos para o pleno exercício de suas</p>
        <p>funções.</p>
        <p>Art. 5o - Compete a Comissão Eleitoral, observadas as diretrizes traçadas pela ASHCLlN:</p>
        <p>I - receber as inscrições dos candidatos e publicar a relação dos inscritos;</p>
        <p>II- supervisionar a campanha eleitoral;</p>
        <p>III - publicar as listas de eleitores;</p>
        <p>IV - emitir instruções sobre a maneira de votar;</p>
        <p>V - providenciar o material necessário à eleição;</p>
        <p>VI - estabelecer e controlar o processo eletrônico da votação;</p>
        <p>VII - publicar os resultados das eleições;</p>
        <p>VIII - julgar em grau de instância final os recursos interpostos nos termos do disposto no art. 49 do</p>
        <p>estatuto;</p>
        <p>IX - resolver os casos omissos.</p>
        <p>Dos Candidatos</p>
        <p>Art. 6o - Para o cargo de presidente, o candidato deverá pertencer ao quadro social há, no mínimo, um</p>
        <p>ano antes da data da eleição;</p>
        <p>Art. 7o - Poderão candidatar-se aos demais cargos da Diretoria da ASHCLlN e ao Conselho Fiscal os</p>
        <p>associados que pertençam ao quadro social há, no mínimo, 60 dias antes da data da eleição.</p>
        <p>§ 1o - Não poderão se candidatar funcionários com contrato de trabalho temporário.</p>
        <p>§ 2o - Os candidatos para o Conselho Fiscal serão eleitos por voto nominal e individual, sendo dois</p>
        <p>membros efetivos e dois suplentes, escolhidos pela ordem do número de votos.</p>
        <p>Dos Eleitores</p>
        <p>Art. 8o - São eleitores:</p>
        <p>I - Os associados da ASHCLlN que pertençam ao quadro social há mais de 60 dias, contados da data de</p>
        <p>publicação da relação dos associados, conforme cronograma definido no artigo 9.</p>
        <p>§ 1o - É vedado o voto por procuração ou correspondência.</p>
        <p>Do Calendário Eleitoral</p>
        <p>Art. 9° - O processo eleitoral subordinar-se-á ao seguinte calendário:</p>
        <p>24/04/2018 – Convocação das Eleições;</p>
        <p>24 a 25/04/2018 – Divulgação das Eleições;</p>
        <p>25/04 a 15/05/2018 – Período de inscrição das chapas para Diretoria e para membro do Conselho Fiscal;</p>
        <p>16/05/2018 – Divulgação das chapas inscritas e homologadas;</p>
        <p>17 a 18/05/2018 – Período de interposição de recursos relativos à homologação das inscrições;</p>
        <p>21/05/2018 – Divulgação dos resultados dos recursos e divulgação final da relação de inscrições;</p>
        <p>22/05 a 18/06/2018 – Período de campanha eleitoral;</p>
        <p>01/06/18 – divulgação da relação dos associados com direito a voto;</p>
        <p>04 a 06/06/18 – Interposição de recurso relativo aos associados com direito a voto;</p>
        <p>07/06/2018 – Divulgação final dos recursos e da relação final dos associados com direito a voto;</p>
        <p></p>
        <p>19 a 21/06/2018 – Período de votação;</p>
        <p>22/06/2018 – Divulgação dos resultados apurados;</p>
        <p>25/06/2018 – Recebimento de recursos relativos à votação/apuração;</p>
        <p>26/06/2018 – Divulgação dos resultados dos recursos e resultado final da apuração da votação;</p>
        <p>Das Inscrições</p>
        <p>Art. 10o - As inscrições das chapas concorrentes à Diretoria deverão ser realizadas na sede</p>
        <p>administrativa da ASHCLIN, mediante entrega de nominata dos membros e a indicação do no do cartão</p>
        <p>ponto e dos respectivos cargos: Presidência, Vice - Presidência, Secretário Geral, Secretário</p>
        <p>Administrativo, 1 °e 2° Tesoureiro, Diretor de Imprensa e Divulgação, Diretor de Patrimônio, Diretor de</p>
        <p>Convênio, Diretor Cultural e Social, Diretor de Esportes e Diretor Jurídico, 5 (cinco) Suplentes.</p>
        <p>§ Único - As inscrições serão feitas na ASHCLlN das 07:00 às 17:00 horas.</p>
        <p>Art. 11o - Perderão a condição de concorrer às eleições todos aqueles que não se inscreverem junto à</p>
        <p>Comissão Eleitoral no prazo previsto no artigo 9°.</p>
        <p>Da Forma de Votação</p>
        <p>Art. 12o – Fica estipulado que o processo de votação será eletrônico através do sistema disponibilizado</p>
        <p>pelo Hospital de Clínicas, nos termos das normas em vigor relacionadas ao assunto, para fins de</p>
        <p>informatização do procedimento de votação e apuração ora disposto.</p>
        <p>Art. 13o A eleição será realizada em 3 (três) dias úteis, conforme edital de convocação.</p>
        <p>Art. 14o A Comissão Eleitoral fará o sorteio dos candidatos concorrentes para organizá-las na cédula</p>
        <p>eleitoral, em ordem numérica crescente.</p>
        <p>Art. 15o. Será utilizado Sistema de Votação Eletrônica, disponível em todos os computadores da rede</p>
        <p>interna do HCPA, para votar em presidente e membro do conselho fiscal da associação.</p>
        <p>§ 1o - Para votar, o funcionário deverá utilizar usuário/senha da rede interna do HCPA que permitirá o</p>
        <p>acesso ao link do Sistema de Votação Eletrônica na página da intranet.</p>
        <p>§ 2o - Será considerado voto válido apenas aquele dado a um candidato para presidente e a um membro</p>
        <p>do conselho fiscal da associação.</p>
        <p>§ 3o - Votos em branco ou nulo não serão computados como válidos.</p>
        <p>Da Campanha Eleitoral</p>
        <p>Art. 16o - A campanha eleitoral e todas as atividades de propaganda ostensiva encerrar-se-ão às 24</p>
        <p>horas do dia previsto como último dia de campanha no cronograma eleitoral previsto no artigo 9o.</p>
        <p>Art. 17o - A Comissão Eleitoral poderá patrocinar, no mínimo, um debate oficial e formal, convidando-se</p>
        <p>para ele todas as chapas inscritas.</p>
        <p><b>CAPÍTULO II</b></p>
        <p>Da Votação</p>
        <p>Art. 18o - A partir da zero hora do primeiro dia da eleição, supridas as eventuais deficiências, o sistema</p>
        <p>estará liberado para receber os acessos dos eleitores, dando inicio às eleições, encerrando-se às vinte</p>
        <p>horas do último dia, conforme definido no art.9o.</p>
        <p>Art. 19o - Na votação observar-se-á se o seguinte:</p>
        <p>I – o eleitor deverá acessar o sistema do HCPA, intranet, no ícone específico de eleições da Aschlin.</p>
        <p>II - o eleitor deverá fazer o login, com usuário e senha;</p>
        <p>III - ato contínuo surgirá a tela com a cédula oficial constando os candidatos concorrentes a presidente e</p>
        <p>os candidatos ao Conselho Fiscal;</p>
        <p>IV – o eleitor poderá assinalar um candidato a presidente e um representante ao conselho fiscal;</p>
        <p>Art. 20o O voto é facultativo e a eleição será válida com qualquer número de votantes.</p>
        <p>Da Fiscalização</p>
        <p>Art. 21o - Cada chapa poderá indicar um fiscal para atuar junto a Comissão eleitoral durante as eleições;</p>
        <p></p>
        <p>Do Encerramento da Votação</p>
        <p>Art. 22° - Às vinte horas dos dias determinados no artigo 9o, o sistema das eleições será bloqueado pela</p>
        <p>CGTIC.</p>
        <p>Art. 23° - Terminada a votação e declarado o seu encerramento pelo Coordenador da comissão eleitoral</p>
        <p>este tomará as seguintes medidas:</p>
        <p>I – autorizará a CGTIC para providenciar no bloqueio do sistema de votação;</p>
        <p>II - solicitará a CGTIC a emissão do relatório com o escrutínio da votação;</p>
        <p>III - mandará lavrar pelo secretário a ata da eleição;</p>
        <p>IV - assinará a ata com os demais membros da comissão eleitoral e os fiscais presentes se estes assim o</p>
        <p>desejarem;</p>
        <p><b>CAPÍTULO III</b></p>
        <p>Da Apuração</p>
        <p>Art. 24° - Será vencedor o candidato a presidente que obtiver a maioria simples dos votos.</p>
        <p>§ Único - No caso de empate haverá novo sufrágio (2° turno).</p>
        <p>Art. 25o Os dois candidatos mais votados para o Conselho Fiscal serão eleitos como membros titulares e</p>
        <p>os terceiro e quartos mais votados serão eleitos como representantes suplentes.</p>
        <p>§ único – Em caso de empate, será eleito o candidato que está a mais tempo associado.</p>
        <p>Art. 26o – Será considerado nulo:</p>
        <p>I - o voto que contiver mais de um candidato assinalado.</p>
        <p>II – o voto que contiver uma numeração diferente dos candidatos.</p>
        <p>Art. 27o - Terminada a apuração, a comissão eleitoral, providenciará a publicação do resultado no dia</p>
        <p>seguinte.</p>
        <p>CAPÍTULO IV</p>
        <p>Dos Recursos</p>
        <p>Art. 28o - Recursos relativos ao processo eleitoral poderão ser interpostos à Comissão Eleitoral no dia</p>
        <p>designado no cronograma do artigo 9o, até 17horas.</p>
        <p>§ Único - A Comissão Eleitoral dará solução de plano e será a instância final de recurso.</p>
        <p><b>CAPÍTULO V</b></p>
        <p>Do Encerramento Do Processo Eleitoral</p>
        <p>Art. 29o - A Comissão Eleitoral dará por encerrada as suas atividades com a publicação do relatório final</p>
        <p>da eleição.</p>
    </div>
  )
}