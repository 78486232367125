import React, { Component } from 'react'
import { bool, func, object } from 'prop-types';
import { CovenantForm } from './CovenantForm';

import { connect } from 'react-redux';
import { editCovenantThunk, fetchCovenantsThunk } from '../../store/actions/covenantActions'

import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';

class EditCovenant extends Component {
  static propTypes = {
    modal: bool.isRequired,
    selectedCovenant: object.isRequired,
    toggle: func.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      contact: props.selectedCovenant.contact,
      description: props.selectedCovenant.description,
      link: props.selectedCovenant.link,
      name: props.selectedCovenant.name,
      owner: props.selectedCovenant.owner,
      id: props.id,
      covenantEdited: false,
      error: false,
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.edit = this.edit.bind(this)
  }

  updateResponse(){
    const { covenants } = this.props
    const { covenantEdited, error } = this.state
    if(covenants.updatedCovenant && covenantEdited) {
      alert(`${covenants.updatedCovenant.name} editado com sucesso!`)
      this.setState({ covenantEdited: false })
    } else if(covenants.error && error) {
      alert(covenants.error)
      this.setState({ error: false })
    }
  }

  edit(){
    const { contact, description, link, name, owner, id } = this.state
    const { editRef } = this.props
    if( name ) {
      const updatedCovenant = {
        contact,
        description,
        link, 
        name,
        owner,
        id,
        editRef
      }
      this.props.fetchCovenants()
      return this.props.editCovenant(updatedCovenant).then(function(){
        return this.setState({
          covenantEdited: true
        }, () => this.updateResponse())
      }.bind(this))
      .catch(function(){
        return this.setState({
          error: true
        }, () => this.pushResponse())
      }.bind(this))
    }
  }

  handleInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({
      [name]: value
    })
  }

  render() {
    const { modal, toggle, selectedCovenant } = this.props
    console.log('selected', this.state)
    return(
      <MDBModal isOpen={modal} toggle={toggle}>
        <MDBModalHeader toggle={toggle}>Editar convênio</MDBModalHeader>
        <MDBModalBody>
          {
            selectedCovenant &&
            <CovenantForm 
              handleInputChange={this.handleInputChange}
              contactValue={this.state.contact}
              descriptionValue={this.state.description}
              linkValue={this.state.link}
              nameValue={this.state.name}
              ownerValue={this.state.owner}
            />
          }
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={toggle}>Voltar</MDBBtn>
          <MDBBtn color="primary" onClick={this.edit}>Editar</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    )
  }
}

const mapStateToProps = state => ({
  covenants: state.covenants
})

const mapDispatchToProps = dispatch => {
  return {
    editCovenant: (covenant) => dispatch(editCovenantThunk(covenant)),
    fetchCovenants: () => dispatch(fetchCovenantsThunk())
  }
 }

export default connect(mapStateToProps, mapDispatchToProps)(EditCovenant)