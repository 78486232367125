import React, { Component } from 'react'
import { Route } from 'react-router'
import { connect } from 'react-redux'
import Slideshow from '../Carousel'
import { fetchGalleriesThunk } from '../../store/actions/galleryActions'
import { Card, CardBody, CardImage, CardTitle, CardText, Col, MDBBtn, MDBIcon } from 'mdbreact';

class GalleryCategories extends Component {
  componentDidMount () {
    this.props.fetchGalleriesThunk()
  }

  render() {
    const { galleries } = this.props
    const images = [...galleries]
    images.sort((a, b) => new Date(b.date) - new Date(a.date))
    
    return (
      <div className="container">
      <Route exact path="/galerias" render={() => (
        <div>
        <h1 className="h1-responsive font-bold mt-sm-5 wow fadeInLeft">Galerias <small>de fotos</small></h1>
        <hr className=" rgba-cyan-strong wow fadeInLeft" data-wow-delay="0.3s" ></hr>
        <Col className="d-flex justify-content-start flex-wrap">
        {
          galleries === 'loading' ? (
            <div>Carregando</div>
          ) : (
            galleries && images.map((gallery, index) => {
              return (
                <Card key={index} style={{ width: "20rem", margin: "20px", height: "max-content" }}>
                  <CardImage
                    className="img-fluid ml-4"
                    src={gallery.icon}
                    style={{ maxWidth: '96px', width: '100%', marginTop: '5px' }}
                    waves
                  />
                  <CardBody>
                    <CardTitle>{gallery.title}</CardTitle>
                    <CardText>
                      {gallery.description}
                    </CardText>
                    <MDBBtn 
                        href={gallery.link}
                        target="_blank"
                        color="default"
                      >
                        Ver galeria 
                        <MDBIcon icon="arrow-right" className="ml-3" />
                      </MDBBtn>
                  </CardBody>
                </Card>
              )
            })
          ) 
        }
        </Col>
        </div>
      )}
      /> 
      </div>
    )
  }
  }

const mapStateToProps = state => ({
  galleries: state.galleries
})

const mapDispatchToProps = dispatch => {
  dispatch(fetchGalleriesThunk())
  return {
    fetchGalleriesThunk: () => dispatch(fetchGalleriesThunk())
  }
 }

export default connect(mapStateToProps, mapDispatchToProps)(GalleryCategories)