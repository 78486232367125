import firebase from 'firebase'
import database from '../../firebase/index'
import { 
  FETCH_GALLERIES,
  ADD_GALLERY,
  SET_EDIT_GALLERY,
  SET_REMOVE_GALLERY,
  DELETED_GALLERY,
  EDITED_GALLERY
} from './types'

export const fetchGalleries = (galleries) => ({
  type: FETCH_GALLERIES, 
  galleries
})

const addGallery = (gallery, error) => ({
  type: ADD_GALLERY,
  gallery,
  error
})

export const setEditId = (gallery, key, editModal) => ({
  type: SET_EDIT_GALLERY,
  gallery,
  key,
  editModal
})

export const setRemoveId = (gallery, key, deleteModal) => ({
  type: SET_REMOVE_GALLERY,
  gallery,
  key,
  deleteModal
})

const deleteGallery = (gallery, deleteId, error) => ({
  type: DELETED_GALLERY,
  gallery,
  deleteId,
  error
})

const editGallery = (gallery, editId, error) => ({
  type: DELETED_GALLERY,
  gallery,
  editId,
  error
})

export function addGalleryThunk(action) {
  return dispatch => {
    const newGallery = {
      title: action.title || '',
      link: action.link || '',
      icon: action.icon || '',
      date: new Date().toISOString(),
      description: action.description || '',
      modifiedBy: JSON.parse(localStorage.getItem('auth')).user.displayName
    }
    return database.ref(`${action.pushRef}`).push(newGallery).then(ref => {
      dispatch(addGallery(action))
    })
    .catch((err) => dispatch(
      addGallery({
        error: err | 'Erro ao adicionar uma galeria, tente novamente mais tarde.'
      })
    ))
  }
}

export function editGalleryThunk(action) {
  return dispatch => {
    const newGallery = {
      title: action.title || '',
      link: action.link || '',
      icon: action.icon || '',
      date: action.date,
      description: action.description || '',
      modifiedBy: JSON.parse(localStorage.getItem('auth')).user.displayName
    }
    
    return database.ref(`galleries/${action.editId}`).update(newGallery).then(ref => {
      dispatch(editGallery(newGallery, action.editId, ''))
    })
    .catch((err) => dispatch(
      editGallery(
        newGallery,
        action.editId,
        err | 'Erro ao adicionar uma galeria, tente novamente mais tarde.'
      )
    ))
  }
}

export function deleteGalleryThunk(action) {
  return (dispatch) => {
    return database.ref(`galleries/${action.deleteId}`).remove().then((res) => {
      dispatch(deleteGallery(action.gallery, action.deleteId))
    })
    .catch((err) => {
      dispatch(deleteGallery(
        action.gallery,
        action.deleteId,
        err || `Erro ao deletar galeria. Tente novamente mais tarde.`
      ))
    })
  }
}

export function fetchGalleriesThunk() {
  return dispatch => {
    const galleries = [];
    database.ref('galleries').once('value', snap => {
      snap.forEach(data => {
        let gallery = data.val();
        gallery = Object.assign({ key: data.key }, gallery)
        galleries.push(gallery)
        })
    })
    .then(() => dispatch(
      fetchGalleries(galleries)
    ))
  }
}