import { 
  FETCH_CLASSIFIED,
  ADD_AD, SET_AD_EDIT_ID,
  SET_AD_DELETE_ID,
  EDITED_AD,
  DELETE_SUCCESS,
  DELETE_FAIL,
  DELETE_PENDING
} from "../actions/types";

export function classifiedAds (state = 'loading', action){
  switch(action.type) {
    case FETCH_CLASSIFIED:
      return action.classifiedAds
    default:
      return state;
  }
}

export function addClassifiedAds (state = {}, action){
  switch(action.type) {
    case ADD_AD:
      return {
        ...state,
        newAd: action.ad || '',
        pushError: action.error || ''
      }
    case SET_AD_EDIT_ID:
      return {
        ...state,
        edit: action.object,
        editId: action.key,
        editModal: action.editModal,
        slideEdited: true
      }
    case SET_AD_DELETE_ID:
      return {
        ...state,
        delete: action.object,
        deleteId: action.key,
        deleteModal: action.deleteModal,
        slideDeleted: true
      }
    case EDITED_AD:
      return {
        ...state,
        adEdited: action.ad,
        idEdited: action.id,
        error: action.error
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deletedType: action.deletedType,
        deletedImages: action.deletedImages,
        deleteModal: false,
        error: ''
      }
    case DELETE_FAIL:
      return {
        ...state,
        deletedType: '',
        deletedImages: [],
        deleteModal: false,
        error: action.error
      }
    case DELETE_PENDING:
      return {
        ...state,
        deletedType: action.deletedType,
        deletedImages: action.deletedImages,
      }
    default:
      return state;
  }
}