import React, { Component } from 'react'
import { bool, func, string, object } from 'prop-types';

import { connect } from 'react-redux';
import { deleteAdThunk, fetchClassifiedThunk, deleteAdObjectThunk } from '../../store/actions/classifiedAdsActions'

import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';

class DeleteAd extends Component {
  static propTypes = {
    modal: bool.isRequired,
    toggle: func.isRequired,
    deleteInfo: object.isRequired,
    deleteRef: string.isRequired
  }

  constructor(props) {
    super(props);
    
    this.state = {
      id: props.deleteId,
      modal: props.modal,
      error: false,
    }

    this.delete = this.delete.bind(this)
  }

  async deleteResponse(){
    const { deletedType, error } = this.props
    if(deletedType) {
      alert(
        `O anúncio de ${deletedType} foi removido com sucesso!`)
    } else if(error) {
      alert(error)
    }
  }

  delete(){
    const { deleteRef, deleteId, deleteInfo } = this.props

    const deleteContent= {
      deleteRef,
      deleteId,
      deleteInfo
    }

    this.props.fetchClassifiedThunk()
    this.props.deleteAdThunk(deleteContent).then(function(){
      return this.props.deleteAdObjectThunk(deleteContent, this.props.deletedImages)
      .then(function(){
        return this.deleteResponse()
      }.bind(this))
      .catch(function(){
        return this.deleteResponse()
      }.bind(this))
    }.bind(this))
    .catch(function(){
      return this.props.deleteAdObjectThunk(deleteContent, this.props.deletedImages)
      .then(function(){
        return this.deleteResponse()
      }.bind(this))
      .catch(function(){
        return this.deleteResponse()
      }.bind(this))
    }.bind(this))
  }

  render() {
    const { toggle, deletedType, deletedImages, error } = this.props
    deletedImages && console.log(deletedImages)
    return(
      <MDBModal isOpen={this.props.deleteModal} toggle={toggle}>
        <MDBModalHeader toggle={toggle}>Excluir anúncio</MDBModalHeader>
        <MDBModalBody>
          <p>Tem certeza que deseja excluir este anúncio?</p>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={toggle}>Voltar</MDBBtn>
          <MDBBtn color="danger" onClick={this.delete}>Excluir</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    )
  }
}

const mapStateToProps = state => ({
  deletedType: state.addClassifiedAds.deletedType,
  deletedImages: state.addClassifiedAds.deletedImages,
  deleteModal: state.addClassifiedAds.deleteModal,
  error: state.addClassifiedAds.error
})

const mapDispatchToProps = dispatch => {
  return {
    deleteAdThunk: (ad) => dispatch(deleteAdThunk(ad)),
    fetchClassifiedThunk: () => dispatch(fetchClassifiedThunk()),
    deleteAdObjectThunk: (deleteContent, deletedImages) => dispatch(deleteAdObjectThunk(deleteContent, deletedImages))
  }
 }

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAd)