import React, { Component } from 'react'
import firebase from 'firebase'
import { SlideImageCard } from '../Slideshow/SlideImageCard'
import EditSlideImage from '../Slideshow/EditSlideImage'
import DeleteImage from '../Upload/DeleteImage'

import { connect } from 'react-redux'
import { setEditId, setDeleteId, handleEditModal, handleDeleteModal } from '../../store/actions/slideshowActions'

class Images extends Component{
  state = {
    images: []
  }
  componentWillMount(){
    let images = []
    firebase.database().ref(this.props.pushRef).on('value', (snapshot) => {
      let items = snapshot.val();
      items && Object.keys(items).map(key => {
          images = images.concat({
          key: key,
          id: items[key].id,
          image: items[key].image,
          url: items[key].url
        })
      })
      this.setState({
        images: images
      })
    })
  }

  render(){
    return(
      <div className="d-flex flex-wrap" style={{ minHeight:"300px" }}>
        {
          this.props.editModal &&
            <EditSlideImage
              toggle={() => {this.props.dispatch(handleEditModal(false))}}
              modal={this.props.editModal}
              editInfo={this.props.edit}
              editId={this.props.editId}
              editRef={this.props.pushRef}
            />
        }
        {
          this.props.deleteModal &&
            <DeleteImage
              toggle={() => {this.props.dispatch(handleDeleteModal(false))}}
              modal={this.props.deleteModal}
              firstRef={this.props.pushRef}
              secondRef={this.props.deleteId}
              deleteInfo={this.props.delete}
              deleteId={this.props.deleteId}
            />
        }
        {
          this.state.images &&
            this.state.images.map((image) => {
              return(
                <SlideImageCard 
                  position={image.id}
                  id={image.key}
                  key={image.key}
                  url={image.url}
                  image={image.image}
                  onDelete={() => {this.props.dispatch(setDeleteId(image, image.key, true))}}
                  onEdit={() => {this.props.dispatch(setEditId(image, image.key, true))}}
                />
              )
            })
        }
        {
          this.state.images.length === 0 && <span>Este anúncio ainda não contém fotos</span>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  editModal: state.slideshow.editModal,
  deleteModal: state.slideshow.deleteModal,
  delete: state.slideshow.delete,
  deleteId: state.slideshow.deleteId,
  editId: state.slideshow.editId,
  edit: state.slideshow.edit
})

export default connect(mapStateToProps)(Images)