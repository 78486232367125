import React, { Component } from 'react';
import { string, func, bool } from 'prop-types'

import { connect } from 'react-redux';
import firebase from "firebase";

import FileUploader from 'react-firebase-file-uploader';
import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBInput } from 'mdbreact';
import Images from './Images'

import tinyPage from '../../assets/prints/tinyPage.jpg'
import dragImage from '../../assets/prints/dragImage.jpg'
import tinifiedImage from '../../assets/prints/tinifiedImage.jpg'

class UploadImage extends Component {
  static propTypes = {
    modal: bool.isRequired,
    toggle: func.isRequired,
    pushRef: string.isRequired,
    add: string.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      image: "",
      isUploading: false,
      progress: 0,
      imageURL: "",
      position: '',
      newSlide: false,
      error: false,
      getImages: props.getImages
    }

    this.handleUploadStart = this.handleUploadStart.bind(this)
    this.handleProgress = this.handleProgress.bind(this)
    this.handleUploadError = this.handleUploadError.bind(this)
    this.handleUploadSuccess = this.handleUploadSuccess.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.changeView = this.changeView.bind(this)
  }

  handleUploadStart(){
    this.setState({
      isUploading: true,
      progress: 0
    })
  }

  handleProgress(progress){
    this.setState({
      progress
    })
  }

  handleUploadError(error){
    this.setState({
      isUploading: false
    })
  }

  async handleUploadSuccess(filename){
    this.setState({ image: filename, progress: 100, isUploading: false });
    await firebase.storage().ref(`${this.props.pushRef}`).child(`${filename}`).getDownloadURL().then(url => 
      this.setState({ imageURL: url }, () => {
        const image = {
          id: parseInt(this.state.position),
          image: this.state.image,
          url: url,
          modifiedBy: JSON.parse(localStorage.getItem('auth')).user.displayName
        }
        firebase.database().ref(this.props.pushRef).push(image).then((response) => {
          alert(`Imagem ${this.state.image} adicionada na posição ${this.state.position}.`)
          window.location.reload()
        })
      })
    );
  }

  handleInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({
      [name]: value
    })
  }

  changeView() {
    this.setState({
      getImages: !this.state.getImages
    })
  }

render() {
  const { toggle, modal } = this.props

  return (
      <MDBModal isOpen={modal} toggle={toggle} size={this.state.getImages && 'lg' }>
        <MDBModalHeader toggle={toggle}>{this.state.getImages ? 'Ver' : 'Adicionar'} {this.props.add}</MDBModalHeader>
        {
          this.state.getImages &&
            <div className="container">
              <MDBBtn color="info" onClick={this.changeView}>Adicionar foto</MDBBtn>
            </div>
        }
        {
          !this.state.getImages ? (
            <MDBModalBody>
              <h5><b>Importante!</b> Fazer o passo a passo a seguir antes de adicionar a imagem para o site.</h5>
              <div className="d-flex flex-column align-items-start">
                <p>1. Navegue para o site <a href="https://tinypng.com/" target="_blank">https://tinypng.com/</a>.</p>
                <img src={tinyPage} width="100%" />
                <p className="pt-2">2. Arraste a imagem que você pretende fazer adicionar ao site, para o quadrado que diz "Drop your .png or .jpg files here!".</p>
                <img src={dragImage} width="100%" />
                <p className="pt-2">3. Espere a barra de progresso ficar em 100% e clique em <b style={{ textDecoration:"underline" }}>download</b></p>
                <img src={tinifiedImage} width="100%" />
                <p className="pt-2">4. Após isso você pode adicionar a imagem que foi baixada(você deve preencher o campo <b>Posição da imagem</b> antes).</p>
              </div>
              {this.state.isUploading && <p>Progress: {this.state.progress}%</p>}
              {this.state.imageURL && <img width="95%" style={{ marginBottom: "10px" }} src={this.state.imageURL} />}
              <div className="pt-3 pb-3">
              <MDBInput
                label="Posição da imagem"
                name="position"
                onChange={this.handleInputChange}
                group
                type="number"
                success="right"
                value={this.state.position}
              />
              {
                this.state.position && 
                  <FileUploader
                    accept="image/*"
                    name="image"
                    randomizeFilename
                    storageRef={firebase.storage().ref(this.props.pushRef)}
                    onUploadStart={this.handleUploadStart}
                    onUploadError={this.handleUploadError}
                    onUploadSuccess={this.handleUploadSuccess}
                    onProgress={this.handleProgress}
                  />
              }
              </div>
            </MDBModalBody>
          ) : (
            <MDBModalBody>
              {
                <Images 
                  pushRef={this.props.pushRef}
                />
              }
            </MDBModalBody>
          )
        }
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={toggle}>Voltar</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    );
  }
}

//const mapStateToProps = state => ({
//  covenants: state.covenants
//})

const mapDispatchToProps = dispatch => {
  return {
    //addCovenant: (covenant) => dispatch(addCovenantThunk(covenant)),
    //fetchCovenants: () => dispatch(fetchCovenantsThunk())
  }
 }

export default connect(mapDispatchToProps)(UploadImage);