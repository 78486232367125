import React from 'react'
import { Button, MDBIcon } from 'mdbreact'
import estatuto from '../../assets/download/ESTATUTO ASHCLIN.pdf'

export default function About(){
  return(
    <div className="container mt-5 mb-5">
      <div className="d-flex w-100 justify-content-end">
        <Button href={estatuto} target="_blank">Estatuto<MDBIcon icon="book-reader" /></Button>
      </div>
      <h3 class="mt-3"><b>Membros da Diretoria ASHCLIN 2021-2023</b></h3>
      <hr class="wow fadeInLeft" data-wow-delay="0.3s" />
      <p>Presidente - LANI BRITO FAGUNDES</p>
      <p>Vice-Presidente – INDARA CARMANIM SACCILOTTO</p>
      <p>Diretor Administrativo PAULO RENATO DOS SANTOS NUNES</p>
      <p>Diretor de Finanças - DOUGLAS GARCIA PINHO</p>
      <p>Diretor do Parque Social e Patrimônio - EMERSON UBIRATÃ PEREIRA SOARES</p>
      <p>Diretora Cultural e Social - ALEXANDRA PIRES COELHO</p>
      <p>Diretor de Esportes - ANDERSON COELHO SILVA</p>
      <p id="h.gjdgxs">Diretora de Marketing e Comunicação - JANAINA FIGUEIREDO DA SILVA</p>
      <p class="c0 c3"></p>
      <h3 class="mt-4">Suplentes</h3>
      <hr class="wow fadeInLeft" data-wow-delay="0.3s" />
      <p>Diretor Suplente- ALEXANDRE DE MOURA NEVES</p>
      <p>Diretora Suplente - VANISE NOGUEIRA DE CASTRO</p>
      <p>Diretora Suplente - LÚCIA ZELINDA ZANELLA</p>
      <h3 class="mt-5"><b>Missão</b></h3>
      <hr class="wow fadeInLeft" data-wow-delay="0.3s" />
      <p>Humanizar o convívio e o lazer dos associados em busca da qualidade de vida no trabalho, na família e na sociedade;</p>
      <p>Desenvolver atividades recreativas e esportivas, culturais e de lazer para os associados e dependentes;</p>
      <p>Oferecer assessoria jurídica visando defender os interesses e reivindicações dos seus associados e dependentes;</p>
      <p>Prover espaços de cultura , esporte e lazer para os associados e seus dependentes;</p>
      <p>Estabelecer convênios que visam oferecer vantagens com menor custos aos associados e dependentes.</p>
      <h3><b>Visão</b></h3>
      <hr class="wow fadeInLeft" data-wow-delay="0.3s" />
      <p>Buscar ser uma referência para seu associado em esporte, cultura, lazer e convívio social.</p>
      <h3><b>Valores</b></h3>
      <hr class="wow fadeInLeft" data-wow-delay="0.3s" />
      <p>Buscar excelência com responsabilidade, competência, seriedade e transparência em todos os seus seguimentos.</p>
      <p class="mb-5">Respeito e confiança com os seus associados e dependentes.</p>
    </div>
  )
}