import React, { Component } from 'react'
import { bool, func, object } from 'prop-types';

import { connect } from 'react-redux';
import { handleEditModal, editSlideThunk, fetchSlideImagesThunk } from '../../store/actions/slideshowActions';

import { MDBBtn, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';

class EditSlideImage extends Component {
  static propTypes = {
    modal: bool.isRequired,
    editInfo: object.isRequired,
    toggle: func.isRequired
  }

  constructor(props) {
    super(props);
    
    this.state = {
      id: props.editId,
      modal: props.modal,
      position: props.editInfo.id,
      imageName: props.editInfo.image,
      url: props.editInfo.url,
      slideEdited: false,
      error: false,
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.edit = this.edit.bind(this)
  }

  updateResponse(){
    const { slides, slideEdited, errorSlide } = this.props
    
    if(slideEdited) {
      alert(`${this.state.imageName} editado com sucesso!`)
    } else if(errorSlide || !slideEdited) {
      alert(slides.errorSlide)
    }
    this.props.handleEditModal(false)
    window.location.reload()
  }

  edit(){
    const { imageName, id, url, position } = this.state
    const { editRef, editId } = this.props

    if( position ) {
      const updatedCovenant = {
        imageName,
        url,
        position,
        id,
        editRef,
        editId
      }
      return this.props.editSlide(updatedCovenant).then(function(){
        return this.updateResponse()
      }.bind(this))
      .catch(function(){
        return this.updateResponse()
      }.bind(this))
    }
  }

  handleInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({
      [name]: value
    })
  }

  render() {
    const { modal, toggle } = this.props

    return(
      <MDBModal isOpen={this.state.modal} toggle={toggle}>
        <MDBModalHeader toggle={toggle}>Editar imagem</MDBModalHeader>
        <MDBModalBody>
          <MDBInput
            label="Posição"
            name="position"
            onChange={this.handleInputChange}
            group
            type="number"
            success="right"
            value={this.state.position}
          />
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={toggle}>Voltar</MDBBtn>
          <MDBBtn color="primary" onClick={this.edit}>Editar</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    )
  }
}

const mapStateToProps = state => ({
  slides: state.slideshow,
  slideEdited: state.slideshow.slideEdited,
  updatedSlide: state.slideshow.updatedSlide,
  errorSlide: state.slideshow.errorSlide
})

const mapDispatchToProps = dispatch => {
  return {
    editSlide: (slide) => dispatch(editSlideThunk(slide)),
    handleEditModal: (bool) => dispatch(handleEditModal(bool)),
    fetchSlideImagesThunk: () => dispatch(fetchSlideImagesThunk())
  }
 }

export default connect(mapStateToProps, mapDispatchToProps)(EditSlideImage)