import React, { Component } from 'react'

import { connect } from 'react-redux';
import { fetchClassifiedThunk } from '../../store/actions/classifiedAdsActions'

import { MDBRow, MDBCol } from 'mdbreact'

import { Header } from '../Layout/Header'
import { CategoriesCard } from '../Layout/CategoriesCard';

class ClassifiedAdsList extends Component {

  render(){
    const { classifiedAds } = this.props
    console.log('class', classifiedAds)
    return(
      <div className="container">
        <Header text="Classificados" routeBack="/admin" />
        <MDBRow>
          <MDBCol size="6">
            {
              classifiedAds && classifiedAds === 'loading' ? (
                <div>Carregando...</div>
              ) : (
                classifiedAds.map((ad, key) => {
                  return(
                    <CategoriesCard
                      title={ad.type}
                      type={`classificados`}
                      route={ad.route}
                      button={`editar`}
                      key={key}
                    />
                  )
                })
              )
            }
            </MDBCol>
            <MDBCol size="4">

            </MDBCol>
        </MDBRow>
        
      </div>
    )
  }
}

const mapStateToProps = state => ({
  classifiedAds: state.classifiedAds
})

export default connect(mapStateToProps)(ClassifiedAdsList)