import React, { Component } from 'react'
import { string, object } from 'prop-types'
import { Link } from 'react-router-dom'

import { Table, TableBody, TableHead, MDBIcon  } from 'mdbreact';

class Leases extends Component {
  static propTypes = {
    type: string,
    lease: object
  }

  static defaultProps = {
    type: null
  }

  render() {
    const { lease } = this.props
    return(
      <div className="container pt-4">
      <h1 className="h1-responsive font-bold mt-sm-5 wow fadeInLeft">
        <Link to="/locacoes">  
          <MDBIcon icon="arrow-left" size="lg" className="pr-3 cyan-text" />
        </Link>
         {lease.type}{lease.description && `, `} 
         <small className="ml-2">{lease.description}</small>
      </h1>
        <hr className=" rgba-cyan-strong wow fadeInLeft" data-wow-delay="0.3s" ></hr>
        <Table bordered responsive>
          <TableHead color="yellow darken-2" textWhite>
            <tr>
              <th>Turno</th>
              <th>Horários</th>
              <th>À vista</th>
              <th>Cartão de crédito</th>
            </tr>
          </TableHead>
        <TableBody>
        {
          lease.shifts ? 
          Object.keys(lease.shifts).map((key) => (
            <tr key={key}>
              <td>{lease.shifts[key].shift}</td>
              <td>{lease.shifts[key].schedule}</td>
              <td>{lease.shifts[key].cash}</td>
              <td>{lease.shifts[key].creditCard}</td>
            </tr>
          ))
          :
          (
            <div>Nenhuma locação foi encontrada nessa categoria</div>
          )
        }
        </TableBody>
      </Table>
      {
        lease.towels &&
        <Table responsive>
          <TableHead color="">
            <tr>
              <th>
                Toalhas
              </th>
              <th>
                Valor
              </th>
            </tr>
          </TableHead>
          <TableBody>
            {
              Object.keys(lease.towels).map((key) => (
                <tr>
                  <td>{lease.towels[key].type}</td>
                  <td>{lease.towels[key].price}</td>
                </tr>
              ))
            }
          </TableBody>
        </Table>
      }
      <Table>
        <TableHead>
          <tr>
          <th>Estacionamento</th>
          <th>Valor</th>
          </tr>
        </TableHead>
        <TableBody>
          <tr>
          <td>Para convidados</td>
          <td>R$ 5</td>
          </tr>
          <tr>
          <td>*OBS: Solicitar com antecedência do seu evento. Horário para solicitação 7h às 16h. Pagamento somente na secretaria da ASHCLIN.</td>
          </tr>
        </TableBody>
      </Table>
      <div className="d-flex justify-content-start flex-wrap pb-4">
        {
          lease.pictures &&
          Object.keys(lease.pictures).map((key) => (
              <img height="250" width="350" src={require(`../../assets/leases/${lease.pictures[key].image}.jpg`)} style={{ margin: "10px" }} alt={`${key}`} />
          ))
        }
      </div>
      </div>
    )
  }
}

export default Leases