import React, { Component } from "react"
import styled from 'styled-components'
import { Swiper, Slide } from 'react-dynamic-swiper'
import 'react-dynamic-swiper/lib/styles.css'
import firebase from 'firebase'
import { bool, string } from 'prop-types'
import { storage } from '../firebase';
import { MDBIcon } from 'mdbreact'

const SlideshowWrapper = styled.div`
  position: relative;
  min-height: ${props => props.height};
  height: ${props => props.height};

  @media (max-width:520px) {
    min-height: ${props => props.heightMobile}
    height: ${props => props.heightMobile}
  }

  .swiper-container {
    height: ${props => props.height};
    min-height: ${props => props.height}

    @media (max-width: 520px) {
      min-height: ${props => props.heightMobile}
      height: ${props => props.heightMobile};
    }
  }

  .swiper-slide {
    min-height: ${props => props.height};
    height: ${props => props.height};
    ${props => props.ad && `max-width: 100%;`}
    ${props => props.ad && `max-height: 40%;`}
    ${props => props.ad && `height: fit-content!important;`}
    ${props => props.ad && `margin: auto;`}

    @media (max-width: 520px) {
      max-height: 320px;
      min-height: ${props => props.heightMobile}
      height: ${props => props.heightMobile};
    }

    img {
      min-height: ${props => props.height};
      height: inherit;
      ${props => props.ad && `max-width: 100%;`}
      ${props => props.ad && `max-height: 40%;`}
      margin: auto;
      width: auto;
      
      @media (max-width: 520px) {
        ${props => props.heightMobile && `min-height: ${props.heightMobile};`}
        ${props => props.heightMobile ? `height: ${props.heightMobile};` : `height: auto;`}
        max-width: 100%;
      }
    }
  }

  .loader--slideshow {
    position: absolute;
    right: 45%;
    top: 45%;
  }
`

class Slideshow extends Component {
  state = {
    imageList: [],
    indicators: 0,
    loading: true
  }

  static propTypes = {
    category: string.isRequired,
    imageHeight: string.isRequired,
    imageHeightMobile: string,
    storageRef: string.isRequired,
    mainPage: bool,
  }

  static defaultProps = {
    mainPage: false
  }

  async componentWillMount() {
    const slideshowRef = firebase.database().ref(this.props.category);
    const sliders = [];
    slideshowRef.on('value', (snapshot) => {
      let items = snapshot.val();
      let object = this.props.mainPage ? items.slides : items
      items && Object.keys(object).map((key) => {
          sliders.push({
            id: object[key].id,
            image: object[key].url
          })
          this.setState({
            imageList: sliders,
            indicators: sliders.length,
            loading: false
          })
        })
    });
  }

  render() {
    const { imageList, loading } = this.state
    const { imageHeight, imageHeightMobile, mainPage, ad } = this.props
    const images = [...imageList]
    images.sort((a, b) => a.id - b.id)
    const sortedImages = images.map((imageUrl, index) => {
      return (
        <Slide key={index}>
          <img
            className={`d-block`} 
            src={ imageUrl.image } 
            alt={ index }
          />
        </Slide>
      )
    })
    
    return (
      <SlideshowWrapper 
        height={imageHeight}
        heightMobile={imageHeightMobile}
        mainPage={mainPage}
        ad={ad}
      >
        {
          loading ?
          (
            <div className="loader--slideshow">
              <MDBIcon icon="spinner" spin size="3x" className="cyan-text" fixed />
            </div>
          ) :
          ( 
            <Swiper
              activeIndex={3}
              swiperOptions={{
                slidesPerView: '1'
              }}
            >
              { sortedImages }
            </Swiper>
          )
        }
      </SlideshowWrapper>
    )

  }
}

export default Slideshow;