import React from 'react'

export default function Retired(){
  return(
    <div class="container mt-5">
    <h3 className="mb-3">Prezados sócios,</h3>
    <p class="text-justify">
    A Diretoria da ASHCLIN 2016/2018 na sua proposta de trabalho idealizou
    um Departamento de Aposentados, o qual foi possível somente com a
    colaboração da Diretoria do HCPA. Portanto, informamos que a partir de
    Abril/2018 os sócios aposentados que não estejam em atividades na
    instituição, terão os seguintes benefícios:<br /><br />
    <b>1.</b>Solicitar seu crachá para acesso às catracas do Prédio Principal
    (qualquer horário) e ao Estacionamento do Parque Social nos dias e
    horários já permitidos para sócios sem cadastro de estacionamento
    no HCPA, segunda à sexta das 17h às 01h e aos sábados, domingos
    e feriados das 08h às 01h. Para fazer um novo crachá;<br /><br />
    <b>2.</b>A mensalidade será no máximo R$15,00 por mês,
    independentemente da sua renda atual;<br /><br />
    <b>3.</b>O Departamento dos Aposentados contará com a disponibilidade de
    01 salão por mês, sempre entre segunda e quarta- feiras no turno
    dia, para reuniões e almoços, sem o pagamento da taxa.
    </p>
    <p class="mb-5"><b>Marilene Hoerlle Nozari</b><br /><b>Presidente da ASHCLIN</b><br /><b>Gestão 2016/2018</b></p>
    </div>
  )
}