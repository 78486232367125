import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Table, TableBody, TableHead, MDBCard, MDBCardBody, MDBCardHeader, MDBBtn, MDBIcon  } from 'mdbreact';

import PDF from '../../assets/download/Agenda-CAMINHOSDALUZ.pdf'

class LessonsList extends Component {
  constructor(props) {
    super(props);

    this.keyCount = 0;
    this.getKey = this.getKey.bind(this);
  }

  getKey(index){
    return this.keyCount + Math.random() + index
  }
  
  render() {
    const { lessons } = this.props

    return(
      <div className="container">
      <div className="d-flex w-100 justify-content-end pt-3">
        <Link
          to="locacoes"
        >
          <MDBBtn
            className="info"
          >
            Locação de espaços <MDBIcon icon="arrow-right" size="sm" className="pl-2 white-text" />
          </MDBBtn>
        </Link>
      </div>
      <h1 className="h1-responsive font-bold wow fadeInLeft">Atividades <small>do Parque Social</small></h1>
        <hr className=" rgba-cyan-strong wow fadeInLeft" data-wow-delay="0.3s" ></hr>
        {
          lessons === 'loading' ? (
            <div>Carregando...</div>
          ) : (
            lessons && lessons.map((company, key) => {
              return(
                <MDBCard color="grey lighten-5" className="mt-3">
                  <MDBCardHeader color="blue darken-3" style={ { fontWeight:"bold" } }>
                    {company.name}
                  </MDBCardHeader>
                  <MDBCardBody>
                  <Table bordered responsive key={key}>
                    <TableBody>
                      {
                        company.name === 'Grupo voluntário: Caminhos da Luz - no Espaço Holístico ILMA' && (
                          <tr>
                            <td style={{ width: '500px' }}>
                              <a className="blue-text" target="_blank" style={{ fontWeight: 'bold' }} href={PDF}>Baixar agenda do Grupo Voluntário: Caminhos da Luz</a>
                            </td>
                          </tr>
                        )
                      }
                      {
                        company.days ? (Object.keys(company.days).map((key, index) => (
                          <tr key={index}>
                            { company.days[key].name && <td key={this.getKey(index)} style={ { fontWeight:"500" } }>{company.days[key].name}</td> }
                            { company.days[key].lesson1 && <td key={this.getKey(index)} style={ { fontWeight:"500" } }>{company.days[key].lesson1}</td> }
                            { company.days[key].lesson2 && <td key={this.getKey(index)} style={ { fontWeight:"500" } }>{company.days[key].lesson2}</td> }
                            { company.days[key].lesson3 && <td key={this.getKey(index)} style={ { fontWeight:"500" } }>{company.days[key].lesson3}</td> }
                          </tr>
                        ))
                        ) : (
                          company.categories ? (
                           <div></div>
                          ) : (
                            <tr>
                              <td>Nenhuma aula disponível</td>
                            </tr>
                          )
                        )
                      }
                      {
                        company.name === 'Grupo voluntário: Caminhos da Luz - no Espaço Holístico ILMA' && (
                          <tr>
                            <td>
                              <p>Práticas Integrativas de Saúde oferecidas gratuitamente: Reiki, Energização e Regeneração Celular René Mey. Atendimentos conforme a ordem de chegada (vagas limitadas conforme a capacidade de atendimento do espaço e dos voluntários).</p>
                            </td>
                          </tr>
                        )
                      }
                    </TableBody>
                  </Table>
                  {
                    company.categories ? 
                    Object.keys(company.categories).map((key, index) => {
                      const { professors } = company.categories[key]
                      const professionals = professors && professors

                      return (
                        <div>
                          <h5 className="mb-2" style={ { fontWeight:"bold" } }>{company.categories[key].type}</h5>
                          <Table bordered responsive key={`${key}--prof--${index}`}>
                            {
                              professionals &&
                              <TableHead>
                                <tr>
                                  <th style={ { fontWeight:"500" } }>Profissional</th>
                                  <th style={ { fontWeight:"500" } }>Atendimento</th>
                                  <th style={ { fontWeight:"500" } }>Contato</th>
                                </tr>
                              </TableHead>
                            }
                            <TableBody>
                              {
                                professionals && Object.keys(professionals).map((professor, index) => {
                                  return (
                                    <tr>
                                      <td style={ { fontWeight:"500" } }>{professionals[professor].name}</td>
                                      <td style={ { fontWeight:"500" } }>{professionals[professor].lessons}</td>
                                      <td style={ { fontWeight:"500" } }>{professionals[professor].contact}</td>
                                    </tr>
                                  )
                                })
                              }
                            </TableBody>
                          </Table>
                          
                          <Table bordered responsive key={`${key}--prof--${index}`}>
                            <TableHead>
                              <tr>
                                <th style={ { fontWeight:"500" } }>Sócio</th>
                                <th style={ { fontWeight:"500" } }>Não sócio</th>
                              </tr>
                            </TableHead>
                            <TableBody>
                              <td style={ { fontWeight:"500" } }> { company.categories[key].prices.associate } </td>
                              <td style={ { fontWeight:"500" } }> { company.categories[key].prices.notAssociated } </td>
                            </TableBody>
                          </Table>
                          <hr className=" rgba-cyan-strong wow fadeInLeft" data-wow-delay="0.3s" ></hr>
                        </div>
                      )
                    }) : (
                    <div></div>
                    )
                  }
                  {
                    company.prices ? (Object.keys(company.prices).map((key, index) => (
                      <div>
                        <h5 style={ { fontWeight:"bold" } }>{ company.prices[key].service }</h5>
                        <Table bordered responsive key={`prices-${key}`}>
                        <TableHead>
                          <tr>
                            <th style={ { fontWeight:"bold" } }>Valor sócio</th>
                            <th style={ { fontWeight:"bold" } }>Valor não sócio</th>
                          </tr>
                        </TableHead>
                        <TableBody>
                          <tr key={`price-${index}`}>
                            <td style={ { fontWeight:"500" } }> { company.prices[key].associate.price } </td>
                            <td style={ { fontWeight:"500" } }> { company.prices[key].notAssociated.price   } </td>
                          </tr>
                        </TableBody>
                      </Table>
                      </div>
                    ))

                    ) : (
                      <div></div>
                    )
                  }
                  </MDBCardBody>
                </MDBCard>
              )
            })
          )
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  lessons: state.lessons
})

export default connect(mapStateToProps)(LessonsList)