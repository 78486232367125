import React, { Component } from 'react'

import { Header } from '../Layout/Header'
import AddGallery from './AddGallery'
import DeleteGallery from './DeleteGallery'
import EditGallery from './EditGallery'

import { connect } from 'react-redux'
import { fetchGalleriesThunk, setEditId, setRemoveId } from '../../store/actions/galleryActions'

import { MDBBtn, MDBIcon, Card, CardBody, CardImage, CardTitle, CardText } from 'mdbreact'

class Galleries extends Component {
  state = {
    pushModal: false,
    deleteModal: false,
    editModal: false
  }

  componentWillMount() {
    this.props.galleries === 'loading' && this.props.dispatch(fetchGalleriesThunk())
  }

  togglePushModal = () => {
    this.setState({
      pushModal: !this.state.pushModal
    });
  }

  render() {
    const { galleries } = this.props
    
    return(
      <div>
        <Header text={`Galerias | `} routeBack="/admin" />
        <div className={`container d-flex justify-content-center flex-wrap`}>
           {
            this.state.pushModal &&
              <AddGallery
                toggle={this.togglePushModal}
                modal={this.state.pushModal}
                pushRef={'galleries'}
              />
          }
          {
            this.props.deleteModal &&
              <DeleteGallery
                toggle={() => {this.props.dispatch(setRemoveId({}, {}, false))}}
                modal={this.props.deleteModal}
              />
          } 
          {
            this.props.editModal &&
              <EditGallery
                toggle={() => {this.props.dispatch(setEditId({}, {}, false))}}
                modal={this.props.editModal}
              />
          }
          <div className="d-flex flex-wrap justify-content-center align-items-center">
            <MDBBtn outline color="success" onClick={this.togglePushModal}>
              <MDBIcon icon="plus" /> Adicionar galeria
            </MDBBtn>
          </div>
        </div>
        <div className="container pt-3 flex-wrap d-flex">
          {
            galleries === 'loading' ? (
              <div>Carregando...</div>
            ) : galleries && galleries.map((gallery, index) => {
                return(
                  <Card key={index} style={{ width: "20rem", margin: "20px" }}>
                    <CardImage
                      className="img-fluid ml-4"
                      src={gallery.icon}
                      waves
                    />
                    <CardBody>
                      <CardTitle>{gallery.title}</CardTitle>
                      <CardText>
                        {gallery.description}
                      </CardText>
                      <CardText>
                        link: {gallery.link}
                      </CardText>
                      {
                        gallery.modifiedBy &&
                        <CardText>
                          <i>Última modifição: {gallery.modifiedBy}</i>
                        </CardText>
                      }
                      <div className="d-flex">
                        <MDBBtn 
                          color="danger"
                          onClick={() => this.props.dispatch(setRemoveId(gallery, gallery.key, true))}
                        >X
                        </MDBBtn>
                        <MDBBtn 
                          color="info"
                          onClick={() => this.props.dispatch(setEditId(gallery, gallery.key, true))}
                        >
                          Editar
                        </MDBBtn>
                      </div>
                    </CardBody>
                  </Card>
                )
              })
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  galleries: state.galleries,
  deleteModal: state.galleriesCrud.deleteModal,
  editModal: state.galleriesCrud.editModal
})

export default connect(mapStateToProps)(Galleries)