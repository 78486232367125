import React from 'react'
import { func, string } from 'prop-types';

import { MDBInput } from 'mdbreact'

const propTypes = {
  handleInputChange: func.isRequired,
  shift: string.isRequired,
  schedule: string.isRequired,
  cash: string.isRequired,
  creditCard: string.isRequired
}

export function ShiftForm(props){
  
  return(
    <form>
      <MDBInput
        label="Turno"
        name="shift"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.shift}
      />
      <MDBInput
        label="Horários"
        name="schedule"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.schedule}
      />
      <MDBInput
        label="À vista"
        name="cash"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.cash}
      />
      <MDBInput
        label="Cartão de crédito"
        name="creditCard"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.creditCard}
      />
    </form>
  )
}