import { 
  FETCH_COVENANTS,
  ADD_COVENANT,
  EDIT_COVENANT,
  DELETE_COVENANT,
  SET_EDIT_COVENANT_ID,
  SET_DELETE_COVENANT_ID
 } from "../actions/types";

export function covenants (state = [], action){
  switch(action.type) {
    case FETCH_COVENANTS:
      return {
        ...state,
       covenants: action.covenants
      } 
    case ADD_COVENANT:
      return {
        ...state,
        newCovenant: action.covenant,
        error: action.error
      }
    case EDIT_COVENANT:
      return {
        ...state,
        editId: action.editId,
        updatedCovenant: action.covenant,
        error: action.error
      }
    case DELETE_COVENANT:
      return {
        ...state,
        deletedCovenant: action.name,
        error: action.error
      }
    case SET_EDIT_COVENANT_ID:
      return {
        ...state,
        selectedCovenant: action.selectedCovenant,
        id: action.key
      }
    case SET_DELETE_COVENANT_ID:
      return {
        ...state,
        delete: {
          name: action.name,
          id: action.id
        }
      }
    default:
      return state;
  }
}