import React from 'react'

import { MDBBtn, MDBCardText, MDBCard, MDBCardBody, MDBRow, MDBCardTitle } from 'mdbreact';
import { relative } from 'path';

export function CovenantsCard(props) {
  return(
    <MDBCard 
      id={props.id}
      style={{ width: `${props.width}`, position: 'relative', margin: '5px' }}>
      <MDBCardBody>
        <MDBRow className="d-flex flex-direction-column justify-content-between align-items-center">
          <MDBCardTitle className="ml-3">
            {props.name}
          </MDBCardTitle>
          <MDBCardText className="pl-3 w-75" style={{ height: 'auto' }}>
            {props.description}
          </MDBCardText>
          {
            props.contact &&
            <MDBCardText className="pl-3 w-75" style={{ height: 'auto' }}>
              {props.contact}
            </MDBCardText>
          }
          {
            props.owner &&
            <MDBCardText className="pl-3 w-75" style={{ height: 'auto' }}>
              {props.owner}
            </MDBCardText>
          }
          {
            props.link &&
            <MDBCardText className="pl-3 w-75" style={{ height: 'auto' }}>
              {props.link}
            </MDBCardText>
          }
          {
            props.modifiedBy &&
            <MDBCardText className="pl-3 w-75" style={{ height: 'auto' }}>
              <i>Última modifição: {props.modifiedBy}</i>
            </MDBCardText>
          }
          <div className={`d-flex flex-nowrap justify-content-between`}>
            <MDBBtn color="danger" onClick={props.onDelete} style={{ borderRadius: '50px' }}>
              X
            </MDBBtn>
            <MDBBtn color="info" onClick={props.onEdit} style={{ borderRadius: '50px' }}>
              EDITAR
            </MDBBtn>
          </div>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  )
}