import React, { Component } from 'react'
import { connect } from 'react-redux';

import { MDBRow, MDBCol } from 'mdbreact'

import { Header } from '../Layout/Header'
import { CategoriesCard } from '../Layout/CategoriesCard';

class Covenants extends Component {
  
  render(){
    const { covenants } = this.props
    return(
      <div className="container">
        <Header text="Convênios" routeBack="/admin" />
        <MDBRow>
          <MDBCol size="6">
            {
              covenants.length === 0 ? (
                <div>Carregando...</div>
              ) : (
                covenants.covenants && covenants.covenants.map((covenant, key) => {
                  return(
                    <CategoriesCard
                      title={covenant.type}
                      type={`convenios`}
                      route={covenant.route}
                      button={`editar`}
                      key={key}
                    />
                  )
                })
              )
            }
            </MDBCol>
            <MDBCol size="4">

            </MDBCol>
        </MDBRow>
        
      </div>
    )
  }
}

const mapStateToProps = state => ({
  covenants: state.covenants
})

export default connect(mapStateToProps)(Covenants)