import React, { Component } from 'react';
import { BrowserRouter as Router } from "react-router-dom";

import { connect } from 'react-redux'
import { fetchCovenantsThunk } from './store/actions/covenantActions'
import { fetchClassifiedThunk } from './store/actions/classifiedAdsActions'
import { fetchLeasesThunk } from './store/actions/leasesActions'
import { isUserLoggedIn } from './store/actions/loginActions'
import { fetchLessonsThunk } from './store/actions/lessonsActions'

import Routes from './Routes'
import Navigation from './components/Layout/Navigation'
import Footer from './components/Layout/Footer'

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseID: "",
      slideShow: [],
      footer: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        footer: true
      })
    }, 3000)
  }

  render() {
    const { auth, classifiedAds, covenants, leases, lessons } = this.props

    return (
      <Router>
        <div className={ `fluid mt-4 position-relative` }>
          <Navigation />
          {
            covenants.covenants && (
              <Routes
                classifiedAds={classifiedAds}
                covenants={covenants.covenants}
                leases={leases}
                lessons={lessons}
              />
            )
          }
          {
            (this.state.footer && !auth.loggedIn) ? <Footer /> : null
          }
        </div>
      </Router>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  covenants: state.covenants,
  classifiedAds: state.classifiedAds,
  leases: state.leases,
  lessons: state.lessons
})

const mapDispatchToProps = dispatch => {
  dispatch(fetchCovenantsThunk())
  dispatch(fetchClassifiedThunk())
  dispatch(fetchLeasesThunk())
  dispatch(isUserLoggedIn())
  dispatch(fetchLessonsThunk())
  return {
    fetchCovenantsThunk: () => dispatch(fetchCovenantsThunk()),
    fetchClassifiedThunk: () => dispatch(fetchClassifiedThunk()),
    fetchLeasesThunk: () => dispatch(fetchLeasesThunk()),
    fetchLessonsThunk: () => dispatch(fetchLessonsThunk()),
    isUserLoggedIn: () => dispatch(isUserLoggedIn())
  }
 }

export default connect(mapStateToProps, mapDispatchToProps)(App);
