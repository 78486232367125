import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer () {
    return(
      <footer className="page-footer w-100 center-on-small-only default-color-dark pt-4 mt-5">
        <div className="container">
        <div className="row">
        
        <div className="col-md-4">
          <h5 className="title mb-4 mt-3 font-bold">
            <i className="fa fa-commenting white-text" aria-hidden="true"></i> 
            <a href="contact.php"> Fale Conosco!</a>
          </h5>
          <p>Preencha um breve formulário deixando dúvidas, sugestões ou até mesmo possíveis reclamações clicando <a href="contact.php" style={{ textDecoration: "underline"}}><b><Link  to="/contato">aqui</Link></b></a>!
          </p>
        </div>
        
        <hr className="clearfix w-100 d-md-none" />
        
        <div className="col-md-2 mx-auto">
          <h5 className="title mb-4 mt-3 font-bold">
            <i className="fa fa-building white-text" aria-hidden="true"></i> Endereço
          </h5>
          <ul  style={{ listStyle: 'none', paddingLeft: '0' }}>
            <li>Largo Eduardo Zácaro Faraco, 439-593</li>
            <li>Santana, Porto Alegre - RS</li>
            <li>90035-003</li>
          </ul>
        </div>
        
        <hr className="clearfix w-100 d-md-none" />
        
        <div className="col-md-2 mx-auto">
          <h5 className="title mb-4 mt-3 font-bold">
            <i className="fa fa-phone" aria-hidden="true"></i> Telefone
          </h5>
          <ul style={{ listStyle: 'none', paddingLeft: '0' }}>
            <li>Sede administrativa: (51) 3359-8169</li>
            <li>Parque Social: (51) 3359-8150</li>
          </ul>
        </div>
        
        <hr className="clearfix w-100 d-md-none" />
        
        <div className="col-md-2 mx-auto">
          <h5 className="title mb-4 mt-3 font-bold">
            <a href="https://icons8.com">
              <img src="https://png.icons8.com/windows/32/ffffff/icons8-logo.png" />
            </a>
          </h5>
          <ul style={{ listStyle: 'none', paddingLeft: '0' }}>
            <li>
              <a href="https://icons8.com">Icon pack by Icons8</a>
            </li>
          </ul>
        </div>
        
        </div>
        </div>
        
        <div className="footer-copyright pb-3">
          <p></p>
        </div>
      
      </footer>
    )
}