import React, { Component } from 'react';
import { string, func, bool } from 'prop-types'

import { connect } from 'react-redux';
import { addGalleryThunk, fetchGalleriesThunk } from '../../store/actions/galleryActions'

import { MDBBtn, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';

class AddGallery extends Component {
  static propTypes = {
    modal: bool.isRequired,
    toggle: func.isRequired,
    pushRef: string.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      title: '',
      icon: '',
      description: '',
      link: '',
      newGallery: false,
      error: false
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.push = this.push.bind(this)
  }

  handleInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({
      [name]: value
    })
  }
  
  async push() {
    const { title, description, link, icon } = this.state
    const { pushRef } = this.props
    if( title ) {
      const newGallery = {
        title,
        link,
        icon,
        description,
        pushRef
      }  
      this.props.fetchGalleriesThunk()
      return this.props.addGalleryThunk(newGallery).then(function(){
        return alert(`Galeria adicionada!`)
      }.bind(this))
      .catch(function(){
        return alert(`Erro ao adicionar galeria. Tente novamente mais tarde.`)
      }.bind(this))
    } else {
      alert("Por favor, preencha ao menos o título.")
    }
  }

render() {
  const { toggle, modal } = this.props

  return (
      <MDBModal isOpen={modal} toggle={toggle}>
        <MDBModalHeader toggle={toggle}>Adicionar galeria</MDBModalHeader>
        <MDBModalBody>
          <form>
            <MDBInput
              label="Título"
              name="title"
              onChange={this.handleInputChange}
              group
              type="text"
              success="right"
              value={this.state.title}
            />
            <MDBInput
              label="Ícone"
              name="icon"
              onChange={this.handleInputChange}
              group
              type="text"
              success="right"
              value={this.state.icon}
            />
            <MDBInput
              label="Link"
              name="link"
              onChange={this.handleInputChange}
              group
              type="text"
              success="right"
              value={this.state.link}
            />
            <MDBInput
              label="Descrição"
              name="description"
              onChange={this.handleInputChange}
              group
              type="textarea"
              success="right"
              value={this.state.description}
            />
          </form>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={toggle}>Voltar</MDBBtn>
          <MDBBtn color="success" onClick={this.push}>Adicionar</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    );
  }
}

const mapStateToProps = state => ({
  galleries: state.galleries,
  newGallery: state.galleriesCrud.newGallery,
  error: state.galleriesCrud.error
})

const mapDispatchToProps = dispatch => {
  return {
    addGalleryThunk: (gallery) => dispatch(addGalleryThunk(gallery)),
    fetchGalleriesThunk: () => dispatch(fetchGalleriesThunk())
  }
 }

export default connect(mapStateToProps, mapDispatchToProps)(AddGallery);