import React from 'react'
import styled from 'styled-components'

const AddressWrapper = styled.div`
.google-maps {
  position: relative;
  padding-bottom: 60%; // This is the aspect ratio
  height: 0;
  overflow: hidden;
}
.google-maps iframe {
  position: absolute;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
`

const Address = () => (
  <AddressWrapper>
    <div className="container mt-3">
      <div className="google-maps">
       <iframe 
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1726.9202577234882!2d-51.206487!3d-30.041433!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x634f762a700642d1!2sAshclin!5e0!3m2!1sen!2sbr!4v1547415546803" 
          width="600" 
          height="350" 
          frameBorder="0" 
          style={{ border: '0' }} 
          allowFullScreen 
        />
      </div>
    </div>
  </AddressWrapper>
)

export default Address