import React from 'react'
import { object, func, string } from 'prop-types';

import { MDBInput, MDBBtn, MDBListGroup, MDBListGroupItem } from 'mdbreact'

const propTypes = {
  handleInputChange: func.isRequired,
  name: string.isRequired,
  professorName: object.isRequired,
}

export function CategoryForm(props){
  
  return(
    <form>
      <MDBInput
        label="Nome da categoria"
        name="name"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.name}
      />
      <div className="mt-3">
        <MDBInput
          label="Preço para sócio"
          name="associate"
          onChange={props.handleInputChange}
          group
          type="text"
          success="right"
          value={props.associate}
        />
        <MDBInput
          label="Preço para não sócio"
          name="notAssociated"
          onChange={props.handleInputChange}
          group
          type="text"
          success="right"
          value={props.notAssociated}
        />
      </div>
      <div className="mt-3">
        <MDBInput
          label="Profissional"
          name="professorName"
          onChange={props.handleInputChange}
          group
          type="text"
          success="right"
          value={props.professorName}
        />
        <MDBInput
          label="Atendimento"
          name="professorLesson"
          onChange={props.handleInputChange}
          group
          type="text"
          success="right"
          value={props.professorLesson}
        />
        <MDBInput
          label="Contato"
          name="professorContact"
          onChange={props.handleInputChange}
          group
          type="text"
          success="right"
          value={props.professorContact}
        />
        <MDBBtn color="success" size="sm" onClick={props.assignProfessor}>+ Adicionar profissional</MDBBtn>
        {
          props.professors &&
          Object.keys(props.professors).map((key, index) => {
            return (
              <MDBListGroup className="mb-2">
                <MDBListGroupItem>Profissional: {props.professors[key].name}</MDBListGroupItem>
                <MDBListGroupItem>Atendimento: {props.professors[key].lesson}</MDBListGroupItem>
                <MDBListGroupItem>Contato: {props.professors[key].contact}</MDBListGroupItem>
              </MDBListGroup>
            )
          })
        }
      </div>
    </form>
  )
}