import React from 'react'
import { func, string } from 'prop-types';

import { MDBInput } from 'mdbreact'

const propTypes = {
  handleInputChange: func.isRequired,
  day: string.isRequired,
  lesson1: string.isRequired,
  lesson2: string.isRequired,
  lesson3: string.isRequired
}

export function DayForm(props){
  
  return(
    <form>
      <MDBInput
        label="Dia"
        name="name"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.name}
      />
      <MDBInput
        label="Aula 1"
        name="lesson1"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.lesson1}
      />
      <MDBInput
        label="Aula 2"
        name="lesson2"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.lesson2}
      />
      <MDBInput
        label="Aula 3"
        name="lesson3"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.lesson3}
      />
    </form>
  )
}