import React, { Component } from 'react'
import { bool, func, string } from 'prop-types';

import { connect } from 'react-redux';
import { deleteShiftThunk, fetchLeasesThunk } from '../../store/actions/leasesActions'

import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';

class DeleteShift extends Component {
  static propTypes = {
    modal: bool.isRequired,
    toggle: func.isRequired,
    deleteRef: string.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      id: props.deleteInfo.key,
      modal: props.modal,
    }

    this.delete = this.delete.bind(this)
  }

  delete(){
    const { id } = this.state
    const { deleteRef } = this.props

    this.props.fetchLeases()
    this.props.deleteShift({ id, deleteRef }).then(function(){
      return alert('Turno deletado com sucesso!')
    })
    .catch(function(){
      return alert('Falha ao deletar turno. Tente novamente mais tarde.')
    })
    this.props.toggle()
  }

  render() {
    const { toggle } = this.props

    return(
      <MDBModal isOpen={this.state.modal} toggle={toggle}>
        <MDBModalHeader toggle={toggle}>Excluir turno</MDBModalHeader>
        <MDBModalBody>
          <p>Tem certeza que deseja excluir este turno?</p>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={toggle}>Voltar</MDBBtn>
          <MDBBtn color="danger" onClick={this.delete}>Excluir</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    )
  }
}

const mapStateToProps = state => ({
  leases: state.leases
})

const mapDispatchToProps = dispatch => {
  return {
    deleteShift: (shift) => dispatch(deleteShiftThunk(shift)),
    fetchLeases: () => dispatch(fetchLeasesThunk())
  }
 }

export default connect(mapStateToProps, mapDispatchToProps)(DeleteShift)