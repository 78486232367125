import React, { Component } from 'react'
import { string, array } from 'prop-types'
import { Link } from 'react-router-dom'

import { Table, TableBody, TableHead, MDBIcon, MDNBtn  } from 'mdbreact';

class Covenant extends Component {
  static propTypes = {
    type: string,
    covenants: array
  }

  static defaultProps = {
    type: null
  }

  render() {
    const { covenant } = this.props
    return(
      <div className="container pt-2 pb-5">
      <h1 className="h1-responsive font-bold mt-sm-5 wow fadeInLeft">
        <Link to="/convenios">  
          <MDBIcon icon="arrow-left" size="lg" className="pr-3 cyan-text" />
        </Link>
         {covenant.type}
      </h1>
        <hr className=" rgba-cyan-strong wow fadeInLeft" data-wow-delay="0.3s" ></hr>
        <Table bordered responsive>
        <TableHead color="blue darken-3" textWhite>
          <tr>
            <th><strong>Nome</strong></th>
            <th><strong>Descrição</strong></th>
            <th><strong>Contato</strong></th>
            <th><strong>Responsável</strong></th>
            <th><strong></strong></th>
          </tr>
        </TableHead>
        <TableBody>
        {
          covenant.covenants ? 
          Object.keys(covenant.covenants).map((key) => (
            <tr key={key}>
              <td><a href={`${covenant.covenants[key].link}`} style={ { fontWeight:"500" } }>
                {covenant.covenants[key].name}
              </a></td>
              <td style={ { fontWeight:"500" } }>
                {covenant.covenants[key].description}
              </td>
              <td style={{ minWidth:"160px", fontWeight:"500" }}>
                {covenant.covenants[key].contact}
              </td>
              <td style={ { fontWeight:"500" } }>
                {covenant.covenants[key].owner}
              </td>
              {
                covenant.covenants[key].link &&
                <td style={{ fontWeight:"500", minWidth:"100px" }}><a className="" href={covenant.covenants[key].link}><strong>Saiba mais</strong></a></td>
              }
            </tr>
          ))
          :
          (
            <div>Nenhum convênio foi encontrado nessa categoria</div>
          )
        }
        </TableBody>
      </Table>
      </div>
    )
  }
}

export default Covenant