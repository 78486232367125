import React from 'react'
import { func, string } from 'prop-types';

import { MDBInput } from 'mdbreact'

export function MiscellaneousForm(props){
  return(
    <form>
      <MDBInput
        label="Nome"
        name="name"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.name}
      />
      <MDBInput
        label="Preço"
        name="price"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.price}
      />
      <MDBInput
        label="Contato"
        name="contact"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.contact}
      />
      <MDBInput
        label="Informações Adicionais"
        name="additionalInfo"
        onChange={props.handleInputChange}
        group
        type="textarea"
        success="right"
        value={props.additionalInfo}
      />
    </form>
  )
}