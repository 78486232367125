import React, { Component } from 'react'
import { bool, func, string, object } from 'prop-types';
import firebase from "firebase";

import { connect } from 'react-redux';
import { handleDeleteModal, fetchSlideImagesThunk, deleteSlideImageThunk } from '../../store/actions/slideshowActions';

import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';

class DeleteImage extends Component {
  static propTypes = {
    modal: bool.isRequired,
    toggle: func.isRequired,
    deleteInfo: object.isRequired
  }
  
  constructor(props) {
    super(props);
    
    this.state = {
      imageName: props.deleteInfo.image,
      id: props.deleteId,
      modal: props.modal,
      imageRemoved: false,
      error: false,
    }

    this.delete = this.delete.bind(this)
  }

  deleteResponse(){
    const { covenants } = this.props
    const { covenantRemoved, error } = this.state
    if(covenants.deletedCovenant && covenantRemoved) {
      alert(`${covenants.deletedCovenant} foi removido com sucesso!`)
      this.setState({ modal: false , covenantRemoved: false })
    } else if(covenants.error && error) {
      alert(covenants.error)
      this.setState({ error: false })
    }
  }

  async delete(){
    const { id, imageName } = this.state
    const { firstRef, secondRef } = this.props
    let isSlideshow = `${firstRef === 'slideshow' ? `/${id}` : ''}`
    let isAds = `${secondRef === 'slides' ? `/${secondRef}` : ''}`
    
    await firebase.storage().ref(`${firstRef}${isAds}`).child(`${imageName}`).delete().then(async function(response){
      await firebase.database().ref(`${firstRef}/${secondRef}${isSlideshow}`).remove().then((res) => {
        alert(`A imagem ${imageName} foi removida!`);
        window.location.reload()
      }).catch((err) => {
        alert(`A imagem foi removida porém os dados não. Tente novamente mais tarde.`);
        window.location.reload()
      })
    }).catch(function(){
      alert(`Erro ao remover a imagem ${imageName}.`)
    })
    
    let data = {
      reference: `${firstRef}/${secondRef}/${id}`,
      imageName: imageName,
    }
    this.props.dispatch(deleteSlideImageThunk(data))
    this.props.dispatch(handleDeleteModal(false))
  }

  render() {
    const { toggle } = this.props

    return(
      <MDBModal isOpen={this.state.modal} toggle={toggle}>
        <MDBModalHeader toggle={toggle}>Excluir imagem</MDBModalHeader>
        <MDBModalBody>
          <p>Tem certeza que deseja excluir 
            <b>{`${this.state.imageName}`}</b> de posição <b>{`${this.props.deleteInfo.id}`}</b>?
          </p>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={toggle}>Voltar</MDBBtn>
          <MDBBtn color="danger" onClick={this.delete}>Excluir</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    )
  }
}

const mapStateToProps = state => ({
  slides: state.slides
})

const mapDispatchToProps = dispatch => {
  return {
    //deleteCovenant: (covenant) => dispatch(deleteCovenantThunk(covenant)),
    //fetchCovenants: () => dispatch(fetchCovenantsThunk())
  }
 }

export default connect(mapStateToProps)(DeleteImage)