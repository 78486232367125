import React, { Component } from 'react'
import { Route, Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { fetchCovenantsThunk } from '../../store/actions/covenantActions'

import { Card, CardBody, CardImage, CardTitle, CardText, Col } from 'mdbreact';
import Covenant from './Covenant'

class CovenantCategories extends Component {

  async componentDidMount() {
    this.props.fetchCovenantsThunk()
  }
 
  render() {
    const { covenants } = this.props
    
    return (
      <div className="container">
      <Route exact path="/convenios" render={() => (
        <div>
        <h1 className="h1-responsive font-bold mt-sm-5 wow fadeInLeft">Convênios, <small>descontos de diversas empresas para associados</small></h1>
        <hr className=" rgba-cyan-strong wow fadeInLeft" data-wow-delay="0.3s" ></hr>
        <Col className="d-flex justify-content-start flex-wrap">
        {
          covenants.length === 0 ? (
            <div>Carregando...</div>
          ) : (
            covenants.covenants && covenants.covenants.map((covenant, index) => {
              return (
                <Card key={index} style={{ width: "20rem", margin: "20px" }}>
                  <CardImage
                    className="img-fluid ml-2"
                    src={covenant.icon}
                    waves
                  />
                  <CardBody>
                    <CardTitle>{covenant.type}</CardTitle>
                    <CardText>
                      {covenant.description}
                    </CardText>
                    <Link to={{pathname: `/convenios/${covenant.route}`}}>
                      <button type="button" className="btn light-blue">Ver parcerias</button>
                    </Link>
                  </CardBody>
                  <Route exact path={`/convenios/${covenant.route}`} component={Covenant} key={index} />
                </Card>
              )
            })
          ) 
        }
        </Col>
        </div>
      )}
      /> 
      </div>
    )
  }
}

const mapStateToProps = state => ({
  covenants: state.covenants
})

const mapDispatchToProps = dispatch => {
  dispatch(fetchCovenantsThunk())
  return {
    fetchCovenantsThunk: () => dispatch(fetchCovenantsThunk())
  }
 }

export default connect(mapStateToProps, mapDispatchToProps)(CovenantCategories)