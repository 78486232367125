import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SignIn from "./SignIn";

export default function(ComposedComponent) {
  class Authentication extends Component {
    static contextTypes = {
      router: PropTypes.object
    };

    componentWillUpdate(nextProps) {
      if (nextProps.isAuthenticaded.loggedIn) {
        this.context.router.history.push("/admin");
      }
    }

    render() {
      if (this.props.isAuthenticaded.loggedIn) {
        return <ComposedComponent {...this.props} />;
      } else {    
       return <SignIn />;
      }
    }
  }

  function mapStateToProps(state) {
    return { isAuthenticaded: state.auth };
  }

  return connect(mapStateToProps)(Authentication);
}