import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Button, MDBIcon } from 'mdbreact'
import Address from './Address'
import docDivulgacao from '../../assets/download/DIVULGAÇÃO ELEIÇÃO 2021-2023.pdf'
import chapa from '../../assets/download/DIVULGAÇÃO CHAPA HOMOLOGADA.pdf'

import Slideshow from '../Carousel'

const images = [
  'home1.jpg',
  'home2.jpg',
  'home3.jpg',
  'home4.jpg',
  'home5.jpg',
  'home6.jpg'
]

class Home extends Component {
  render() {
    return (
      <div>
        <div className={`container pb-4 ${!window.matchMedia("(max-width:520px)").matches && 'pt-4'}`}>
          <hr className="mt-2 mb-4 rgba-cyan-strong wow fadeInLeft" data-wow-delay="0.3s" ></hr>
            <Slideshow
              category="slideshow"
              imageHeight="560px"
              imageHeightMobile="275px"
              mainPage
              storageRef="slideshow"
            />
        </div>
        
        <div className='container pt-3 pb-3'>
          <hr className="mt-2 mb-4 rgba-cyan-strong wow fadeInLeft" data-wow-delay="0.3s" ></hr>
          <header>
            <h2>Horário de funcionamento das lojas</h2>
          </header>
          <section>
            <p>
              Segunda a quinta: 7:05h às 17hs
            </p>
            <p>
              Sextas-feiras: 7:05 às 16hs
            </p>
          </section>
          <hr className="mt-2 mb-4 rgba-cyan-strong wow fadeInLeft" data-wow-delay="0.3s" ></hr>
        </div>
        <div className='container pb-3'>
          <Address />
        </div>
      <div className="fluid teal accent-1 mt-4 mb-2 pt-2 pb-2">
        <div className="container">
          <Link to="/parquesocial">
            <h2 className="teal-text">
              PARQUE SOCIAL
            </h2>
          </Link>
        </div>
      </div>
      <div className="container d-flex justify-content-around flex-wrap pb-5">
        {
          images.map((image, key) => (
            <img 
              height="100"
              width="160"
              style={{ margin: "10px" }}
              src={require(`../../assets/home/${image}`)}
              key={key}
            />
          ))
        }
      </div>
      </div>
    )
  }
}

export default Home;