import React, { Component } from 'react'
import { bool, func, object } from 'prop-types';

import { connect } from 'react-redux';
import { editAdThunk, fetchClassifiedThunk } from '../../store/actions/classifiedAdsActions'

import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { HomesForm } from './formTypes/homesForm';
import { AutoMobileForm } from './formTypes/autoMobileForm'
import { MiscellaneousForm } from './formTypes/miscellaneousForm'

class EditAd extends Component {
  static propTypes = {
    modal: bool.isRequired,
    toggle: func.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      type: props.editInfo.type,
      name: props.editInfo.name,
      contact: props.editInfo.contact,
      model: props.editInfo.model,
      year: props.editInfo.year,
      city: props.editInfo.city,
      additionalInfo: props.editInfo.additionalInfo,
      link: props.editInfo.link,
      bedRooms: props.editInfo.bedRooms,
      bathRooms: props.editInfo.bathRooms,
      price: props.editInfo.price,
      reception: props.editInfo.reception,
      restoration: props.editInfo.restoration,
      taxes: props.editInfo.taxes,
      title: props.editInfo.title,
      location: props.editInfo.location,
      pictures: props.editInfo.pictures
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.edit = this.edit.bind(this)
  }

  updateResponse(){
    const { editedAd, adCrud, error } = this.props
    
    if(editedAd) {
      alert(`Anúncio de ${editedAd.type} editado com sucesso!`)
    } else if(error) {
      alert(editedAd.error)
    }
  }

  edit(){
    const { 
      type,
        name,
        model,
        year,
        city,
        contact,
        additionalInfo,
        link,
        bedRooms,
        bathRooms,
        price,
        reception,
        restoration,
        taxes,
        title,
        location,
        pictures
      } = this.state
    const { id, editRef } = this.props

    if( type ) {
      const updatedAd = {
        type,
        name,
        model,
        year,
        city,
        contact,
        additionalInfo,
        link,
        bedRooms,
        bathRooms,
        price,
        reception,
        restoration,
        taxes,
        title,
        location,
        pictures,
        id,
        editRef
      }
      
      this.props.fetchClassifiedThunk()
      return this.props.editAd(updatedAd).then(function(){
        return alert(`Anúncio de ${type} editado com sucesso!`)
      }.bind(this))
      .catch(function(){
        return alert(`Erro ao editar anúncio, tente novamente mais tarde!`)
      }.bind(this))
    } else {
      alert("Preencha ao menos o tipo do anúncio.")
    }
  }

  handleInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({
      [name]: value
    })
  }

  render() {
    const { modal, toggle } = this.props

    return(
      <MDBModal isOpen={modal} toggle={toggle}>
        <MDBModalHeader toggle={toggle}>Editar anúncio</MDBModalHeader>
        <MDBModalBody>
          {
            ( this.props.editedAd.type === 'apartamentos' || this.props.editedAd.type === 'apartamentos' || this.props.editedAd.type === 'imoveisComerciais' ) &&
              <HomesForm
                handleInputChange={this.handleInputChange}
                { ...this.state }
              />
          }
          {
            ( this.props.editedAd.type === 'automoveis' || this.props.editedAd.type === 'motocicletas' ) &&
              <AutoMobileForm
                handleInputChange={this.handleInputChange}
                { ...this.state }
              />
          }
          {
            ( this.props.editedAd.type === 'equipamentosMedicos' || this.props.editedAd.type === 'eletrodomesticos' || this.props.editedAd.type === 'outros' ) &&
              <MiscellaneousForm
                handleInputChange={this.handleInputChange}
                { ...this.state }
              />
          }
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={toggle}>Voltar</MDBBtn>
          <MDBBtn color="primary" onClick={this.edit}>Editar</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    )
  }
}

const mapStateToProps = state => ({
  classifiedAds: state.classifiedAds,
  editedAd: state.addClassifiedAds.edit,
  adCrud: state.addClassifiedAds,
  error: state.addClassifiedAds.error
})

const mapDispatchToProps = dispatch => {
  return {
    editAd: (covenant) => dispatch(editAdThunk(covenant)),
    fetchClassifiedThunk: () => dispatch(fetchClassifiedThunk())
  }
 }

export default connect(mapStateToProps, mapDispatchToProps)(EditAd)