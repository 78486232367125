import {
  FETCH_SLIDES,
  DELETE_SLIDE,
  EDIT_SLIDE,
  EDITED_SLIDE,
  SLIDE_NOT_EDITED,
  HANDLE_DELETE_MODAL,
  HANDLE_EDIT_MODAL,
  DELETE_SLIDE_THUNK
} from '../actions/types'

export function slideshow(state = [], action){
  switch(action.type) {
    case FETCH_SLIDES:
      return {
        ...state,
        slides: action.slides
      }
      case EDIT_SLIDE:
      return {
        ...state,
        edit: action.object,
        editId: action.key,
        editModal: action.modal,
        slideEdited: true
      }
    case DELETE_SLIDE:
      return {
        ...state,
        delete: action.object,
        deleteId: action.key,
        deleteModal: action.modal
      }
    case DELETE_SLIDE_THUNK:
      return {
        ...state,
        isDeleted: action.deleteStatus
      }
    case HANDLE_DELETE_MODAL:
      return {
        ...state,
        deleteModal: action.deleteModal
      }
    case HANDLE_EDIT_MODAL:
      return {
        ...state,
        editModal: action.editModal
      }
    case EDITED_SLIDE:
      debugger
      return {
        ...state,
        updatedSlide: action.imageName,
        slideEdited: true,
        editModal: false
      }
    case SLIDE_NOT_EDITED:
      return {
        ...state,
        updatedSlide: {},
        slideEdited: false,
        errorSlide: action.error,
        editModal: false
      }
    default:
      return {
        ...state
      }
  }
}