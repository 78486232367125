import React from 'react'

import { MDBBtn, MDBCardText, MDBCard, MDBCardBody, MDBContainer, MDBCardTitle } from 'mdbreact';

export function SlideImageCard(props){
    return(
      <MDBCard
        id={props.id}
        style={{ width: '20em', margin: '10px' }}>
        <MDBCardBody className="d-flex flex-wrap justify-content-between flex-column align-items-start">
            <MDBCardTitle>
              {
                props.url ? (
                  <img
                    className="m-2" 
                    src={props.url}  
                    width="95%"
                  />
                ) : (
                  <span>loading</span>
                )
              }
            </MDBCardTitle>
            <MDBCardText className="pl-3 w-75" style={{ height: '100px' }}>
              {props.position && `Posição: ${props.position}`} 
              <br />
              {`Nome do arquivo: ${props.image}`}
            </MDBCardText>
            {
              props.modifiedBy &&
              <MDBCardText className="pl-3 w-75">
                <i>Última modificação: {props.modifiedBy}</i>
              </MDBCardText>
            }
            <div className={`d-flex flex-nowrap justify-content-between`}>
              <MDBBtn color="danger" onClick={props.onDelete} style={{ borderRadius: '50px' }}>
                DELETAR
              </MDBBtn>
              <MDBBtn color="info" onClick={props.onEdit} style={{ borderRadius: '50px' }}>
                EDITAR
              </MDBBtn>
          </div>
        </MDBCardBody>
      </MDBCard>
    )
}