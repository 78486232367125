import React, { Component } from 'react';
import { string, func, bool } from 'prop-types'

import { connect } from 'react-redux';
import { addClassifiedAdThunk, fetchClassifiedThunk } from '../../store/actions/classifiedAdsActions'

import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { HomesForm } from './formTypes/homesForm';
import { AutoMobileForm } from './formTypes/autoMobileForm';
import { MiscellaneousForm } from './formTypes/miscellaneousForm';

class CreateAd extends Component {
  static propTypes = {
    modal: bool.isRequired,
    toggle: func.isRequired,
    pushRef: string.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      type: '',
      additionalInfo: '',
      contact: '',
      link: '',
      bedRooms: '',
      bathRooms: '',
      price: '',
      reception: '',
      restoration: '',
      taxes: '',
      title: '',
      location: ''
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleType = this.handleType.bind(this)
    this.push = this.push.bind(this)
  }

  handleInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({
      [name]: value
    })
  }

  handleType(event) {
    const value = event.target.value
    this.setState({
      type: value
    })
  }

  pushResponse() {
    const { newAd, error } = this.props
    if(newAd && !error) {
     alert(`Anúncio adicionado à categoria ${newAd.type}`)
     this.setState({ 
      type: '',
      name: '',
      contact: '',
      model: '',
      year: '',
      city: '',
      additionalInfo: '',
      link: '',
      bedRooms: '',
      bathRooms: '',
      price: '',
      reception: '',
      restoration: '',
      taxes: '',
      title: '',
      location: ''
    })
    } else if(!newAd && error) {
      alert(error)
    }
  }

  async push() {
    const { 
        type,
        name,
        contact,
        model,
        year,
        city,
        additionalInfo,
        link,
        bedRooms,
        bathRooms,
        price,
        reception,
        restoration,
        taxes,
        title,
        location
     } = this.state
    const pushRef = `classifiedAds/${type}/ads`
    if( type ) {
      const newAd = {
        type,
        name,
        contact,
        model,
        year,
        city,
        additionalInfo,
        link,
        bedRooms,
        bathRooms,
        price,
        reception,
        restoration,
        taxes,
        title,
        location,
        pushRef
      }  
      this.props.fetchClassifiedThunk()
      return this.props.addClassifiedAdThunk(newAd)
      .then(function(){
        return this.setState({
          newAd: true
        }, () => this.pushResponse())
      }.bind(this))
      .catch(function(){
        return this.setState({
          error: true
        }, () => this.pushResponse())
      }.bind(this))
    } else {
      alert("Por favor, preencha ao menos o tipo.")
    }
  }

render() {
  const { toggle, modal } = this.props
  const { type } = this.state
  const moradias = type === 'apartamentos' || type === 'casas' || type === 'imoveisComerciais'
  const autos = type === 'automoveis' || type === 'motocicletas'
  const miscellaneous = type === 'equipamentosMedicos' || type === 'moveis' || type === 'eletrodomesticos' || type === 'outros'

  return (
      <MDBModal isOpen={modal} toggle={toggle}>
        <MDBModalHeader toggle={toggle}>Adicionar anúncio</MDBModalHeader>
        <MDBModalBody>
          <select onChange={this.handleType} className="browser-default custom-select">
            <option>Selecione o tipo de anúncio</option>
            <option value="apartamentos">Apartamentos</option>
            <option value="casas">Casas</option>
            <option value="automoveis">Automóveis</option>
            <option value="motocicletas">Motocicletas</option>
            <option value="equipamentosMedicos">Equipamentos médicos</option>
            <option value="imoveisComerciais">Imóveis comerciais</option>
            <option value="moveis">Móveis</option>
            <option value="eletrodomesticos">Eletrodomésticos</option>
            <option value="outros">Outros</option>
          </select>
          {
            moradias && 
              <HomesForm 
                handleInputChange={this.handleInputChange}
                { ...this.state }
              />
          }
          {
            autos &&
              <AutoMobileForm
                handleInputChange={this.handleInputChange}
                { ...this.state }
              />
          }
          {
            miscellaneous &&
              <MiscellaneousForm
                handleInputChange={this.handleInputChange}
                { ...this.state }
              />
          }
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={toggle}>Voltar</MDBBtn>
          <MDBBtn color="success" onClick={this.push}>Adicionar</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    );
  }
}

const mapStateToProps = state => ({
  classifiedAds: state.classifiedAds,
  newAd: state.addClassifiedAds.newAd,
  error: state.addClassifiedAds.pushError
})

const mapDispatchToProps = dispatch => {
  return {
    addClassifiedAdThunk: (covenant) => dispatch(addClassifiedAdThunk(covenant)),
    fetchClassifiedThunk: () => dispatch(fetchClassifiedThunk())
  }
 }

export default connect(mapStateToProps, mapDispatchToProps)(CreateAd);