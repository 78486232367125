import React, { Component } from 'react';
import { string, func, bool } from 'prop-types'

import { connect } from 'react-redux';
import { addShiftThunk, fetchLeasesThunk } from '../../store/actions/leasesActions'

import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { ShiftForm } from './ShiftForm';

class CreateShift extends Component {
  static propTypes = {
    modal: bool.isRequired,
    toggle: func.isRequired,
    pushRef: string.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      shift: '',
      schedule: '',
      cash: '',
      creditCard: '',
      type: '',
      newShift: false,
      error: false,
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.push = this.push.bind(this)
    this.handleType = this.handleType.bind(this)
  }

  handleInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({
      [name]: value
    })
  }

  handleType(event) {
    const value = event.target.value;
    this.setState({
      type: value
    })
  }

  async push() {
    const { shift, schedule, cash, creditCard, type } = this.state
    const { pushRef } = this.props
    if( shift && schedule && cash && type ) {
      const newShift = {
        shift, 
        schedule, 
        cash, 
        creditCard,
        type,
        pushRef
      }  
      this.props.fetchLeases()
      return this.props.addShift(newShift)
      .then(function(){
        return alert('Turno adicionado com sucesso!')
      })
      .catch(function(){
        return alert('Falha ao adicionar turno. Tente novamente mais tarde.')
      })
    } else {
      alert("Por favor, preencha ao menos o turno, horários, valor à vista e tipo.")
    }
  }

render() {
  const { toggle, modal } = this.props

  return (
      <MDBModal isOpen={modal} toggle={toggle}>
        <MDBModalHeader toggle={toggle}>Adicionar turno</MDBModalHeader>
        <MDBModalBody>
          <select onChange={this.handleType} className="browser-default custom-select">
            <option>Selecione o tipo de locação</option>
            <option value="salaogrande">Salão grande</option>
            <option value="salaopequeno1">Salões pequenos</option>
            <option value="quisques">Quiosques</option>
            <option value="quadras">Quadras</option>
          </select>
          <ShiftForm 
            handleInputChange={this.handleInputChange}
            shift={this.state.shift}
            schedule={this.state.schedule}
            cash={this.state.cash}
            creditCard={this.state.creditCard}
          />
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={toggle}>Voltar</MDBBtn>
          <MDBBtn color="success" onClick={this.push}>Adicionar</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    );
  }
}

const mapStateToProps = state => ({
  leases: state.leases
})

const mapDispatchToProps = dispatch => {
  return {
    addShift: (shift) => dispatch(addShiftThunk(shift)),
    fetchLeases: () => dispatch(fetchLeasesThunk())
  }
 }

export default connect(mapStateToProps, mapDispatchToProps)(CreateShift);