import database from '../../firebase/index'
import {
  FETCH_SLIDES,
  DELETE_SLIDE,
  DELETE_SLIDE_THUNK,
  EDIT_SLIDE,
  EDITED_SLIDE,
  SLIDE_NOT_EDITED,
  HANDLE_DELETE_MODAL,
  HANDLE_EDIT_MODAL
} from './types'
import { handlePictureModal } from '../actions/adsPicturesActions'

export const fetchSlideImages = (slides) => {
  return {
    type: FETCH_SLIDES,
    slides
  }
}

export const setDeleteId = (object, key, modal) => {
  return {
    type: DELETE_SLIDE,
    object,
    key,
    modal
  }
}

export const setEditId = (object, key, modal) => {
  return {
    type: EDIT_SLIDE,
    object,
    key,
    modal
  }
}

export const handleDeleteModal = (deleteModal) => {
  return {
    type: HANDLE_DELETE_MODAL,
    deleteModal
  }
}

export const handleEditModal = (editModal) => {
  return {
    type: HANDLE_EDIT_MODAL,
    editModal
  }
}

export const updateFail = (action) => {
  return {
    type: SLIDE_NOT_EDITED,
    error: `Erro ao editar ${action.image}. Tente novamente mais tarde.`
  }
}

export const updateSuccess = (action) => {
  return {
    type: EDITED_SLIDE,
    imageName: action.imageName
  }
}

// function fetchURLs(slides, action){
//   debugger
//   return async dispatch => {
//     await Object.keys(slides[0]).map(async function(key){
//       await storage.ref(action).child(`${slides[0][key].image}`).getDownloadURL()
//       .then(function(url){
//         return slides[0][key] = Object.assign({ url: url }, slides[0][key])
//       }.bind(slides[0][key]))
//     })
//     dispatch(fetchSlideImages(slides))
//   }
// }

export function fetchSlideImagesThunk(action) {
  return dispatch => {
    let slides = {};
    database.ref(action).once('value', snap => {
      snap.forEach(data => {
        let images = data.val();
        slides = Object.assign({ images }, slides)
      })
    })
    .then(() => dispatch(
      fetchSlideImages(slides, action)
    ))
    .catch(() => dispatch(
      fetchSlideImages(slides, action)
    ))
  }
}

export function editSlideThunk(action) {
  return (dispatch) => {
    const updatedSlide = {
      id: parseInt(action.position),
      image: action.imageName,
      url: action.url,
      modifiedBy: JSON.parse(localStorage.getItem('auth')).user.displayName
    }
    
    return database.ref(`${action.editRef}/${action.editId}`).update(updatedSlide)
    .then((res) => dispatch(
      updateSuccess(action)
    ))
    .then(() => dispatch(
      handlePictureModal('', '', false)
    ))
    .catch((err) => dispatch(
      updateFail(action)
    ))
  }
}

export function deleteSlideImageThunk({ reference, imageName, position } = {}) {
  return (dispatch) => {
    return database.ref(reference).remove()
    .then((res) => {
      return {
        type: DELETE_SLIDE_THUNK,
        deleteStatus: true
      }
    })
    .then(() => dispatch(
      handlePictureModal('', '', false)
    ))
    .catch((err) => {
      return {
        deleteStatus: err || `Erro ao deletar ${imageName}. Tente novamente mais tarde.`
      }
    })
  }
}