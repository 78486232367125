import { SET_AD_EDIT_ID } from '../actions/types'

export function adPictures(state = '', action){
  switch(action.type) {
    case SET_AD_EDIT_ID:
      return {
        ...state,
        modal: action.modal,
        selectedId: action.selectedId,
        selectedType: action.adType
      }
    default:
      return state;
  }
}

