import React from 'react'
import { Link } from 'react-router-dom'
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardHeader, MDBBtn } from 'mdbreact'

export default function SocialPark(){
  const routes = [
    {
      name: 'Atividades',
      route: '/atividades',
      image: 'https://img.icons8.com/officel/80/000000/ballet-shoes.png',
      description: 'Yoga, Ballet, Karate, futebol, terapias e muito mais!'
    },
    {
      name: 'Locação de espaços',
      route: '/locacoes',
      image: 'https://img.icons8.com/clouds/100/000000/dancing-party.png',
      description: 'Salões de festa, quadras de futebol, e quiosques!'
    }
  ]
  return(
    <div class="container mt-5 d-flex flex-wrap">
      {
        routes.map(route => {
          return(
            <MDBCard style={{ width: "20rem", margin: "1rem" }}>
              <MDBCardHeader color="light-blue lighten-1">{route.name}</MDBCardHeader>
              <MDBCardBody>
                <MDBCardTitle>
                  <img src={ route.image } />
                </MDBCardTitle>
                <MDBCardText>
                  {route.description}
                </MDBCardText>
                <Link to={route.route}>  
                  <MDBBtn color="light-blue lighten-1">Ver mais</MDBBtn>
                </Link>
              </MDBCardBody>
            </MDBCard>
          )
        })
      }
    </div>
  )
}