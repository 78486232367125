import React, { Component } from 'react'

import { connect } from 'react-redux'

import { Header } from '../Layout/Header'
import { DaysCard } from './DaysCard'
import { PricesCard } from './PricesCard'
import { ProfessorsCard } from './ProfessorsCard'
import { CategoryCard } from './CategoryCard'
import DeleteLesson from './DeleteLesson'
import CreateLesson from './CreateLesson'
import EditLesson from './EditLesson'
import { MDBBtn, MDBIcon } from 'mdbreact'
import SignIn from '../Authentication/SignIn';

class LessonShift extends Component {
  state = {
    editModal: false,
    deleteModal: false,
    days: false,
    prices: false,
    categories: false,
    type: '',
    id: '',
    lessonToBeEdited: '',
    lessonToBeDeleted: '',
    priceToBeDeleted: '',
    professorToBeEdited: '',
    professorToBeDeleted: '',
    categoryToBeEdited: '',
    categoryToBeDeleted: '',
    priceToBeEdited: {
      associate: {
        price: ''
      },
      notAssociated: {
        price: ''
      }
    },
  }
  
  togglePushPriceModal = () => {
    this.setState({
      prices: !this.state.prices
    });
  }

  togglePushCategoryModal = () => {
    this.setState({
      categories: !this.state.categories
    });
  }

  togglePushDayModal = () => {
    this.setState({
      days: !this.state.days
    });
  }

  toggleEditModal = () => {
    this.setState({
      editModal: !this.state.editModal
    })
  }

  toggleDeleteModal = () => {
    this.setState({
      deleteModal: !this.state.deleteModal
    })
  }

  render() {
    const { lesson, pushRef, isAuthenticated } = this.props
    
    if(isAuthenticated.loggedIn) {
      return (
        <div className="pb-5">
          <Header text={`Locações - ${lesson.name} | `} routeBack="/admin/parquesocial" />
          <div className={`container d-flex justify-content-center flex-wrap`}>
            {
              this.state.days &&
                <CreateLesson
                  toggle={this.togglePushDayModal}
                  modal={this.state.days}
                  days={this.state.days}
                  type={'days'}
                  pushRef={pushRef}
                />
            }
            {
              this.state.prices &&
                <CreateLesson
                  toggle={this.togglePushPriceModal}
                  modal={this.state.prices}
                  prices={this.state.prices}
                  type={'prices'}
                  pushRef={pushRef}
                />
            }
            {
              this.state.categories &&
                <CreateLesson
                  toggle={this.togglePushCategoryModal}
                  modal={this.state.categories}
                  category={this.state.categories}
                  type={'categories'}
                  pushRef={pushRef}
                />
            }
            {
              this.state.editModal && 
                <EditLesson 
                  toggle={this.toggleEditModal}
                  modal={this.state.editModal}
                  editPriceInfo={this.state.priceToBeEdited}
                  editLessonInfo={this.state.lessonToBeEdited}
                  editProfessorInfo={this.state.professorToBeEdited}
                  editCategoryInfo={this.state.categoryToBeEdited}
                  type={this.state.type}
                  id={this.state.id}
                  editRef={pushRef}
                />
            }
            {
              this.state.deleteModal &&
                <DeleteLesson
                  toggle={this.toggleDeleteModal}
                  modal={this.state.deleteModal}
                  type={this.state.type}
                  id={this.state.id}
                  deleteRef={pushRef}
                />
            }
            <div className="d-flex flex-wrap justify-content-center align-items-center">
              <MDBBtn outline color="success" onClick={this.togglePushDayModal}>
                <MDBIcon icon="plus" /> Adicionar turno
              </MDBBtn>
              <MDBBtn outline color="success" onClick={this.togglePushCategoryModal}>
                <MDBIcon icon="plus" /> Adicionar categoria
              </MDBBtn>
              <MDBBtn outline color="success" onClick={this.togglePushPriceModal}>
                <MDBIcon icon="plus" /> Adicionar preço
              </MDBBtn>
            </div>
          </div>
          <div className="container pt-4 pb-2">
            <h3>Turnos</h3>
          </div>
          <div className={`container d-flex justify-content-start flex-wrap`}>
            {
              lesson.days ? 
                Object.keys(lesson.days).map((key) => {
                  return (
                    <DaysCard 
                      lesson={lesson.days[key]}
                      width="22em"
                      id={key}
                      key={key}
                      onDelete={() => { this.setState({ lessonToBeDeleted: lesson.days[key], id: key, type: 'days' }); this.toggleDeleteModal(); }}
                      onEdit={() => { this.setState({ lessonToBeEdited: lesson.days[key], id: key, type: 'days' }); this.toggleEditModal(); }}
                    />
                  )
                }) : (
                  <div>Nenhuma atividade foi encontrada nessa categoria</div>
                )
            }
        </div>
        <div className="container pt-4 pb-2">
          <h3>Preços</h3>
        </div>
        <div className={`container d-flex justify-content-start flex-wrap`}>
        {
          lesson.prices ? 
            Object.keys(lesson.prices).map((key) => {
              return (
                <PricesCard 
                  lesson={lesson.prices[key]}
                  width="22em"
                  id={key}
                  key={key}
                  onDelete={() => { this.setState({ priceToBeDeleted: lesson.prices[key], id: key, type: 'prices' }); this.toggleDeleteModal(); }}
                  onEdit={() => { this.setState({ priceToBeEdited: lesson.prices[key], id: key, type: 'prices' }); this.toggleEditModal(); }}
                />
              )
            }) : (
              <div>Nenhum preço foi encontrado nessa categoria</div>
            )
        }
        </div> 
        <div className="container pt-4 pb-2">
          <h3>Categorias</h3>
        </div>
        <div className={`container d-flex justify-content-start flex-wrap`}>
        {
          lesson.categories ? 
            Object.keys(lesson.categories).map((key) => {
              const professors = lesson.categories[key].professors
              const keyType = key
              
              return (
                <div className="d-flex flex-column w-100 justify-content-start">
                  <h5>{ lesson.categories[key].type }</h5>
                  <div className="d-flex flex-wrap mb-2 w-100 justify-content-start">
                    <CategoryCard 
                      category={lesson.categories[key]}
                      width="22em"
                      id={key}
                      key={key}
                      onDelete={() => { this.setState({ categoryToBeDeleted: lesson.categories[key], id: key, type: `categories` }); this.toggleDeleteModal(); }}
                      onEdit={() => { this.setState({ categoryToBeEdited: lesson.categories[key], id: key, type: `categories` }); this.toggleEditModal(); }}
                    />
                  </div>
                  <div className="d-flex flex-wrap mb-5 w-100 justify-content-start">
                    <h6 className="w-100">{professors ? `Profissionais de ${lesson.categories[key].type}` : `Nenhum profissional em ${lesson.categories[key].type}`}</h6>
                    {
                      professors &&
                      Object.keys(professors).map((key, index) => (
                        <ProfessorsCard 
                          professor={professors[key]}
                          width="22em"
                          id={professors[key].key}
                          key={professors[key].key}
                          onDelete={() => { this.setState({ professorToBeDeleted: professors[key], id: key, type: `categories/${keyType}/professors` }); this.toggleDeleteModal(); }}
                          onEdit={() => { this.setState({ professorToBeEdited: professors[key], id: key, type: `categories/${keyType}/professors` }); this.toggleEditModal(); }}
                        />
                      ))
                    }
                  </div>
                </div>
              )
            }) : (
              <div>Nenhuma categoria foi encontrada</div>
            )
        }
        </div>  
        </div>
      )
    } else {
      return <SignIn />
    }
  }
}

const mapStateToProps = state => ({
  lessons: state.lessons,
  isAuthenticated: state.auth
})

export default connect(mapStateToProps)(LessonShift)