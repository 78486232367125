import React, { Component } from 'react'
import { connect } from 'react-redux';

import { MDBRow, MDBCol } from 'mdbreact'

import { Header } from '../Layout/Header'
import { CategoriesCard } from '../Layout/CategoriesCard';

class LessonsAdmin extends Component {
  
  render(){
    const { lessons } = this.props
    
    return(
      <div className="container">
        <Header text="Parque Social" routeBack="/admin" />
        <MDBRow>
          <MDBCol size="6">
            {
              lessons === 'loading' ? (
                <div>Carregando...</div>
              ) : (
                lessons && lessons.map((lesson, key) => {
                  return(
                    <CategoriesCard
                      title={lesson.name}
                      type={`parquesocial`}
                      route={lesson.route}
                      button={`editar`}
                      key={key}
                    />
                  )
                })
              )
            }
            </MDBCol>
        </MDBRow>
        
      </div>
    )
  }
}

const mapStateToProps = state => ({
  lessons: state.lessons
})

export default connect(mapStateToProps)(LessonsAdmin)