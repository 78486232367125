import firebase from 'firebase'
import database from '../../firebase/index'
import { 
  FETCH_CLASSIFIED,
  ADD_AD,
  SET_AD_EDIT_ID,
  SET_AD_DELETE_ID,
  EDITED_AD,
  DELETE_SUCCESS,
  DELETE_FAIL,
  DELETE_PENDING
} from './types'

export const fetchClassifiedAds = (classifiedAds) => ({
  type: FETCH_CLASSIFIED, classifiedAds
})

export const addAd = (ad, error) => ({
  type: ADD_AD,
  ad,
  error
})

export const setEditId = (object, key, editModal) => {
  return {
    type: SET_AD_EDIT_ID,
    object,
    key,
    editModal
  }
}

export const setDeleteId = (object, key, deleteModal) => {
  return {
    type: SET_AD_DELETE_ID,
    object,
    key,
    deleteModal
  }
}

const editAd = (id, ad, error) => ({
  type: EDITED_AD,
  id,
  ad,
  error
})

const deleteSuccess = (action, deletedImages) => {
  debugger
  return {
    type: DELETE_SUCCESS,
    deletedType: action.deleteInfo.type,
    deletedImages: deletedImages
  }
}

const deleteFail = (action) => ({
  type: DELETE_FAIL,
  error: `Erro ao deletar anúncio de ${action.deleteInfo.type}. Tente novamente mais tarde`
})

const deletePending = (action, deletedImages) => ({
  type: DELETE_PENDING,
  action,
  deletedImages
})

export function addClassifiedAdThunk(action) {
  return dispatch => {
    const ad = {
      type: action.type || '',
      additionalInfo: action.additionalInfo || '',
      contact: action.contact || '',
      link: action.link || '',
      bedRooms: action.bedRooms || '',
      bathRooms: action.bathRooms || '',
      price: action.price || '',
      reception: action.reception || '',
      restoration: action.restoration || '',
      taxes: action.taxes || '',
      title: action.title || '',
      location: action.location || '',
      modifiedBy: JSON.parse(localStorage.getItem('auth')).user.displayName,
      date: new Date()
    }

    const autoMobAd = {
      type: action.type || '',
      model: action.model || '',
      year: action.year || '',
      price: action.price || '',
      contact: action.contact || '',
      additionalInfo: action.additionalInfo || '',
      city: action.city || '',
      modifiedBy: JSON.parse(localStorage.getItem('auth')).user.displayName,
      date: new Date()
    }

    const miscellaneousAd = {
      type: action.type || '',
      name: action.name || '',
      price: action.price || '',
      contact: action.contact || '',
      additionalInfo: action.additionalInfo || '',
      modifiedBy: JSON.parse(localStorage.getItem('auth')).user.displayName,
      date: new Date()
    }
    const homes = action.type === 'apartamentos' || action.type === 'casas'
    const autos = action.type === 'automoveis' || action.type === 'motocicletas'

    const payload = homes ? ad : autos ? autoMobAd : miscellaneousAd
    debugger
    return database.ref(action.pushRef).push(payload).then((ref) => {
      dispatch(addAd({
        id: ref.key,
        success: true,
        ...ad
      }));
    })
    .catch(function(err){
      dispatch(addAd({
        error: err | `Erro ao adicionar anúncio. Tente novamente mais tarde.`
      }))
    })
  }
}

export function editAdThunk(action) {
  return (dispatch) => {
    const ad = {
      type: action.type || '',
      additionalInfo: action.additionalInfo || '',
      contact: action.contact || '',
      link: action.link || '',
      bedRooms: action.bedRooms || '',
      bathRooms: action.bathRooms || '',
      price: action.price || '',
      reception: action.reception || '',
      restoration: action.restoration || '',
      taxes: action.taxes || '',
      title: action.title || '',
      location: action.location || '',
      pictures: action.pictures || {},
      modifiedBy: JSON.parse(localStorage.getItem('auth')).user.displayName,
      date: new Date()
    }

    const autoMobAd = {
      type: action.type || '',
      model: action.model || '',
      year: action.year || '',
      price: action.price || '',
      contact: action.contact || '',
      additionalInfo: action.additionalInfo || '',
      city: action.city || '',
      pictures: action.pictures || {},
      modifiedBy: JSON.parse(localStorage.getItem('auth')).user.displayName,
      date: new Date()
    }

    const miscellaneousAd = {
      type: action.type || '',
      name: action.name || '',
      price: action.price || '',
      contact: action.contact || '',
      additionalInfo: action.additionalInfo || '',
      pictures: action.pictures || {},
      modifiedBy: JSON.parse(localStorage.getItem('auth')).user.displayName,
      date: new Date()
    }
    const homes = action.type === 'apartamentos' || action.type === 'casas'
    const autos = action.type === 'automoveis' || action.type === 'motocicletas'

    const payload = homes ? ad : autos ? autoMobAd : miscellaneousAd
    
    return database.ref(`${action.editRef}/${action.id}`).update(payload).then((res) => {
      dispatch(editAd(action.id, payload))
    })
    .catch((err) => {
      dispatch(editAd({
        error: err || `Erro ao editar ${action.type}. Tente novamente mais tarde.`
      }))
    })
  }
}

export function deleteAdObjectThunk(action, deletedImages) {
  return async dispatch => {
    await database.ref(`${action.deleteRef}/${action.deleteId}`).remove()
    .then(res => dispatch(
        deleteSuccess(action, deletedImages))
    )
    .catch(async err => await dispatch(
      deleteFail(action, deletedImages)
    ))
  }
}

export function deleteAdThunk(action) {
  return async dispatch => {
    const deletedImages = []
    let deleted = false
    debugger
    if(action.deleteInfo.pictures) {
      await Object.keys(action.deleteInfo.pictures).map(async key => {
        await firebase.storage().ref(`${action.deleteRef}/${action.deleteId}/pictures`).child(`${action.deleteInfo.pictures[key].image}`).delete()
        .then((res) => {
          deletedImages.push(action.deleteInfo.pictures[key].image);
          deleted = true
        })
      })
      
      deleted && dispatch(deleteSuccess(action, deletedImages))
      !deleted && dispatch(deleteFail(action, deletedImages))
    } else {
      const deletedImages = []
      dispatch(deletePending(action, deletedImages))
    }
  }
}

export function fetchClassifiedThunk() {
  return dispatch => {
    const classifiedAds = [];
    database.ref('classifiedAds').once('value', snap => {
      snap.forEach(data => {
        let classified = data.val();
        classifiedAds.push(classified)
        })
    })
    .then(() => dispatch(
      fetchClassifiedAds(classifiedAds)
    ))
  }
}

export function fetchClassifiedRoutesThunk() {
  return dispatch => {
    const classifiedAds = [];
    database.ref('classifiedAds').once('value', snap => {
      snap.forEach(data => {
        let classified = data.val();
        classifiedAds.push(classified.route)
        })
    })
    .then(() => dispatch(
      fetchClassifiedAds(classifiedAds)
    ))
  }
}