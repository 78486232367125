import React from 'react'
import { func, string } from 'prop-types';

import { MDBInput } from 'mdbreact'

export function AutoMobileForm(props){
  return(
    <form>
      <MDBInput
        label="Modelo"
        name="model"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.model}
      />
      <MDBInput
        label="Ano"
        name="year"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.year}
      />
      <MDBInput
        label="Valor"
        name="price"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.price}
      />
      <MDBInput
        label="Contato"
        name="contact"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.contact}
      />
      <MDBInput
        label="Cidade"
        name="city"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.city}
      />
      <MDBInput
        label="Informações adicionais"
        name="additionalInfo"
        onChange={props.handleInputChange}
        group
        type="textarea"
        success="right"
        value={props.additionalInfo}
      />
    </form>
  )
}