import React, { Component } from 'react'

import { SlideImageCard } from './SlideImageCard'
import UploadImage from '../Upload/UploadImage'
import DeleteImage from '../Upload/DeleteImage'
import EditSlideImage from './EditSlideImage'
import { Header } from '../Layout/Header'

import { connect } from 'react-redux'
import { fetchSlideImagesThunk, setDeleteId, setEditId, handleDeleteModal, handleEditModal } from '../../store/actions/slideshowActions'

import { MDBBtn, MDBIcon } from 'mdbreact'

class SlideshowManager extends Component {
  state = {
    pushModal: false,
    deleteModal: false,
    editModal: false
  }

  async componentWillMount() {
    !this.props.slides && this.props.dispatch(fetchSlideImagesThunk('slideshow'))
  }

  togglePushModal = () => {
    this.setState({
      pushModal: !this.state.pushModal
    });
  }

  render() {
    const { slides, storagePath } = this.props
    
    return(
      <div>
        <Header text={`Slideshow | `} routeBack="/admin" />
        <div className={`container d-flex justify-content-center flex-wrap`}>
          {
            this.state.pushModal &&
              <UploadImage
                toggle={this.togglePushModal}
                modal={this.state.pushModal}
                pushRef={'slideshow/slides'}
                add={'slide'}
              />
          }
          {
            this.props.deleteModal &&
              <DeleteImage
                toggle={() => {this.props.dispatch(handleDeleteModal(false))}}
                modal={this.props.deleteModal}
                firstRef={'slideshow'}
                secondRef={'slides'}
                deleteInfo={this.props.delete}
                deleteId={this.props.deleteId}
              />
          }
          {
            this.props.editModal &&
              <EditSlideImage
                toggle={() => {this.props.dispatch(handleEditModal(false))}}
                modal={this.props.editModal}
                editInfo={this.props.edit}
                editId={this.props.editId}
                editRef={'slideshow/slides'}
              />
          }
          <div className="d-flex flex-wrap justify-content-center align-items-center">
            <MDBBtn outline color="success" onClick={this.togglePushModal}>
              <MDBIcon icon="plus" /> Adicionar slide
            </MDBBtn>
          </div>
        </div>
        <div className="container pt-3 flex-wrap d-flex">
          {
            slides ?
              Object.keys(slides.images).map((key) => {
                return(
                  <SlideImageCard 
                    position={slides.images[key].id}
                    modifiedBy={slides.images[key].modifiedBy}
                    id={key}
                    key={key}
                    url={slides.images[key].url}
                    image={slides.images[key].image}
                    onDelete={() => {this.props.dispatch(setDeleteId(slides.images[key], key, true))}}
                    onEdit={() => {this.props.dispatch(setEditId(slides.images[key], key, true))}}
                  />
                )
              }) : (
                <div>Nenhum convênio foi encontrado nessa categoria</div>
              )
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  slides: state.slideshow.slides,
  delete: state.slideshow.delete,
  deleteModal: state.slideshow.deleteModal,
  editModal: state.slideshow.editModal,
  deleteId: state.slideshow.deleteId,
  editId: state.slideshow.editId,
  edit: state.slideshow.edit
})



export default connect(mapStateToProps)(SlideshowManager)