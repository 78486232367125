import React, { Component } from 'react';
import { string, func, bool } from 'prop-types'

import { connect } from 'react-redux';
import { addLessonThunk, fetchLessonsThunk } from '../../store/actions/lessonsActions'

import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { DayForm } from './DayForm';
import { PriceForm } from './PriceForm';
import { CategoryForm } from './CategoryForm';

class CreateLesson extends Component {
  static propTypes = {
    modal: bool.isRequired,
    toggle: func.isRequired,
    pushRef: string.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      lesson1: '',
      lesson2: '',
      lesson3: '',
      service: '',
      associate: '',
      notAssociated: '',
      professorName: '',
      professorContact: '',
      professorLesson: '',
      professors: [],
      prices: {
        associate: '',
        notAssociated: ''
      },
      newShift: false,
      error: false,
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.assignProfessor = this.assignProfessor.bind(this)
    this.push = this.push.bind(this)
  }

  handleInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({
      [name]: value
    })
  }

  assignProfessor() {
    const { professorName, professorContact, professorLesson } = this.state
    const key = professorName.toLowerCase().replace(/[áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]/g, '')
    const newProfessor = {
      name: professorName,
      contact: professorContact,
      lesson: professorLesson,
      key: key,
      modifiedBy: JSON.parse(localStorage.getItem('auth')).user.displayName
    }
    if(professorName) {
      this.setState({
        professors: Object.assign( { [key]: newProfessor }, this.state.professors)
      })
    } else {
      alert('Ao menos o nome do professor deverá ser preenchido.')
    }
  }

  async push() {
    const { name, professors, lesson1, lesson2, lesson3, service, associate, notAssociated } = this.state
    const { pushRef, type } = this.props

    const categoryPrices = {
      associate: associate,
      notAssociated: notAssociated
    }

    const newDay = {
      name, 
      lesson1, 
      lesson2, 
      lesson3,
      type,
      pushRef
    }
    const newPrice = {
      name,
      service,
      associate,
      notAssociated,
      type,
      pushRef
    }

    const newCategory = {
      name,
      professors,
      categoryPrices,
      type,
      pushRef
    }

    if( type === 'days' ) {
      debugger
      if( name && lesson1 ) {
        this.props.fetchLessons()
        this.props.toggle()
        return this.props.addLesson(newDay)
        .then(function(){
          return alert('Dia adicionado com sucesso!')
        })
        .catch(function(){
          return alert('Falha ao adicionar dia. Tente novamente mais tarde.')
        })
      } else {
        alert("Por favor, preencha ao menos o dia e uma aula.")
      }
    } else if( type === 'prices' ) {
      debugger
      if( associate ) {
        this.props.fetchLessons()
        this.props.toggle()
        return this.props.addLesson(newPrice)
        .then(function(){
          return alert('Dia adicionado com sucesso!')
        })
        .catch(function(){
          return alert('Falha ao adicionar dia. Tente novamente mais tarde.')
        })
      } else {
        alert("Por favor, preencha pelo menos o valor de sócio")
      }
    } else if( type === 'categories' ) {
      debugger
      if( name ) {
        this.props.fetchLessons()
        this.props.toggle()
        return this.props.addLesson(newCategory)
        .then(function(){
          return alert('Categoria adicionado com sucesso!')
        })
        .catch(function(){
          return alert('Falha ao adicionar categoria. Tente novamente mais tarde.')
        })
      } else {
        alert("Por favor, preencha ao menos o nome da categoria.")
      }
    }
  }

render() {
  const { toggle, modal, type } = this.props
  
  return (
      <MDBModal isOpen={modal} toggle={toggle}>
        <MDBModalHeader toggle={toggle}>Adicionar</MDBModalHeader>
        <MDBModalBody>
          {
            type === 'days' ?
            (
              <DayForm 
                handleInputChange={this.handleInputChange}
                name={this.state.name}
                lesson1={this.state.lesson1}
                lesson2={this.state.lesson2}
                lesson3={this.state.lesson3}
              />
            )
            :
            (
              type === 'prices' ? (
                <PriceForm 
                  handleInputChange={this.handleInputChange}
                  service={this.state.service}
                  associate={this.state.associate}
                  notAssociated={this.state.notAssociated}
                />
              ) : (
                <CategoryForm
                  handleInputChange={this.handleInputChange}
                  assignProfessor={this.assignProfessor}
                  name={this.state.name}
                  professorName={this.state.professorName}
                  professorContact={this.state.professorContact}
                  professorLesson={this.state.professorLesson}
                  associate={this.state.associate}
                  notAssociated={this.state.notAssociated}
                  professors={this.state.professors}
                />
              )
            )
          }
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={toggle}>Voltar</MDBBtn>
          <MDBBtn color="success" onClick={this.push}>Adicionar</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    );
  }
}

const mapStateToProps = state => ({
  lessons: state.lessons
})

const mapDispatchToProps = dispatch => {
  return {
    addLesson: (lesson) => dispatch(addLessonThunk(lesson)),
    fetchLessons: () => dispatch(fetchLessonsThunk())
  }
 }

export default connect(mapStateToProps, mapDispatchToProps)(CreateLesson);