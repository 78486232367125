import React from 'react'
import { func, string } from 'prop-types';

import { MDBInput } from 'mdbreact'

const propTypes = {
  handleInputChange: func.isRequired,
  day: string.isRequired,
  lesson1: string.isRequired,
  lesson2: string.isRequired,
  lesson3: string.isRequired
}

export function PriceForm(props){
  
  return(
    <form>
      <MDBInput
        label="Serviço"
        name="service"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.service}
      />
      <MDBInput
        label="Sócio"
        name="associate"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.associate}
      />
      <MDBInput
        label="Não sócio"
        name="notAssociated"
        onChange={props.handleInputChange}
        group
        type="text"
        success="right"
        value={props.notAssociated}
      />
    </form>
  )
}