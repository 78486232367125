import { 
  FETCH_LESSONS,
  ADD_DAY
} from '../actions/types'

export function lessons (state = 'loading', action) {
  switch(action.type) {
    case FETCH_LESSONS:
      return action.lessons;
    default:
      return state;
  }
}

export function lessonsCrud (state = {}, action) {
  switch(action.type) {
    case ADD_DAY:
      return {
        ...state,
        day: action.day,
        error: action.error
      }
    default:
      return state;
  }
}