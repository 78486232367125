import React, { Component } from 'react'
import { Route, Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { fetchClassifiedThunk } from '../../store/actions/classifiedAdsActions'

import { Card, CardBody, CardImage, CardTitle, CardText, Col } from 'mdbreact';

class ClassifiedAdsCategories extends Component {

  async componentDidMount() {
    this.props.fetchClassifiedThunk()  
  }

  async componentDidUpdate() {
    this.props.fetchClassifiedThunk()  
  }
 
  render() {
    const { classifiedAds } = this.props
    
    return (
      <div className="container mb-5">
      <Route exact path="/classificados" render={() => (
        <div>
        <h1 className="h1-responsive font-bold mt-sm-5 wow fadeInLeft">Anúncios, <small>por categoria</small></h1>
        <hr className=" rgba-cyan-strong wow fadeInLeft" data-wow-delay="0.3s" ></hr>
        <Col className="d-flex justify-content-start flex-wrap">
        {
          classifiedAds.classifiedAds === 'loading' ? (
            <div>Carregando...</div>
          ) : (
            classifiedAds.classifiedAds && classifiedAds.classifiedAds.map((adType, index) => {
              return (
                <Card key={index} style={{ width: "20rem", margin: "20px" }}>
                  <CardImage
                    className="img-fluid ml-4"
                    src={adType.icon}
                    waves
                  />
                  <CardBody>
                    <CardTitle>{adType.type}</CardTitle>
                    <CardText>
                      {adType.description}
                    </CardText>
                    <Link to={{pathname: `/classificados/${adType.route}`}}>
                      <button type="button" className="btn light-blue">Ver anúncios</button>
                    </Link>
                  </CardBody>
                </Card>
              )
            })
          ) 
        }
        </Col>
        </div>
      )}
      /> 
      </div>
    )
  }
}

const mapStateToProps = state => ({
  classifiedAds: state
})

const mapDispatchToProps = dispatch => {
  dispatch(fetchClassifiedThunk())
  return {
    fetchClassifiedThunk: () => dispatch(fetchClassifiedThunk())
  }
 }

export default connect(mapStateToProps, mapDispatchToProps)(ClassifiedAdsCategories)