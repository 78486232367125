import React, { Component } from 'react'

import { MDBBtn, MDBIcon } from 'mdbreact'

import { setId, setRemoveId } from '../../store/actions/covenantActions'
import { connect } from 'react-redux'

import { Header } from '../Layout/Header'
import { CovenantsCard } from './CovenantsCard'
import CreateCovenant from './CreateCovenant'
import EditCovenant from './EditCovenant'
import DeleteCovenant from './DeleteCovenant';
import SignIn from '../Authentication/SignIn';

class CovenantAdmin extends Component {
  state = {
    pushModal: false,
    editModal: false,
    deleteModal: false,
    selectedCovenant: {},
    editId: ''
  }
  
  togglePushModal = () => {
    this.setState({
      pushModal: !this.state.pushModal
    });
  }

  toggleEditModal = () => {
    this.setState({
      editModal: !this.state.editModal
    })
  }

  toggleDeleteModal = () => {
    this.setState({
      deleteModal: !this.state.deleteModal
    })
  }

  componentDidMount(){
    this.props.dispatch(setId())
  }

  render() {
    const { covenant, pushRef, selectedCovenant, id, isAuthenticated } = this.props
    
    if(isAuthenticated.loggedIn) {
      return (
        <div className="pb-5">
          <Header text={`Convênios - ${covenant.type} | `} routeBack="/admin/convenios" />
          <div className={`container d-flex justify-content-center flex-wrap`}>
            {
              this.state.pushModal &&
                <CreateCovenant
                  toggle={this.togglePushModal}
                  modal={this.state.pushModal}
                  pushRef={pushRef}
                />
            }
            {
              this.state.editModal && 
                <EditCovenant 
                  toggle={this.toggleEditModal}
                  modal={this.state.editModal}
                  selectedCovenant={selectedCovenant}
                  id={id}
                  editRef={pushRef}
                />
            }
            {
              this.state.deleteModal &&
                <DeleteCovenant 
                  toggle={this.toggleDeleteModal}
                  modal={this.state.deleteModal}
                  deleteInfo={this.props.delete}
                  deleteRef={pushRef}
                />
            }
            <div className="d-flex flex-wrap justify-content-center align-items-center">
              <MDBBtn outline color="success" onClick={this.togglePushModal}>
                <MDBIcon icon="plus" /> Adicionar convênio
              </MDBBtn>
            </div>
          </div>
          <div className={`container pt-3 d-flex justify-content-start flex-wrap`}>
            {
              covenant.covenants ? 
                Object.keys(covenant.covenants).map((key) => {
                  return (
                    <CovenantsCard 
                      name={covenant.covenants[key].name}
                      description={covenant.covenants[key].description}
                      contact={covenant.covenants[key].contact}
                      owner={covenant.covenants[key].owner}
                      link={covenant.covenants[key].link}
                      modifiedBy={covenant.covenants[key].modifiedBy}
                      width="22em"
                      id={key}
                      key={key}
                      onDelete={() => { this.props.dispatch(setRemoveId(covenant.covenants[key].name, key)); this.toggleDeleteModal(); }}
                      onEdit={() => { this.props.dispatch(setId(covenant.covenants[key], key)); this.toggleEditModal(); }}
                    />
                  )
                }) : (
                  <div>Nenhum convênio foi encontrado nessa categoria</div>
                )
            }
          </div>
        </div>
      )
    } else {
      return <SignIn />
    }
  }
}

const mapStateToProps = state => ({
  selectedCovenant: state.covenants.selectedCovenant,
  id: state.covenants.id,
  delete: state.covenants.delete,
  isAuthenticated: state.auth
})

export default connect(mapStateToProps)(CovenantAdmin)