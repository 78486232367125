import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { string } from 'prop-types'

import { MDBBtn, MDBCard, MDBCardBody, MDBRow, MDBCardTitle } from 'mdbreact';

export function CategoriesCard(props) {
  return(
    <MDBCard style={{ width: '100%', margin: '10px' }}>
      <MDBCardBody>
        <MDBRow className="d-flex justify-content-between flex-nowrap align-items-center">
          <MDBCardTitle className="ml-3">
            {props.title}
          </MDBCardTitle>
          <Link
            to={`/admin/${props.type}/${props.route}`}
          >
            <MDBBtn>
                {props.button}
            </MDBBtn>
          </Link>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  )
}