import React from 'react'

import { MDBBtn, MDBCardText, MDBCard, MDBCardBody, MDBRow, MDBCardTitle } from 'mdbreact';
import { relative } from 'path';

export function LeasesCard(props) {
  return(
    <MDBCard 
      id={props.id}
      style={{ width: `${props.width}`, position: 'relative', margin: '5px' }}>
      <MDBCardBody>
        <MDBRow className="d-flex flex-column justify-content-between align-items-start">
          <MDBCardTitle className="ml-3">
            {props.shift}
          </MDBCardTitle>
          <MDBCardText className="pl-3 w-75" style={{ height: 'auto' }}>
            Horários: {props.schedule}
          </MDBCardText>
          <MDBCardText className="pl-3 w-75" style={{ height: 'auto' }}>
            À vista: {props.cash}
          </MDBCardText>
          <MDBCardText className="pl-3 w-75" style={{ height: 'auto' }}>
            Cartão de crédito: {props.creditCard}
          </MDBCardText>
          {
            props.modifiedBy &&
            <MDBCardText className="pl-3 w-75" style={{ height: 'auto' }}>
              <i>Última modifição: {props.modifiedBy}</i>
            </MDBCardText>
          }
          <div className={`d-flex flex-nowrap justify-content-between`}>
            <MDBBtn color="danger" onClick={props.onDelete} style={{ borderRadius: '50px' }}>
              X
            </MDBBtn>
            <MDBBtn color="info" onClick={props.onEdit} style={{ borderRadius: '50px' }}>
              EDITAR
            </MDBBtn>
          </div>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  )
}