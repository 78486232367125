import { 
  FETCH_GALLERIES,
  ADD_GALLERY,
  SET_REMOVE_GALLERY,
  SET_EDIT_GALLERY,
  DELETED_GALLERY,
  EDITED_GALLERY
} from "../actions/types";

export function galleries (state = 'loading', action) {
  switch(action.type) {
    case FETCH_GALLERIES:
      return action.galleries;
    default:
      return state;
  }
}

export function galleriesCrud (state = {}, action) {
  switch(action.type) {
    case ADD_GALLERY:
      return {
        ...state,
        newGallery: action.gallery || '',
        error: action.error || ''
      }
    case SET_REMOVE_GALLERY:
      return {
        ...state,
        deleteModal: action.deleteModal,
        deleteInfo: action.gallery,
        deleteId: action.key
      }
    case SET_EDIT_GALLERY:
      return {
        ...state,
        editModal: action.editModal,
        editInfo: action.gallery,
        editId: action.key
      }
    case DELETED_GALLERY:
      return {
        ...state,
        galleryRemoved: action.gallery,
        error: action.error,
        deleteModal: false
      }
    case EDITED_GALLERY:
      return {
        ...state,
        galleryEdited: action.gallery,
        error: action.error,
        editModal: false
      }
    default:
      return state;
  }
}