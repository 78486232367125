import firebase from 'firebase'
import {
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  ALREADY_LOGGED_IN,
  NOT_LOGGED_IN,
  LOG_OUT
} from './types'

export const loginUserSuccess = (auth) => {
  return {
    type: LOGIN_USER_SUCCESS,
    user: auth.user
  }
}

export const loginUserFail = (error) => {
  return {
    type: LOGIN_USER_FAIL,
    error
  }
}

const loggedOut = () => {
  return {
    type: LOG_OUT
  }
}

export function logOut(){
    if (localStorage.getItem('auth') !== null) {
      localStorage.removeItem('auth')
      return dispatch => {
        firebase.auth().signOut().then(() => {
          return dispatch(loggedOut())
        })
      }
    }
} 

export function loginUserThunk(email, password){
    return dispatch => {
      firebase.auth().signInWithEmailAndPassword(email, password)
        .then((auth) => {
          localStorage.setItem('auth', JSON.stringify(auth));
          return dispatch(loginUserSuccess(auth))
        }).catch((error) => {
          dispatch(loginUserFail(error))
          alert('Senha e/ou email estão errados. Confira e tente novamente.')
        })
    }
}

export function isUserLoggedIn(){
  if (localStorage.getItem('auth') !== null){
    const localStorageUser = JSON.parse(localStorage.getItem('auth')).user
    return{
      type: ALREADY_LOGGED_IN,
      user: localStorageUser
    }
  } 
  else {
    return{
      type: NOT_LOGGED_IN,
      user: null
    }
  }
}