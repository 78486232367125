import React, { Component } from 'react'
import { bool, func, string } from 'prop-types';

import { connect } from 'react-redux';
import { deleteGalleryThunk, fetchGalleriesThunk } from '../../store/actions/galleryActions'

import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';

class DeleteGallery extends Component {
  static propTypes = {
    modal: bool.isRequired,
    toggle: func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      modal: props.deletModal,
      galleryRemoved: false,
      error: false,
    }

    this.delete = this.delete.bind(this)
  }

  delete(){
    const { deleteInfo, deleteId } = this.props
    const deleteContent = {
      deleteInfo,
      deleteId
    }
    this.props.fetchGalleriesThunk()
    this.props.deleteGalleryThunk(deleteContent).then(function(){
      return alert(`Galeria removida!`)
    }.bind(this))
    .catch(function(){
      return alert(`Erro ao remover galeria. Tente novamente mais tarde`)
    }.bind(this))
  }

  render() {
    const { toggle } = this.props

    return(
      <MDBModal isOpen={this.props.deleteModal} toggle={toggle}>
        <MDBModalHeader toggle={toggle}>Excluir galeria</MDBModalHeader>
        <MDBModalBody>
          <p>Tem certeza que deseja remover <b>{`${this.props.deleteInfo.title}`}</b>?</p>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={toggle}>Voltar</MDBBtn>
          <MDBBtn color="danger" onClick={this.delete}>Excluir</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    )
  }
}

const mapStateToProps = state => ({
  galleries: state.galleries,
  deleteModal: state.galleriesCrud.deleteModal,
  deleteInfo: state.galleriesCrud.deleteInfo,
  deleteId: state.galleriesCrud.deleteId
})

const mapDispatchToProps = dispatch => {
  return {
    deleteGalleryThunk: (gallery) => dispatch(deleteGalleryThunk(gallery)),
    fetchGalleriesThunk: () => dispatch(fetchGalleriesThunk())
  }
 }

export default connect(mapStateToProps, mapDispatchToProps)(DeleteGallery)