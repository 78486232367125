import React, { Component } from 'react'
import { 
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  Collapse,
  MDBNavItem,
  MDBNavLink,
  MDBIcon
} from "mdbreact";
import styled from 'styled-components'
import { slide as Menu } from 'react-burger-menu'

import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { logOut, isUserLoggedIn } from '../../store/actions/loginActions'

import logo from '../../assets/logos/ash.png';
import { veganBurguer } from '../../assets/sidenav/style'

const MenuWrapper = styled.div`${veganBurguer}`

const NavbarWrapper = styled.div`
  .navbar-brand {
    font-family: 'Paytone One', sans-serif;
    font-size: 2.3em;

    .nav-link:hover {
      font-weight: 900!important;
    }

    @media (max-width: 520px) {
      font-size: 1.8em;
    }

    @media (min-width: 520px) {
      padding-top: 0;
    }
  }
`

const contentManagerNavigation = [
  { label: 'Convênios', route: '/admin/convenios' },
  { label: 'Classificados', route: '/admin/classificados' },
  { label: 'Galerias', route: '/admin/galerias' },
  { label: 'Locação de espaços', route: '/admin/locacoes' },
  { label: 'Parque Social', route: '/admin/parquesocial' },
  { label: 'Slideshow', route: '/admin/slideshow' }
]

const navigationLabels = [
  { label: 'SOBRE', route: '/sobre' },
  { label: 'GALERIA', route: '/galerias' },
  { label: 'LOCALIZAÇÃO', route: '/mapa' },
  { label: 'CONVÊNIOS', route: '/convenios' },
  { label: 'PARQUE SOCIAL', route: '/parquesocial' },
  { label: 'CLASSIFICADOS', route: '/classificados' },
  { label: 'CONTATO', route: '/contato' }
]

const dropdownLabels = [
  { label: 'Aposentados', route: '/aposentados' },
  { label: 'Eleições', route: '/eleicoes' },
  { label: 'Locação de espaços', route: '/locacoes' },
  { label: 'Lojas', route: '/lojas' },
  { label: 'Anúncios', route: '/classificados' },
  { label: 'Administrador', route: '/login' },
]

class Navigation extends Component {
  state = {
    collapseID: ""
  }

  showSettings (event) {
    event.preventDefault();
  }

  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));
  }

  closeCollapse = collapseID => () => {
    this.state.collapseID === collapseID && this.setState({ collapseID: "" });
  }

  render() {
    const { auth } = this.props

    return(
      <NavbarWrapper className="pb-5">
      <MDBNavbar color={`teal accent-4 ${auth.loggedIn && `p-3`}`} dark expand="md" fixed="top">
          { !auth.loggedIn ? (
            <MDBNavbarBrand href="/">
              <img
                src={ logo }
                alt=""
                height="30"
                className="mr-2"
              /> 
              { 'ASHCLIN ' }
            </MDBNavbarBrand>
          ) : (
            <MenuWrapper>
              <Menu>
                {
                  contentManagerNavigation.map((item, key) => (
                    <Link
                      className="menu-item"
                      id={`${item.route.replace('/', '')}`}
                      key={key}
                      to={item.route}
                    >
                    { item.label }
                    </Link>
                  ))
                }
              </Menu>
            </MenuWrapper>
          )
          }
          {
            auth.loggedIn && 
            <MDBNavbarBrand href="/" className="pr-5" />
          }
        <MDBNavbarToggler
          onClick={this.toggleCollapse("mainNavbarCollapse")}
        />
        <Collapse
          id="mainNavbarCollapse"
          isOpen={this.state.collapseID}
          navbar
        >
          <MDBNavbarNav left>
          {
            navigationLabels.map((navItem, key) => (
              <MDBNavItem
                key={key}
              >
                <MDBNavLink
                  exact
                  to={navItem.route}
                  onClick={this.closeCollapse("mainNavbarCollapse")}
                >
                  { navItem.label }
                </MDBNavLink>
              </MDBNavItem>
            ))
          }
          {
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  <div className="d-none d-md-inline">MAIS</div>
                </MDBDropdownToggle>
                <MDBDropdownMenu right className="dropdown-default">
                {
                  dropdownLabels.map((dropItem, key) => (
                    <MDBDropdownItem
                      key={key}
                      className="text-hover"
                    >
                      <MDBNavLink
                        className="teal-text text-hover"
                        to={dropItem.route}
                        onClick={this.closeCollapse("mainNavbarCollapse")}
                      >
                        { dropItem.label }
                      </MDBNavLink>
                    </MDBDropdownItem>
                  ))
                }
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>
          }
          </MDBNavbarNav>
          {
            auth.loggedIn &&
            <MDBDropdown>
            <MDBDropdownToggle nav caret className="white-text">
              <div className="d-none d-md-inline white-text">
                {auth.user ? auth.user.displayName : null}
              </div>
            </MDBDropdownToggle>
            <MDBDropdownMenu right>
              <MDBDropdownItem onClick={() => { this.props.logOut(); this.props.isUserLoggedIn(); }}>
                <MDBIcon icon="reply" className="mr-2" /> Sair
              </MDBDropdownItem>
            </MDBDropdownMenu> 
          </MDBDropdown>
          }
        </Collapse>
      </MDBNavbar>
    </NavbarWrapper>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth
})

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(logOut()),
    isUserLoggedIn: () => dispatch(isUserLoggedIn())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation)