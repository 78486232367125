import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { MDBIcon } from 'mdbreact'

const HeaderWrapper = styled.div`
  .title {
    padding: 30px 0;
    text-align: center;

    b {
      font-family: 'Paytone One', sans-serif;
    }
  }
`

export function Header(props){
  return (
    <HeaderWrapper>
      <h1 className="title mt-2">
        <Link to={props.routeBack}>  
          <MDBIcon icon="arrow-left" size="lg" className="pr-3 cyan-text" />
        </Link>
        {`${props.text}`} <b>ASHCLIN</b> 
      </h1>
    </HeaderWrapper>
  )
}